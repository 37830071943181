import * as React from 'react';
import {useState, useEffect, Suspense} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import axios from 'axios';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import 'dayjs/locale/en-gb';

import './app.scss';
import Login from './Login';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import {getToken, removeUserSession} from './Utils/Common';
import DataQuality from './components/DataQuality';
import WardGuardian from './components/WardGuardian';
import Register from './components/Register';
import Logout from './components/Logout';
import Help from './components/Help';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import TrustPicker from './components/TrustPicker';
import ReportViewer from './components/ReportViewer';
import PreBuiltReportViewer from './components/PreBuiltReportViewer';
import staffdetailsviewer from './components/StaffDetailsViewer';
import PersonContractView from './components/PersonContractView';
import {SidebarContextProvider} from './components/context/SidebarContextProvider';
import LazyLoader from './components/common/loaders/LazyLoader';
import UserActivityView from './components/UserActivityView';
const SidebarRoutes = React.lazy(() => import('./components/sidebar/SidebarRoutes'));

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  function ClearCache() {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
  }

  useEffect(() => {
    document.title = 'Ward Guardian Compliance Console';

    if (process.env.REACT_APP_APPVERSION === null || process.env.REACT_APP_APPVERSION === undefined) {
      ClearCache();
    } else {
      const checkVersion = async () => {
        const res = await axios.get(process.env.REACT_APP_APIURL + 'config');
          if (process.env.REACT_APP_APPVERSION !== res.data) {
              console.log('Going to wait')
              await new Promise(f => setTimeout(f, 5000));
              console.log('waited')
                ClearCache();
            }
      };
      checkVersion();
    }

    const token = getToken();
    if (!token) {
      return;
    }
    axios
      .post(
        process.env.REACT_APP_APIURL + 'auth/user/checktoken',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      )
      .then((response) => {
        setAuthLoading(false);
      })
      .catch((error: any) => {
        removeUserSession();
        setAuthLoading(false);
      });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <SidebarContextProvider>
        <BrowserRouter>
          <Switch>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/forgotpassword" component={ForgotPassword} />
            <PublicRoute path="/resetpassword" component={ResetPassword} />
            <Route path="/help" component={Help} />
            <Route path="/PreBuiltReportViewer" component={PreBuiltReportViewer} />
            <PrivateRoute path="/TrustPicker" component={TrustPicker} />
            <PrivateRoute path="/DataQuality" component={DataQuality} />
            <PrivateRoute path="/wardguardian" component={WardGuardian} />
            <PrivateRoute path="/logout" component={Logout} />
            <PrivateRoute path="/changepassword" component={ChangePassword} />
            <PrivateRoute path="/ReportViewer" component={ReportViewer} />
            <PrivateRoute path="/staffdetailsviewer" component={staffdetailsviewer} />
            <PrivateRoute path="/PersonContractView/:personId/:contractedHours" component={PersonContractView} />
            <PrivateRoute path="/UserActivity" component={UserActivityView} />
            <PublicRoute exact path="/register" component={Register} />
            <PublicRoute exact path="/" component={Login} />
          </Switch>
          <LazyLoader>
            <SidebarRoutes />
          </LazyLoader>
        </BrowserRouter>
      </SidebarContextProvider>
    </LocalizationProvider>
  );
}

export default App;
