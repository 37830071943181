import * as React from 'react';
import { useState } from 'react';
// import {DatePicker, DatePickerChangeEvent} from '@progress/kendo-react-dateinputs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button as TelButton } from '@progress/kendo-react-buttons';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import { IntlProvider, load } from '@progress/kendo-react-intl';
import { ButtonGroup, Button } from '@material-ui/core';
import Moment from 'moment';

import LocationsTreeView, { getChildUnits } from './LocationsTreeView';
import { SearchLocation } from '../Utils/types';
import { ActiveProfile, KPIState, KPIFilter } from './WardGuardian';
import { Notification as Notification2, SidebarContext } from '../components/context/SidebarContextProvider';
import ColumnsCollapsableList from './common/collapsable-list/ColumnsCollapsableList';
import UnitsCollapsableList from './common/collapsable-list/UnitsCollapsableList';
import { getLocations } from './common/collapsable-list/util';
import TotalsCollapsableList from './common/collapsable-list/TotalsCollapsibleList';
import { State } from '@progress/kendo-data-query';
import KpiStateCollapsableList from './common/collapsable-list/KpiStateCollapsableList';
import dayjs from 'dayjs';
interface FiltersProps {
    history: any[];
    filtersChangeCallBack: (searchLocations: string, startDate?: Date, endDate?: Date, isRestoringDefault?: boolean) => void;
    redoCallBack: () => void;
    exportExcelCallBack: () => void;
    advancedCallBack: () => void;
    expandCollapseCallBack: () => void;
    selectedVerbosityLevelCallBack: (item: any) => void;
    selectedVerbosityLevel: any;
    loading: boolean;
    rowCount: number;
    maxRowsForAdvanced: number;
    defaultStartDate: Date;
    defaultEndDate: Date;
    setDataGridColumns: React.Dispatch<React.SetStateAction<{ gridColumns: any[]; gridParentColumns: any[] }>>;
    setLocationsString?: React.Dispatch<React.SetStateAction<string>>;
    locationsString?: string;
    setDates: React.Dispatch<React.SetStateAction<{ startDate: Date | undefined; endDate: Date | undefined }>>;
    dates: { startDate: Date | undefined; endDate: Date | undefined };
    setTotalsMode: React.Dispatch<React.SetStateAction<'totals' | 'counts' | 'percentages'>>;
    totalsMode: 'totals' | 'counts' | 'percentages';
    setKpiStateMode: React.Dispatch<React.SetStateAction<KPIState>>;
    handleKpiStateModeChange: (kpiState: KPIState) => void;
    kpiStateMode: KPIState;
    gridState: State;
    setErrorNotification: React.Dispatch<React.SetStateAction<Notification2>>;
}

load(likelySubtags, currencyData, weekData);

weekData.supplemental.weekData.firstDay.US = 'mon';
export interface CurrentSearch {
    startDate: Date | undefined;
    endDate: Date | undefined;
    selectedLocation: SearchLocation | undefined;
}

function WardGuardianFiltersView(props: FiltersProps) {
    //set the date the end of the current month by default

    const [startDate, setStartDate] = useState<Date>(props.defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(props.defaultEndDate);
    let sl: SearchLocation = { isDirectorate: false, locations: '', locationNames: 'Select Ward/Unit' };
    const [selectedLocation, setSelectedLocation] = React.useState(sl);
    const [windowVisible, setWindowVisible] = useState(false);
    const [expand, setExpand] = useState(true);
    const [grouping, setGrouping] = useState(false);
    const [isIconFocused, setIsIconFocused] = useState(false);
    const { isSidebarOpen } = React.useContext(SidebarContext);
    const [activeSearchLocations, setActiveSearchLocations] = React.useState('');

    const { activeUnitProfile } = React.useContext(SidebarContext);

    const toggleDialog = () => {
        if ({ windowVisible }.windowVisible) {
            setWindowVisible(!{ windowVisible }.windowVisible);
        } else {
            setWindowVisible(!{ windowVisible }.windowVisible);
        }
    };

    const handleFilters = async (locations?: SearchLocation, newStartDate?: Date, newEndDate?: Date) => {
        let selectedLocations;
        if (locations) {
            selectedLocations = locations;
        } else {
            const treeData = await getLocations();
            if (treeData && (activeUnitProfile || activeSearchLocations)) {
                const metricCodes = activeUnitProfile ? activeUnitProfile.metricCodes : activeSearchLocations;
                const locationsString = getChildUnits(metricCodes, treeData);
                const newSelectedLocations = { ...selectedLocation, locations: locationsString };
                selectedLocations = newSelectedLocations;
            } else {
                selectedLocations = selectedLocation;
            }
        }
        props.filtersChangeCallBack(
            selectedLocation === undefined ? '' : selectedLocations.locations,
            newStartDate || startDate,
            newEndDate || endDate,
        );
    };

    const handleExportToExcel = () => {
        props.exportExcelCallBack();
    };

    const handleAdvanced = () => {
        setGrouping(!grouping);
        props.advancedCallBack();
    };

    const handleExpandCollapse = () => {
        setExpand(!expand);
        props.expandCollapseCallBack();
    };

    const handleRestoreDefaults = () => {
        setStartDate(props.defaultStartDate);
        setEndDate(props.defaultEndDate);
        setIsIconFocused(false);
        setSelectedLocation(sl);
        setActiveSearchLocations('');
        props.redoCallBack();
    };

    const handleStartDateChange = (value: any) => {
        if (value && value.$d) {
            setStartDate(value.$d as Date);
            setIsIconFocused(true);
        }
    };

    const handleEndDateChange = (value: any) => {
        if (value && value.$d) {
            setEndDate(value.$d as Date);
            setIsIconFocused(true);
        }
    };

    const handleSelectedLocation = (event: SearchLocation) => {
        setSelectedLocation(event);
    };

    const handleSelectedVerbosityLevelSimple = () => {
        props.selectedVerbosityLevelCallBack('availabledata');
        setIsIconFocused(true);
    };

    const handleSelectedVerbosityLevelVerbose = () => {
        props.selectedVerbosityLevelCallBack('alldata');
        setIsIconFocused(true);
    };

    const handleSelectedVerbosityLevelAllKPIs = () => {
        props.selectedVerbosityLevelCallBack('all');
        setIsIconFocused(true);
    };

    const handleSelectedVerbosityLevelFailedKPIs = () => {
        props.selectedVerbosityLevelCallBack('onlyfails');
        setIsIconFocused(true);
    };

    const handleDateChange = (isForward: boolean, isToday?: boolean) => {
        let newEndDate = isForward
            ? Moment(endDate).add(4, 'week').toDate()
            : endDate
                ? Moment(endDate).subtract(4, 'week').toDate()
                : Moment().toDate();
        let newStartDate = isForward ? Moment(startDate).add(4, 'week').toDate() : Moment(startDate).subtract(4, 'week').toDate();

        if (isToday) {
            newEndDate = Moment().add(2, 'week').toDate();
            newStartDate = Moment().subtract(2, 'week').toDate();
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        props.setDates({ startDate: newStartDate, endDate: newEndDate });
        handleFilters(undefined, newStartDate, newEndDate);
    };

    function Truncate(str: string, n: number) {
        if (str.length === 0) {
            return 'Select Ward/Unit';
        }

        return str.length > n ? str.substr(0, n - 1) + '...' : str;
    }

    React.useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter' && !isSidebarOpen) {
                handleFilters();
                setIsIconFocused(false);
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isSidebarOpen, startDate, endDate, selectedLocation, activeUnitProfile]);

    return (
        <div className="filtersview" style={{ background: 'rgba(208, 239, 255, 0.3)' }}>
            <div className="firstfilterrow">
                <div style={{ padding: '0 0 0 7px', display: 'flex', flexDirection: 'column', flex: 1.2, justifyContent: 'flex-end' }}>
                    <label htmlFor="Locations" className="inputlables" style={{ color: '#585959', fontWeight: 600 }}>
                        Select Ward/Unit
                    </label>
                    <TelButton
                        id="Locations"
                        className="wardsearchbutton"
                        title={props.locationsString}
                        onClick={toggleDialog}
                        style={{ minHeight: '28.16px', minWidth: '400px' }}>
                        {Truncate(props.locationsString || '', window.innerWidth > 1550 ? 80 : 60)}
                    </TelButton>

                    <LocationsTreeView
                        history={props.history}
                        selectedLocationsCallBack={handleSelectedLocation}
                        locations={selectedLocation}
                        windowVisible={windowVisible}
                        closeButtonCallBack={toggleDialog}
                        healthRoster={true}
                        filtersChangeCallBack={props.filtersChangeCallBack}
                        setActiveSearchLocations={setActiveSearchLocations}
                        activeSearchLocations={activeSearchLocations}
                        setLocationsString={props.setLocationsString}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                <IntlProvider locale={'en-US'}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label
                                htmlFor="startDate"
                                className="inputlables"
                                style={{
                                    marginBottom: '5px',
                                    color: '#585959',
                                    fontWeight: 600,
                                }}>
                                Select Date Range
                            </label>
                            <div style={{ display: 'flex', marginBottom: '5px', marginLeft: '10px' }}>
                                <TelButton
                                    className="date-picker-button"
                                    title="Move dates backwards by 4 weeks"
                                    style={{ margin: '0 3px 0 0' }}
                                    onClick={() => handleDateChange(false)}>
                                    {'< Roster'}
                                </TelButton>
                                <TelButton
                                    className="date-picker-button"
                                    style={{ margin: '0 3px' }}
                                    title="Move start date backwards by 2 weeks and the end date forwards by 2 weeks from today"
                                    onClick={() => handleDateChange(false, true)}>
                                    Today
                                </TelButton>
                                <TelButton
                                    className="date-picker-button"
                                    style={{ margin: '0 0 0 3px' }}
                                    title="Move dates forwards by 4 weeks"
                                    onClick={() => handleDateChange(true)}>
                                    {'Roster >'}
                                </TelButton>
                            </div>
                        </div>
                        <div style={{ display: 'flex', minWidth: '302.08px', maxWidth: '302.08px', justifyContent: 'space-between' }}>
                            <div style={{ maxHeight: '28.16px', height: '28.16px', marginRight: '10px' }}>
                                <DatePicker
                                    className="date-picker-button-solid-base"
                                    sx={{
                                        '.MuiPickersPopper-root': { border: '0px solid transparent', outline: 'none' },
                                        '.MuiOutlinedInput-root': { paddingRight: '20px' },
                                        '.MuiOutlinedInput-input': {
                                            '&:focus': { border: '0px solid transparent', outline: 'none' },
                                            padding: '6px 12px',
                                            background: 'white',
                                        },
                                        '.MuiOutlinedInput Mui-focused': { outline: 'none' },
                                        '.MuiInputBase-root': {
                                            background: '#ebebeb',
                                            border: '0.5px solid #ebebeb',
                                            '&:hover fieldset': {
                                                outline: 'none',
                                                border: '0.5px solid #ebebeb',
                                            },
                                            '&:active fieldset': {
                                                outline: 'none',
                                                border: '0.5px solid #ebebeb',
                                            },
                                            '&:focus fieldset': {
                                                outline: 'none',
                                                border: '0px solid transparent',
                                            },
                                        },
                                        maxHeight: '28.16px',
                                        height: '28.16px',
                                    }}
                                    value={startDate === undefined ? dayjs(new Date(props.defaultStartDate)) : dayjs(startDate)}
                                    views={['year', 'month', 'day']}
                                    onClose={() => props.setDates({ ...props.dates, startDate })}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                            <div style={{ maxHeight: '28.16px', height: '28.16px', justifyContent: 'flex-end' }}>
                                <DatePicker
                                    sx={{
                                        '.MuiPickersPopper-root': { border: '0px solid transparent', outline: 'none' },
                                        '.MuiOutlinedInput-root': { paddingRight: '20px' },
                                        '.MuiOutlinedInput-input': {
                                            '&:focus': { border: '0px solid transparent', outline: 'none' },
                                            padding: '6px 12px',
                                            background: 'white',
                                        },
                                        '.MuiOutlinedInput Mui-focused': { outline: 'none' },
                                        '.MuiInputBase-root': {
                                            background: '#ebebeb',
                                            border: '0.5px solid #ebebeb',
                                            '&:hover fieldset': {
                                                outline: 'none',
                                                border: '0.5px solid #ebebeb',
                                            },
                                            '&:active fieldset': {
                                                outline: 'none',
                                                border: '0.5px solid #ebebeb',
                                            },
                                            '&:focus fieldset': {
                                                outline: 'none',
                                                border: '0px solid transparent',
                                            },
                                        },
                                        maxHeight: '28.16px',
                                        height: '28.16px',
                                    }}
                                    className="date-picker-button-solid-base"
                                    value={endDate === undefined ? dayjs(new Date(props.defaultEndDate)) : dayjs(endDate)}
                                    views={['year', 'month', 'day']}
                                    onClose={() => props.setDates({ ...props.dates, endDate })}
                                    onChange={handleEndDateChange}
                                />
                            </div>
                        </div>
                    </div>
                </IntlProvider>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <label htmlFor="endDate" className="inputlables" style={{ color: '#585959', fontWeight: 600 }}>
                        Select Ward Guardian Content
                    </label>
                    <div>
                        <ButtonGroup orientation="horizontal" color="primary">
                            <Button
                                className="toggle-button"
                                variant={'contained'}
                                title="Just show me the available data, excluding the categories my Trust has excluded."
                                onClick={handleSelectedVerbosityLevelSimple}
                                style={{
                                    minWidth: '80px',
                                    maxHeight: '28.16px',
                                    minHeight: '28.16px',
                                    padding: '2px 0',
                                    textTransform: 'none',
                                    background: props.selectedVerbosityLevel === 'availabledata' ? '#0080c1' : '#8a8a8a',
                                    color: 'white',
                                    boxShadow: 'none',
                                    borderRight: '1px solid white',
                                }}>
                                Simple
                            </Button>
                            <Button
                                variant={'contained'}
                                className="toggle-button"
                                title="Show me all the sections regardless if they have data, excluding the categories my Trust has excluded."
                                onClick={handleSelectedVerbosityLevelVerbose}
                                style={{
                                    minWidth: '80px',
                                    padding: '2px 0',
                                    textTransform: 'none',
                                    maxHeight: '28.16px',
                                    minHeight: '28.16px',
                                    boxShadow: 'none',
                                    borderLeft: '1px solid white',
                                    borderRight: '1px solid white',
                                    background: props.selectedVerbosityLevel === 'alldata' ? '#0080c1' : '#8a8a8a',
                                    color: 'white',
                                }}>
                                Verbose
                            </Button>
                            <Button
                                className="toggle-button"
                                variant={'contained'}
                                title="Show me everything."
                                onClick={handleSelectedVerbosityLevelAllKPIs}
                                style={{
                                    minWidth: '80px',
                                    padding: '2px 0',
                                    textTransform: 'none',
                                    maxHeight: '28.16px',
                                    minHeight: '28.16px',
                                    borderLeft: '1px solid white',
                                    borderRight: '1px solid white',
                                    background: props.selectedVerbosityLevel === 'all' ? '#0080c1' : '#8a8a8a',
                                    color: 'white',
                                    boxShadow: 'none',
                                }}>
                                All KPIs
                            </Button>
                            <Button
                                className="toggle-button"
                                variant={'contained'}
                                title="Show me the sections where the key metric has not hit the target."
                                onClick={handleSelectedVerbosityLevelFailedKPIs}
                                style={{
                                    minWidth: '80px',
                                    padding: '2px 0',
                                    textTransform: 'none',
                                    maxHeight: '28.16px',
                                    minHeight: '28.16px',
                                    borderLeft: '1px solid white',
                                    background: props.selectedVerbosityLevel === 'onlyfails' ? '#0080c1' : '#8a8a8a',
                                    color: 'white',
                                    boxShadow: 'none',
                                }}>
                                Failed KPIs
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <label htmlFor="Search" className="inputlables" style={{ color: '#585959', fontWeight: 600 }}>
                        Search
                    </label>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <TelButton
                                className="buttons-container-button"
                                style={{ margin: '0 7px 0 0' }}
                                onClick={handleRestoreDefaults}
                                disabled={props.loading}
                                title="Restore defaults"
                                iconClass="fa-solid fa-rotate-right">
                                Restore
                            </TelButton>
                        </div>
                        <div style={{ paddingRight: '13px', display: 'flex', alignItems: 'flex-end' }}>
                            <TelButton
                                className="buttons-container-button search-button"
                                style={{
                                    margin: '0 7px',
                                    color: isIconFocused ? 'black' : '#656565',
                                    border: isIconFocused ? '1px solid black' : '1px solid gray',
                                }}
                                onClick={() => {
                                    if (startDate < endDate) {
                                        handleFilters();
                                    } else {
                                        props.setErrorNotification({ show: true, text: 'The end date should be after the start date' });
                                    }
                                    setIsIconFocused(false);
                                }}
                                disabled={props.loading}
                                title="Search"
                                iconClass="fa-solid fa-magnifying-glass">
                                Search
                            </TelButton>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondfilterrow" style={{ padding: '3px 13px 3px 0px', display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TelButton
                        className="buttons-container-button"
                        onClick={handleAdvanced}
                        disabled={props.loading || props.rowCount > props.maxRowsForAdvanced}
                        iconClass="fa-solid fa-layer-group"
                        title="Toggle grouping panel">
                        {grouping ? 'Ungroup' : 'Group'}
                    </TelButton>
                    <TelButton
                        className="buttons-container-button"
                        onClick={handleExpandCollapse}
                        disabled={props.loading || (Array.isArray(props.gridState.group) && props.gridState.group.length < 1) || !props.gridState.group}
                        title="Expand/Collapse groups"
                        iconClass={expand ? 'fa-solid fa-plus' : 'fa-solid fa-minus'}>
                        {expand ? 'Expand' : 'Collapse'}
                    </TelButton>
                    <TelButton
                        className="buttons-container-button"
                        onClick={handleExportToExcel}
                        disabled={props.loading}
                        title="Export to Excel"
                        iconClass="fa-solid fa-file-excel">
                        Export
                    </TelButton>
                    <ColumnsCollapsableList isLoading={props.loading} setDataGridColumns={props.setDataGridColumns} />
                    <UnitsCollapsableList isLoading={props.loading} setActiveSearchLocations={setActiveSearchLocations} />
                    <KpiStateCollapsableList
                        isLoading={props.loading}
                        setKpiStateMode={props.setKpiStateMode}
                        kpiStateMode={props.kpiStateMode}
                        handleKpiStateModeChange={props.handleKpiStateModeChange}
                    />
                    <TotalsCollapsableList isLoading={props.loading} setTotalsMode={props.setTotalsMode} totalsMode={props.totalsMode} />
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <span className="rowcount">Rosters: {props.rowCount}</span>
                </div>
            </div>
        </div>
    );
}

export default WardGuardianFiltersView;
