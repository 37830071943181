import {Loader} from '@progress/kendo-react-indicators';
import React, {ReactNode, Suspense} from 'react';

interface LazyLoaderProps {
  children: ReactNode;
}

const LazyLoader = ({children}: LazyLoaderProps) => {
  return (
    <Suspense
      fallback={
        <Loader
          size="medium"
          type="infinite-spinner"
          style={{
            position: 'absolute',
            width: '100px',
            top: '50%',
            left: '50%',
          }}
        />
      }>
      {children}
    </Suspense>
  );
};

export default LazyLoader;
