import {GridColumnMenuWrapper} from '@progress/kendo-react-grid';
import React from 'react';

export const HeaderCell = (props: any) => (
  <React.Fragment>
    <span className="k-cell-inner" title={props.title}>
      <span className="k-link" onClick={props.onClick}>
        <span className="k-column-title">
          {props.title}
          {props.children}
        </span>
      </span>
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps}></GridColumnMenuWrapper>
    </span>
  </React.Fragment>
);
