import React, {ReactNode} from 'react';

interface NewTabGridProps<T> {
  children: ReactNode;
  style?: React.CSSProperties;
}

const NewTabGrid = <T extends Object>(props: NewTabGridProps<T>) => {
  const {children, style} = props;

  return (
    <table className="checklist" style={style}>
      <tbody>{children}</tbody>
    </table>
  );
};

export default NewTabGrid;
