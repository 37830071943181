import React, {ReactNode} from 'react';
import {Box, Dialog, Typography, IconButton, DialogContent, DialogActions, Button} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

interface GenericModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  text: string;
  children: ReactNode;
  buttons: ReactNode;
}

const GenericModal = ({isModalOpen, onClose, text, children, buttons}: GenericModalProps) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isModalOpen} maxWidth="sm" fullWidth>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        style={{backgroundColor: '#0080C1', padding: '0 10px '}}>
        <Typography variant="h6" style={{color: 'white', paddingLeft: '14px'}}>
          {text}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} style={{color: 'white', paddingRight: '2px'}} />
        </IconButton>
      </Box>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions style={{padding: '8px 24px'}}>{buttons}</DialogActions>
    </Dialog>
  );
};

export default GenericModal;
