import * as React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';
import { removeUserSession, getToken, getTrust, getUser, getUserSettings } from '../Utils/Common';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import {SearchLocation} from '../Utils/types';
import GenericModal from './common/modals/GenericModal';
import Treeview, {TreeNode} from './common/Treeview';
import {transformColumnsToTreeview, updateColumns, updateTreeViewColumns, getAllLocationsString} from './common/collapsable-list/util';
import {SidebarContext} from './context/SidebarContextProvider';
import Notification from './common/Notification';
import {Notification as Notification2} from './context/SidebarContextProvider';
interface LocationTreeViewProps {
  history: any[];
  selectedLocationsCallBack: (selectedLocations: SearchLocation) => void;
  locations?: SearchLocation;
  windowVisible: boolean;
  healthRoster: boolean;
  closeButtonCallBack: () => void;
  filtersChangeCallBack?: (searchLocations: string, startDate?: Date, endDate?: Date, isRestoringDefault?: boolean) => void;
  setActiveSearchLocations?: React.Dispatch<React.SetStateAction<string>>;
  activeSearchLocations?: string;
  setLocationsString?: React.Dispatch<React.SetStateAction<string>>;
  startDate?: Date;
  endDate?: Date;
}

export interface TreeViewItem {
  locationId: number;
  locationName: string;
  checked: boolean;
  cscId: number;
  items: any;
  expanded: boolean;
}

export const useStyles = makeStyles({
  btn: {
    background: '#0080c1',
    color: 'white',
    textTransform: 'unset',
    fontSize: '12px',
    '&:hover': {
      background: '#0080c1',
      opacity: 0.9,
    },
  },
  defaultBtn: {textTransform: 'unset', fontSize: '12px'},
});

export const getChildUnits = (metricCodes: string, treeData: TreeNode[]): string => {
  const newColumns = updateColumns(metricCodes, {
    parentColumns: treeData,
    textField: 'locationName',
    valueField: 'locationId',
    valueSecondField: 'locationId',
    compareField: 'esrDivision',
  });

  const childCols = newColumns.childColumns;
  let selectedLocations = '';
  childCols.forEach((location) => {
    if ('locationId' in location) {
      if (selectedLocations) {
        selectedLocations += `,${location.locationId.toString()}`;
      } else {
        selectedLocations += location.locationId.toString();
      }
    }
  });
  return selectedLocations;
};

const escapeRegExpMatch = function (s: string) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};
export const isExactMatch = (str: string, match: string) => {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
};

export const onSearch = (searchResults: TreeNode[], updatedColumns: TreeNode[]): TreeNode[] => {
  const searchResultsCopy = [...searchResults];
  const updatedColumnssCopy = [...updatedColumns];

  const searchResultsChecked: string[] = [];

  searchResultsCopy.forEach((node) => {
    if (node.checked) searchResultsChecked.push(node.value.toString());
    if (node.items) {
      node.items.forEach((n) => {
        if (n.checked) searchResultsChecked.push(n.value.toString());
      });
    }
  });

  if (searchResultsChecked.length < 1) {
    updatedColumnssCopy.forEach((node) => {
      if (searchResultsChecked.includes(node.value.toString()) && !node.checked) node.checked = true;
      if (node.items) {
        node.items.forEach((n) => {
          if (searchResultsChecked.includes(n.value.toString()) && !n.checked) n.checked = true;
        });
      }
    });
  }

  return searchResultsChecked.length < 1 ? updatedColumnssCopy : searchResultsCopy;
};

const LocationsTreeView = (props: LocationTreeViewProps) => {
  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  const [columns, setColumns] = useState<string>('');
  const [updatedColumns, setUpdatedColumns] = useState<TreeNode[]>([]);
  const [searchResults, setSearchResults] = useState<TreeNode[]>([]);
const [isChoosingFromAllLocations, setIsChoosingFromAllLocations] = useState<boolean>(false); 

    const isOceansblueUser = getUser().includes('@oceansblue.co.uk');
    const canChooseAllLocation = getUserSettings().canChooseAllLocation;
    const canCreateTrustProfiles = getUserSettings().canCreateTrustProfiles;

  const {
    handleActiveUnitProfile,
    handleIsCreatingNewProfile,
    handleSideBar,
    handleSetHeader,
    handleNewUnitProfile,
    handleActiveLinkedProfile,
    activeUnitProfile,
    handleActiveUnits,
    activeUnits,
    handleProfile,
  } = React.useContext(SidebarContext);

  const data = transformColumnsToTreeview(columns, {
    parentColumns: treeData,
    textField: 'locationName',
    valueField: 'locationId',
    valueSecondField: 'locationId',
    compareField: 'esrDivision',
  });

  const classes = useStyles();

    useEffect(() => loadLocations(false, false), []);

    const loadLocations = (stateChange:boolean, isChoosingFromAllLocations:boolean) =>
    {
        const token = getToken();
        if (!token) {
            return;
        }

        if (stateChange === false) {
            if (treeData.length > 1) {
                return;
            }
        }

        let serviceUri = 'Locations/GetTreeViewLocations';
        if (props.healthRoster) {
            serviceUri = 'HealthRosterLocations/GetLocations';
            axios
                .post(
                    process.env.REACT_APP_APIURL + serviceUri,
                    {
                        trustName: getTrust().trustName,
                        email: getUser(),
                        useLocString: !isChoosingFromAllLocations
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + getToken(),
                        },
                    },
                )
                .then((response) => {
                    setTreeData(response.data);
                })
                .catch((error: any) => {
                    if (error.response.status === 401) {
                        removeUserSession();
                        props.history.push('/login');
                    }
                    console.log(error);
                });
        } else {
            axios
                .post(
                    process.env.REACT_APP_APIURL + serviceUri,
                    {
                        trust: getTrust()!.trustName,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + getToken(),
                        },
                    },
                )
                .then((response) => {
                    setTreeData(response.data);
                })
                .catch((error: any) => {
                    if (error.response.status === 401) {
                        removeUserSession();
                        props.history.push('/login');
                    }
                    console.log(error);
                });
        }
    }
  const toggleDialog = () => {
    props.closeButtonCallBack();
    setSearchResults([]);
    setUpdatedColumns([]);
  };

  const runSearch = () => {
    const updatedColumnsCopy = onSearch(searchResults, updatedColumns);
    let selectedLocations = updateTreeViewColumns(updatedColumnsCopy);
    let childLocations = selectedLocations.substring(selectedLocations.indexOf(';') + 1) || selectedLocations;

    if (childLocations !== undefined && props.filtersChangeCallBack) {
      props.filtersChangeCallBack(childLocations, props.startDate && props.startDate, props.endDate && props.endDate);
      handleActiveUnitProfile(undefined);
    }
    const locationsStr = handleLocationsString(childLocations, data);
    if (props.setLocationsString) props.setLocationsString(locationsStr);
    if (props.setActiveSearchLocations) props.setActiveSearchLocations(selectedLocations);
    toggleDialog();
    setSearchResults([]);
    setUpdatedColumns([]);

    handleActiveLinkedProfile(undefined);
    };

  const onAllLocationsClick = () => {
      setIsChoosingFromAllLocations(!isChoosingFromAllLocations)
      loadLocations(true, !isChoosingFromAllLocations);
  };

  const handleLocationsString = (selectedLocations: string, data: TreeNode[]): string => {
    let locationsStr = '';
    data.forEach((node) => {
      if (Array.isArray(node.items)) {
        node.items.forEach((n) => {
          if (n.value && isExactMatch(selectedLocations, n.value.toString())) {
            if (locationsStr) locationsStr += `,${n.text}`;
            else locationsStr += `${n.text}`;
          }
        });
      }
      if (node.items && node.items.length < 1) {
        if (node.value && isExactMatch(selectedLocations, node.value.toString())) {
          if (locationsStr) locationsStr += `,${node.text}`;
          else locationsStr += `${node.text}`;
        }
      }
    });
    return locationsStr;
  };

  const addNewProfile = async (isStatic: boolean) => {
    handleSideBar(true);
    handleSetHeader('Units');
    handleProfile(undefined);
    if (!activeUnitProfile && !activeUnits) {
      const selectedLocations = await getAllLocationsString();
      handleActiveUnits({metricCodes: selectedLocations, longName: '', positionIndex: 0, profileType: 2, id: 1});
    } else {
      handleNewUnitProfile({profile: activeUnitProfile, isStatic});
    }

    handleIsCreatingNewProfile(true);
    toggleDialog();
  };

  React.useEffect(() => {
    let metricCodes = '';
    if (activeUnitProfile) {
      metricCodes = activeUnitProfile.metricCodes;
    }
    if (props.activeSearchLocations) {
      metricCodes = props.activeSearchLocations;
    }

    const selectedLocations = metricCodes.includes(';') ? getChildUnits(metricCodes, treeData) : metricCodes;
    let locationsStr = '';

    if (activeUnitProfile && !props.activeSearchLocations && selectedLocations) {
      locationsStr = handleLocationsString(selectedLocations, data);
      if (props.setLocationsString) props.setLocationsString(locationsStr);
    }

    if (props.activeSearchLocations && props.filtersChangeCallBack && props.setLocationsString && selectedLocations) {
      locationsStr = handleLocationsString(selectedLocations, data);
      if (props.setLocationsString) props.setLocationsString(locationsStr);
      handleActiveUnits({metricCodes: metricCodes, longName: '', positionIndex: 0, profileType: 2, id: 1});
      props.filtersChangeCallBack(selectedLocations, props.startDate && props.startDate, props.endDate && props.endDate);
    }

    if (!props.activeSearchLocations && !activeUnitProfile) {
      if (props.setLocationsString) props.setLocationsString('');
    }

    setColumns(metricCodes);
  }, [activeUnitProfile, props.activeSearchLocations, treeData]);

  return (
    <>
      <GenericModal
        isModalOpen={props.windowVisible}
        onClose={() => {
          toggleDialog();
          setSearchResults([]);
          setUpdatedColumns([]);
        }}
        text={props.healthRoster ? 'Ward/Unit' : 'Locations'}
        buttons={
          <>
            <Button onClick={toggleDialog} variant="contained" title="Close the window" className={classes.defaultBtn}>
              Close
            </Button>

            <Button
              onClick={() => {
                runSearch();
              }}
              variant="contained"
              title="Close the window and run the search"
              className={classes.btn}>
              Search
            </Button>
          </>
        }>
        <Treeview
                  nodes={data}
                  updatedColumns={updatedColumns}
                  setUpdatedColumns={setUpdatedColumns}
                  placeholder="Search units..."
                  setSearchResults={setSearchResults}
                  searchResults={searchResults}
                  profileType={2}
                  isOceansblueUser={isOceansblueUser}
                  onTrustProfileClick={() => addNewProfile(true)}
                  onUserProfileClick={() => addNewProfile(false)}
                  canChooseAllLocation={canChooseAllLocation}
                  canCreateTrustProfiles={canCreateTrustProfiles }
                  isChoosingFromAllLocations={isChoosingFromAllLocations}
                  onAllLocationsClick={() => onAllLocationsClick()}
              />
      </GenericModal>
    </>
  );
};

export default LocationsTreeView;
