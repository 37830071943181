import * as React from 'react';
import {GridColumnMenuItemGroup, GridColumnMenuItem, GridColumnMenuItemContent, GridColumnMenuProps} from '@progress/kendo-react-grid';
import gridColumnsDef from './WardGuardianGridColumns.json';
import {SidebarContext} from './context/SidebarContextProvider';

interface CustomColumnMenuProps extends GridColumnMenuProps {
  columns: Array<columnInterface>;
  onColumnsSubmit: (event: any) => void;
}

export interface columnInterface {
  title?: string;
  field?: string;
  show?: boolean;
  filter?: 'boolean' | 'numeric' | 'text' | 'date' | string | undefined;
  minWidth?: number;
  minGridWidth?: number;
  locked?: boolean;
  width?: string | number;
}

export const CustomParentColumnMenu = (props: CustomColumnMenuProps) => {
  const [columns, setColumns] = React.useState<Array<columnInterface>>(props.columns);
  const [columnsExpanded, setColumnsExpanded] = React.useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = React.useState<boolean>(false);

  const {handleActiveProfile, handleActiveColumns} = React.useContext(SidebarContext);

  const onToggleColumn = (col: any) => {
    let cols: any = [...columns];
    const colIsChecked = cols.find((column: any) => column && col && column.group === col.group);
    if (colIsChecked) {
      cols = cols.filter((column: any) => column && col && column.group !== col.group);
    } else {
      cols = gridColumnsDef.ParentColumns.map((column: any) => {
        if (cols.find((c: any) => column && c && column.group === c.group)) return column;
        if (column && col && column.group === col.group) return column;
      });
    }
    setColumns(cols);
  };

  const onReset = (event: any) => {
    event.preventDefault();
    const newColumns: Array<columnInterface> = gridColumnsDef.ParentColumns.map((col) => {
      return {
        ...col,
        show: true,
      };
    });
    setColumns(newColumns);
    handleActiveProfile(undefined);
    props.onColumnsSubmit(newColumns);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
    handleActiveColumns(undefined);
  };

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    props.onColumnsSubmit(columns);
    handleActiveProfile(undefined);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onMenuItemClick = () => {
    const value = !columnsExpanded;
    setColumnsExpanded(value);
    setFilterExpanded(value ? false : filterExpanded);
  };

  const isChecked = (col: any) => {
    const cols = columns;
    let checked = false;
    cols.forEach((column: any) => {
      if (column && col && column.group === col.group) {
        checked = true;
      }
    });
    return checked;
  };

  return (
    <div>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem title={'Columns'} iconClass={'k-i-columns'} onClick={onMenuItemClick} />
        <GridColumnMenuItemContent show={columnsExpanded}>
          <div className={'k-column-list-wrapper'}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={'k-column-list'}>
                {gridColumnsDef.ParentColumns.map((column, idx) => (
                  <div key={idx} className={'k-column-list-item'}>
                    <span>
                      <input
                        id={`column-visiblity-show-${idx}`}
                        className="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        readOnly={true}
                        checked={isChecked(column)}
                        onClick={() => {
                          onToggleColumn(column);
                        }}
                      />
                      <label
                        htmlFor={`column-visiblity-show-${idx}`}
                        className="k-checkbox-label"
                        style={{paddingLeft: '5px', userSelect: 'none'}}>
                        {column.title}
                      </label>
                    </span>
                  </div>
                ))}
              </div>
              <div className={'k-actions k-hstack k-justify-content-stretch'}>
                <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'}>
                  Reset
                </button>
                <button className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}>Save</button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  );
};
