import * as React from 'react';
import {AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import { getToken, getTrust, GetTrustSettings, setTrust, setUserSettings, getUser} from '../Utils/Common';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import jwt_decode from 'jwt-decode';
import {Trust, TrustSettings} from '../Utils/types';

import {SidebarContext} from './context/SidebarContextProvider';

interface NavMenuProps {
  history: any[];
  selectedTab: number;
  disabledTabs: boolean[];
}

interface JwtPayload {
  TrustAccess: string;
}

export default function NavMenu(props: NavMenuProps) {
  const {handleSetHeader, handleSideBar, handleProfile} = React.useContext(SidebarContext);

  const token = getToken();
  let auth = true;
  if (!token) {
    auth = false;
  }
  const [dropdownValues, setDropdownValues] = React.useState();
  const [dropdownSelectedValue, setDropdownSelectedValue] = React.useState();
  const [selected, setSelected] = React.useState(props.selectedTab);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [supportEmail, setSupportEmail] = React.useState<string>();
  const open = Boolean(anchorEl);
  const [numberOfTrusts, setNumberOfTrusts] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const [items, setItems] = React.useState([
    {
      disabled: props.disabledTabs[0],
      id: 'dataquality',
      text: 'Barnacles',
      link: '/dataquality',
    },
    {
      disabled: props.disabledTabs[1],
      id: 'WardGuardian',
      text: 'Ward Guardian',
      link: '/WardGuardian',
    },
    {
      disabled: props.disabledTabs[2],
      id: 'Foresight',
      text: 'Foresight',
      link: '/Foresight',
    },
  ]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuGotoUrl = (siteUrl: any) => (ev: any) => {
    props.history.push(siteUrl);
  };

  const loadData = async () => {
    let trustSettings = await GetTrustSettings(getTrust().trustId);
    setSupportEmail(
      trustSettings!.supportEmailAddress != undefined ? 'mailto:' + trustSettings!.supportEmailAddress : 'mailto:support@oceansblue.co.uk',
    );
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    let trust: Trust = {
      trustId: event.target.value.TrustId,
      trustName: event.target.value.TrustName,
    };
    setTrust(trust);
      let UserSettings = {
          defaultVerbosityLevel: event.target.value.VerbosityLevel,
          canChooseAllLocation: event.target.value.CanChooseAllLocation,
          canCreateTrustProfiles: event.target.value.CanCreateTrustProfiles
      }
      setUserSettings(UserSettings)
    setDropdownSelectedValue(event.target.value);
    window.location.reload();
  };

  const handleGo = async () => {
    console.log('go');
    let trustSettings: TrustSettings | undefined = await GetTrustSettings(getTrust().trustId);
    if (trustSettings === undefined || trustSettings === null) {
      props.history.push('/DataQuality');
    } else {
      if (trustSettings.softwareProvider === 'Allocate') {
        props.history.push('/wardguardian');
      } else {
        props.history.push('/DataQuality');
      }
    }
  };

  React.useEffect(() => {
    setLoading(true);
    loadData();
    const token = getToken();
    const valuesArray = JSON.parse(jwt_decode<JwtPayload>(token!)!.TrustAccess);
    setNumberOfTrusts(valuesArray!.length);
    setDropdownValues(valuesArray);
    const trustId = getTrust()!.trustId;
    const selectedValue = valuesArray.find((trust: any) => trust.TrustId === trustId);
    if (selectedValue) {
      setDropdownSelectedValue(selectedValue);
    }
    setLoading(false);
  }, []);

  return (
    <AppBar>
      <AppBarSection className="title">
        <span className="oceansbluefont">
          <span>Ward Guardian Compliance Console</span>
          {/* <span style={italicText}>Ward Guardian Compliance Console</span> */}
        </span>
      </AppBarSection>

      <AppBarSpacer style={{width: 500}} />

      <AppBarSpacer />

      <AppBarSection>
        {!{loading}.loading ? (
          <div>
            {numberOfTrusts === 1 ? (
              <span className="trustpadding"> {getTrust()!.trustName}</span>
            ) : (
              <div style={{paddingRight: '10px', paddingBottom: '5px'}}>
                <DropDownList
                  data={dropdownValues}
                  textField="TrustName"
                  onChange={handleChange}
                  defaultValue={dropdownSelectedValue}
                  style={{width: '140px'}}
                />
              </div>
            )}
          </div>
        ) : (
          <span></span>
        )}
        <span className="k-appbar-separator" />
        <span className="trustpadding">{`Logged in as ${getUser()}`}</span>
        <span className="k-appbar-separator" />
        <span className="trustpadding">
          {' '}
          <a href={supportEmail}>Get Support</a>
        </span>
        <span className="k-appbar-separator" />
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            onBlur={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}>
            <MenuItem onClick={menuGotoUrl('/changepassword')}>Change Password</MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Columns');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Column Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Units');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Unit Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Linked');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Linked Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Subscriptions');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Subscriptions
            </MenuItem>
            {getUser().includes('@oceansblue.co.uk') && <MenuItem onClick={() => window.open('UserActivity')}>View User Activity</MenuItem>}
            <MenuItem onClick={menuGotoUrl('/logout')}>Logout</MenuItem>
          </Menu>
        </div>
      </AppBarSection>
    </AppBar>
  );
}
