import * as React from 'react';
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
} from '@progress/kendo-react-grid';
import {sortArrCustom, filterArrByCol} from './GridCellTypes';
import {classNames} from '@progress/kendo-react-common';
import gridColumnsDef from './WardGuardianGridColumns.json';
import {SidebarContext} from './context/SidebarContextProvider';

export class CustomColumnMenu extends React.Component {
  static contextType = SidebarContext;
  constructor(props) {
    super(props);

    this.state = {
      columns: this.props.columns,
      columnsExpanded: false,
      rowsExpanded: false,
      filterExpanded: false,
      value: 'All',
    };
  }

  onToggleColumn = (col) => {
    let cols = [...this.state.columns];
    const colIsChecked = cols.find((column) => column && col && column.field === col.field);
    if (colIsChecked) {
      cols = cols.filter((column) => column && col && column.field !== col.field);
    } else {
      cols = gridColumnsDef.Columns.map((column) => {
        if (cols.find((c) => column && c && column.field === c.field)) return column;
        if (column && col && column.field === col.field) return column;
      });
    }
    this.setState({columns: cols});
  };

  onReset = (event) => {
    event.preventDefault();
    const allColumns = gridColumnsDef.Columns.map((col) => {
      return {
        ...col,
        show: true,
      };
    });
    const {handleActiveProfile, handleActiveColumns} = this.context;
    handleActiveProfile(undefined);
    this.props.onColumnsSubmit(allColumns);
    this.setState({columns: allColumns}, () => this.onSubmit());
    handleActiveColumns(undefined);
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const {handleActiveProfile} = this.context;
    handleActiveProfile(undefined);
    this.props.onColumnsSubmit(this.state.columns);
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  onMenuItemClick = () => {
    const value = !this.state.columnsExpanded;
    this.setState({
      columnsExpanded: value,
      filterExpanded: value ? false : this.state.filterExpanded,
    });
  };

  onFilterExpandChange = (value) => {
    this.setState({
      filterExpanded: value,
      columnsExpanded: value ? false : this.state.columnsExpanded,
    });
  };

  handleColumnToggle = (state) => {
    this.props.onColumnLockToggle(this.props.column.field || '', state);
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  isChecked = (col) => {
    const cols = this.state.columns;
    let checked = false;
    cols.forEach((column) => {
      if (column && col && column.field === col.field) {
        checked = true;
      }
    });
    return checked;
  };

  handleChange = (type) => {
    let columnFiltersCopy = [...this.props.columnFilters];
    const filterAlreadyExists = columnFiltersCopy.find((f) => this.props.cellConfig && f.field === this.props.cellConfig.field);
    if (filterAlreadyExists) {
      columnFiltersCopy = columnFiltersCopy.filter((f) => this.props.cellConfig && f.field !== this.props.cellConfig.field);
    }
    columnFiltersCopy.push({field: this.props.cellConfig.field, filterType: type});
    this.props.setColumnFilters(columnFiltersCopy);
  };

  render() {
    const metric = this.props.metrics.find((metric) => metric.metricCode === this.props.cellConfig.metriccode);
    const hasBestOutcome = metric && metric.bestOutcome;
    const filter = this.props.columnFilters.find((f) => this.props.cellConfig && f.field === this.props.cellConfig.field);

    return (
      <div>
        <GridColumnMenuSort
          {...this.props}
          onSortChange={(desc, dir) => {
            this.props.setGridState((state) => ({...state, sort: [{field: this.props.cellConfig.field, dir: desc[0].dir}]}));
          }}
        />
        <GridColumnMenuFilter {...this.props} onExpandChange={this.onFilterExpandChange} expanded={this.state.filterExpanded} />
        {hasBestOutcome && (
          <GridColumnMenuItemGroup>
            <GridColumnMenuItem
              title={'Show All'}
              iconClass={'k-i-rows'}
              onClick={() => this.handleChange('All')}
              selected={(filter && filter.filterType === 'All') || !filter}
            />
            <GridColumnMenuItem
              title={'Show Pass'}
              iconClass={'k-i-check k-i-checkmark greentick'}
              onClick={() => this.handleChange('Passed')}
              selected={filter && filter.filterType === 'Passed'}
            />
            <GridColumnMenuItem
              title={'Show Fail'}
              iconClass={'k-i-close k-i-x redtick'}
              onClick={() => this.handleChange('Failed')}
              selected={filter && filter.filterType === 'Failed'}
            />
          </GridColumnMenuItemGroup>
        )}
        <GridColumnMenuItemGroup>
          <GridColumnMenuItem title={'Columns'} iconClass={'k-i-columns'} onClick={this.onMenuItemClick} />
          <GridColumnMenuItemContent show={this.state.columnsExpanded}>
            <div className={'k-column-list-wrapper'}>
              <form onSubmit={this.onSubmit} onReset={this.onReset}>
                <div className={'k-column-list'}>
                  {gridColumnsDef.Columns.map((column, idx) => (
                    <div key={idx} className={'k-column-list-item'}>
                      <span>
                        <input
                          id={`column-visiblity-show-${idx}`}
                          className="k-checkbox k-checkbox-md k-rounded-md"
                          type="checkbox"
                          readOnly={true}
                          checked={this.isChecked(column)}
                          onClick={() => {
                            this.onToggleColumn(column);
                          }}
                        />
                        <label
                          htmlFor={`column-visiblity-show-${idx}`}
                          className="k-checkbox-label"
                          style={{paddingLeft: '5px', userSelect: 'none'}}>
                          {column.title}
                        </label>
                      </span>
                    </div>
                  ))}
                </div>
                <div className={'k-actions k-hstack k-justify-content-stretch'}>
                  <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'}>
                    Reset
                  </button>
                  <button className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}>Save</button>
                </div>
              </form>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
        <GridColumnMenuItemGroup>
          <GridColumnMenuItemContent show={true}>
            <div className={'k-column-list-wrapper'}>
              <div className={'k-column-list'}>
                <div
                  className={classNames('k-column-list-item', {
                    'k-disabled': this.props.locked,
                  })}
                  onClick={() => this.handleColumnToggle(true)}>
                  <span className="k-icon k-i-lock" /> Lock Column
                </div>
                <div
                  className={classNames('k-column-list-item', {
                    'k-disabled': !this.props.locked,
                  })}
                  onClick={() => this.handleColumnToggle(false)}>
                  <span className="k-icon k-i-unlock" /> Unlock Column
                </div>
              </div>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      </div>
    );
  }
}
