import React, {useEffect, useState} from 'react';
import NavMenus from './NavMenu';
import axios, {AxiosResponse} from 'axios';
import { removeUserSession, getToken, getTrust, getUser, GetMetricMaster, getUserSettings } from '../Utils/Common';
import {Loader} from '@progress/kendo-react-indicators';
import {CustomColumnMenu} from './ColumnMenu.jsx';
import {CustomParentColumnMenu} from './ParentColumnMenu';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridColumnResizeEvent,
  GridColumnProps,
  GridColumnReorderEvent,
} from '@progress/kendo-react-grid';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {SortDescriptor, process as gridprocess, State, DataResult} from '@progress/kendo-data-query';
import Moment from 'moment';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {filterBy} from '@progress/kendo-data-query';
import {setGroupIds, getGroupIds, setExpandedState} from '@progress/kendo-react-data-tools';

import gridColumnsDef from './WardGuardianGridColumns.json'; //defined the columns that are included and their default visability
import {
  WardGuardianLinkCell,
  WardGuardianEmailLinkCell,
  ApproverViolationCell,
  HeaderCell,
  WGTickCell,
  SumFooterCell,
  AverageFooterCell,
  hhmmSum,
  convertTohhmm,
  CountsFooterCell,
  PercentagesFooterCell,
  getCompliantRosters,
  sortArrCustom,
  convertDateToTimestamp,
  filterArrByCol,
  getCompliancyScoreAverage,
  getGroupValue,
} from './GridCellTypes';
import WardGuardianFiltersView from './WardGuardianFiltersView';
import {CellConfig, MetricMaster} from '../Utils/types';
import {ProfilesResponse, StaticOrUserProfile} from './common/collapsable-list/ColumnsCollapsableList';
import {updateColumns, getLocations, colsToString} from './common/collapsable-list/util';
import {getChildUnits} from './LocationsTreeView';
import {Notification as Notification2, SidebarContext} from '../components/context/SidebarContextProvider';
import Notification from './common/Notification';
import {checkProfileTypes} from './common/grid/util';
import {WorkbookOptions, WorkbookSheetRowCell} from '@progress/kendo-ooxml';
import {LinkedProfile} from './common/grid/pages/LinkedProfiles';
import {LocalizationProvider} from '@mui/x-date-pickers';

interface NotificationItem {
  show: boolean;
  row: string;
  id: number;
}

export type KPIState = 'All' | 'Failed' | 'Passed';
export interface KPIFilter {
  field: string;
  filterType: KPIState;
}

export interface ActiveProfile {
  isStatic: boolean;
  profile: StaticOrUserProfile | undefined | null;
}

export const processWithGroups = (data: any, dataState: State): DataResult => {
  if (Array.isArray(data) && data.length > 0) {
    const dataStateCopy = {...dataState};
    dataStateCopy.sort = [];
    const newDataState = gridprocess(data, dataStateCopy);
    setGroupIds({data: newDataState.data, group: dataStateCopy.group});
    return newDataState;
  }
  return {data, total: data.length};
};

let defaultSort: SortDescriptor[] = [{field: 'daysNotice', dir: 'asc'}];
let defaultStartDate = Moment().toDate();
let defaultEndDate = Moment().add(4, 'weeks').toDate();
const _exporter = React.createRef<ExcelExport>();
const rowLimitForNoVirtual: number = 60000;
const pageSize = 200;

export const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export default function WardGuardian(props: {history: any[]}) {
  let initialDataState: State = {
    sort: defaultSort,
    skip: 0,
  };

  const selectedAppBarTab: number = 1;
  const [jsonData, setjsonData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [gridHeight, setGridHeight] = React.useState<number>(0);

  const [dataGridColumns, setDataGridColumns] = useState({
    gridColumns: gridColumnsDef.Columns,
    gridParentColumns: gridColumnsDef.ParentColumns,
  });
  const [updatedGridColumns, setUpdatedGridColumns] = useState<any>();
  const [errorNotification2, setErrorNotification2] = React.useState(false);
  const [notifications, setNotifications] = React.useState<NotificationItem[]>([]);
  const [groupable, setGroupable] = React.useState(false);
  const [selectedVerbosityLevel, setSelectedVerbosityLevel] = React.useState();
  const [metricMaster, setMetricMasters] = React.useState<MetricMaster[]>([]);
  const [locationsString, setLocationsString] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState<KPIFilter[]>([]);
    const [totalsMode, setTotalsMode] = React.useState<'totals' | 'counts' | 'percentages'>('totals');
  const [kpiStateMode, setKpiStateMode] = React.useState<KPIState>('All');

  let MyGrid = React.useRef<Grid>(null);
  let gridContainerRef = React.useRef<HTMLDivElement>(null);

  let notificationId: number = 0;

  const [gridState, setGridState] = React.useState<State>(initialDataState);
  const [collapsedState, setCollapsedState] = React.useState<any[]>([]);
  const [errorNotification, setErrorNotification] = React.useState<Notification2>({text: '', show: false});
  const [dates, setDates] = React.useState<{startDate: Date | undefined; endDate: Date | undefined}>({
    startDate: undefined,
    endDate: undefined,
  });
  const metrics: any[] = [];
  dataGridColumns.gridColumns.forEach((col) => {
    if (
      metricMaster.find((c) => col && c && c.targetValue !== null && (c.sourceColumnName === col.field || c.metricCode === col.metriccode))
    ) {
      metrics.push(col);
    }
  });
  const resultState = React.useMemo(() => {
    let data: DataResult | undefined;
    const field = gridState.sort && gridState.sort[0] && gridState.sort[0].field;
    const col: any = dataGridColumns.gridColumns.filter((item) => item && item.field === field)[0];

    if (Array.isArray(columnFilters) && columnFilters.length > 0) {
      const filteredData = filterArrByCol(jsonData, columnFilters);
      data = {data: filteredData, total: filteredData.length};
    }
    if (
      col &&
      (col.sortType === 'of' || col.filter === 'date' || col.sortType === 'hhmm' || col.filter === 'numeric' || col.filter === 'text')
    ) {
      const sortType =
        col.filter === 'date'
          ? 'date'
          : col.sortType === 'of'
          ? 'of'
          : col.sortType === 'hhmm'
          ? 'hhmm'
          : col.filter === 'numeric'
          ? 'number'
          : col.filter === 'text'
          ? 'text'
          : 'bool';
      if (sortType !== 'bool' && gridState.sort && data) {
        const sortedData = sortArrCustom(data.data, gridState, sortType, metrics, field, gridState.sort[0].dir);
        data = {data: sortedData.data, total: sortedData.data.length};
      }
    }
    let processedData = processWithGroups(data ? data.data : jsonData, gridState);
    if (gridState.group && gridState.group.length > 0) {
      processedData.data.sort((a, b) => {
        if (!getGroupValue(totalsMode, metricMaster, a.items, metrics, col)) {
          return 1;
        }
        if (!getGroupValue(totalsMode, metricMaster, b.items, metrics, col)) {
          return -1;
        }
        if (
          gridState.sort &&
          getGroupValue(totalsMode, metricMaster, a.items, metrics, col) < getGroupValue(totalsMode, metricMaster, b.items, metrics, col)
        ) {
          return -1;
        }
        if (
          gridState.sort &&
          getGroupValue(totalsMode, metricMaster, a.items, metrics, col) > getGroupValue(totalsMode, metricMaster, b.items, metrics, col)
        ) {
          return 1;
        }
        return 1;
      });
      if (gridState.sort && gridState.sort[0] && gridState.sort[0].dir === 'desc') {
        processedData.data.reverse();
      }
    }
    return processedData;
  }, [jsonData, gridState, columnFilters, totalsMode]);
  const flattenedGroupData = () => {
    if (groupable && gridState.group && gridState.group.length > 0) {
      const flattenedArray: any[] = [];
      resultState.data.map((group) => {
        if (Array.isArray(group.items)) {
          group.items.map((roster: any) => {
            flattenedArray.push(roster);
          });
        }
      });
      return flattenedArray;
    }
    return undefined;
  };

  const rosterCount = (): number => {
    if (groupable && gridState.group && gridState.group.length > 0) {
      let count = 0;
      if (Array.isArray(resultState.data)) {
        resultState.data.forEach((group) => {
          if (Array.isArray(group.items)) {
            count += group.items.length;
          }
        });
      }
      return count;
    }
    return resultState.data.length;
  };

  const {
    isSidebarOpen,
    handleActiveProfile,
    activeProfile,
    handleActiveUnitProfile,
    activeEditedProfile: sidebarActiveProfile,
    activeUnitEditedProfile: sidebarActiveUnitProfile,
    activeUnitProfile: sidebarActiveUnitProfileCtx,
    handleNewUnitProfile,
    handleNewProfile,
    handleActiveColumns,
    handleActiveUnits,
    handleIsCreatingNewProfile,
    handleActiveLinkedProfile,
    activeLinkedProfile,
  } = React.useContext(SidebarContext);

  function handleWindowResize() {
    if (gridContainerRef.current) {
      setGridHeight(gridContainerRef.current.offsetTop + 1);
    }
  }

  const getColumnProfiles = async (linkedProfile?: {id: number; isStatic: boolean}) => {
    try {
      const response: AxiosResponse<ProfilesResponse> = await axios.get(
        process.env.REACT_APP_APIURL + `profiles/GetProfiles?email=${getUser()}&trustId=${getTrust().trustId}&profileTypeId=1`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );

      if (response.status !== 200) {
        setErrorNotification({show: true, text: "Couldn't fetch profiles, please try again or check your connection"});
      }

      const hasDefaultProfile =
        response.data.profiles && response.data.profiles.length > 0 && response.data.profiles.find((item) => item.onLoadFlag === true);

      const hasDefaultStaticProfile =
        response.data.profilesStatic &&
        response.data.profilesStatic.length > 0 &&
        response.data.profilesStatic.find((item) => item.onLoadFlag === true);
      if (linkedProfile) {
        let newColProfile;
        if (linkedProfile.isStatic && response.data.profilesStatic.length > 0) {
          newColProfile = response.data.profilesStatic.find((profile) => profile.id === linkedProfile.id);
        }
        if (!linkedProfile.isStatic && response.data.profiles.length > 0) {
          newColProfile = response.data.profiles.find((profile) => profile.id === linkedProfile.id);
        }
        if (newColProfile) {
          const columns =
            newColProfile.metricCodes &&
            updateColumns(newColProfile.metricCodes, {
              parentColumns: updatedGridColumns ? updatedGridColumns.gridParentColumns : gridColumnsDef.ParentColumns,
              childColumns: updatedGridColumns ? updatedGridColumns.gridColumns : gridColumnsDef.Columns,
              textField: 'title',
              valueField: 'group',
              valueSecondField: 'field',
            });
          columns
            ? setDataGridColumns({gridColumns: columns.childColumns, gridParentColumns: columns.parentColumns})
            : setDataGridColumns({gridColumns: gridColumnsDef.Columns, gridParentColumns: gridColumnsDef.ParentColumns});
          handleActiveProfile(newColProfile);
        }
      } else if (hasDefaultProfile) {
        const columns =
          hasDefaultProfile.metricCodes &&
          updateColumns(hasDefaultProfile.metricCodes, {
            parentColumns: updatedGridColumns ? updatedGridColumns.gridParentColumns : gridColumnsDef.ParentColumns,
            childColumns: updatedGridColumns ? updatedGridColumns.gridColumns : gridColumnsDef.Columns,
            textField: 'title',
            valueField: 'group',
            valueSecondField: 'field',
          });
        columns
          ? setDataGridColumns({gridColumns: columns.childColumns, gridParentColumns: columns.parentColumns})
          : setDataGridColumns({gridColumns: gridColumnsDef.Columns, gridParentColumns: gridColumnsDef.ParentColumns});
        handleActiveProfile(hasDefaultProfile);
      } else if (hasDefaultStaticProfile) {
        const columns =
          hasDefaultStaticProfile.metricCodes &&
          updateColumns(hasDefaultStaticProfile.metricCodes, {
            parentColumns: updatedGridColumns ? updatedGridColumns.gridParentColumns : gridColumnsDef.ParentColumns,
            childColumns: updatedGridColumns ? updatedGridColumns.gridColumns : gridColumnsDef.Columns,
            textField: 'title',
            valueField: 'group',
            valueSecondField: 'field',
          });
        columns
          ? setDataGridColumns({gridColumns: columns.childColumns, gridParentColumns: columns.parentColumns})
          : setDataGridColumns({gridColumns: gridColumnsDef.Columns, gridParentColumns: gridColumnsDef.ParentColumns});
        handleActiveProfile(hasDefaultStaticProfile);
      } else {
        setDataGridColumns({gridColumns: gridColumnsDef.Columns, gridParentColumns: gridColumnsDef.ParentColumns});
        handleActiveProfile(undefined);
      }
    } catch (err) {
      console.log(err);
      setErrorNotification({show: true, text: "Couldn't fetch profiles, please try again or check your connection"});
    }
  };

  const getUnitsProfiles = async (isDefault?: boolean): Promise<string | undefined> => {
    try {
      const response: AxiosResponse<ProfilesResponse> = await axios.get(
        process.env.REACT_APP_APIURL + `profiles/GetProfiles?email=${getUser()}&trustId=${getTrust().trustId}&profileTypeId=2`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );

      const response2: AxiosResponse<LinkedProfile[]> = await axios.get(
        process.env.REACT_APP_APIURL + `linkedprofiles/GetLinkedProfiles?email=${getUser()}&trustId=${getTrust().trustId}`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );

      if (response.status !== 200 || response2.status !== 200) {
        setErrorNotification({show: true, text: "Couldn't fetch profiles, please try again or check your connection"});
      }

      const hasDefaultProfile =
        response.data.profiles && response.data.profiles.length > 0 && response.data.profiles.find((item) => item.onLoadFlag === true);

      const hasDefaultStaticProfile =
        response.data.profilesStatic &&
        response.data.profilesStatic.length > 0 &&
        response.data.profilesStatic.find((item) => item.onLoadFlag === true);

      if (activeLinkedProfile && activeLinkedProfile.isActive && !isDefault) {
        let unitProfile: any;
        let isProfileStatic = false;
        if (activeLinkedProfile.unitProfileIsStatic) {
          unitProfile =
            response.data.profilesStatic.length > 0 &&
            response.data.profilesStatic.find((item) => item.id === activeLinkedProfile.unitProfileId);
          isProfileStatic = true;
        } else {
          unitProfile =
            response.data.profiles &&
            response.data.profiles.length > 0 &&
            response.data.profiles.find((item) => item.id === activeLinkedProfile.unitProfileId);
        }

        if (unitProfile && unitProfile.id) {
          handleActiveUnitProfile(unitProfile);
          const treeData = await getLocations();
          let hasLinkedProfile: any;
          if (isProfileStatic) {
            hasLinkedProfile =
              response2.data && response2.data.find((profile) => profile.unitProfileId === unitProfile.id && profile.unitProfileIsStatic);
          } else {
            hasLinkedProfile =
              response2.data && response2.data.find((profile) => profile.unitProfileId === unitProfile.id && !profile.unitProfileIsStatic);
          }

          if (hasLinkedProfile) {
            getColumnProfiles({id: hasLinkedProfile.columnProfileId, isStatic: hasLinkedProfile.columnProfileIsStatic});
          }
          if (treeData) {
            const selectedLocations = unitProfile.metricCodes.includes(';')
              ? getChildUnits(unitProfile.metricCodes, treeData)
              : unitProfile.metricCodes;
            return selectedLocations;
          }
        }
      } else if (hasDefaultProfile) {
        handleActiveUnitProfile(hasDefaultProfile);
        const treeData = await getLocations();
        const hasDefaultLinkedProfile =
          response2.data &&
          response2.data.find(
            (profile) => profile.unitProfileId === hasDefaultProfile.id && !profile.unitProfileIsStatic && profile.isActive,
          );
        handleActiveLinkedProfile(hasDefaultLinkedProfile);
        if (hasDefaultLinkedProfile) {
          getColumnProfiles({id: hasDefaultLinkedProfile.columnProfileId, isStatic: hasDefaultLinkedProfile.columnProfileIsStatic});
        }
        if (treeData) {
          const selectedLocations = hasDefaultProfile.metricCodes.includes(';')
            ? getChildUnits(hasDefaultProfile.metricCodes, treeData)
            : hasDefaultProfile.metricCodes;
          return selectedLocations;
        }
      } else if (hasDefaultStaticProfile) {
        handleActiveUnitProfile(hasDefaultStaticProfile);
        const treeData = await getLocations();
        const hasDefaultLinkedProfile =
          response2.data &&
          response2.data.find(
            (profile) => profile.unitProfileId === hasDefaultStaticProfile.id && profile.unitProfileIsStatic && profile.isActive,
          );
        handleActiveLinkedProfile(hasDefaultLinkedProfile);
        if (hasDefaultLinkedProfile) {
          getColumnProfiles({id: hasDefaultLinkedProfile.columnProfileId, isStatic: hasDefaultLinkedProfile.columnProfileIsStatic});
        }
        if (treeData) {
          const selectedLocations = hasDefaultStaticProfile.metricCodes.includes(';')
            ? getChildUnits(hasDefaultStaticProfile.metricCodes, treeData)
            : hasDefaultStaticProfile.metricCodes;
          return selectedLocations;
        }
      } else {
        handleActiveUnitProfile(undefined);
        return undefined;
      }
    } catch (err) {
      console.log(err);
      setErrorNotification({show: true, text: "Couldn't fetch profiles, please try again or check your connection"});
    }
  };
  const loadData = async (
    startDate: Date,
    endDate: Date,
    searchLocations: string | undefined,
    restoreDefaults: boolean,
    isMount: boolean,
    isRestoringDefault?: boolean,
    isDefault?: boolean,
  ) => {
    setLoading(true);
    const token = getToken();

    if (!token) {
      return;
    }

    let getUnitLocations;
    if (isMount || restoreDefaults) getColumnProfiles();

    if (isMount || isRestoringDefault) {
      getUnitLocations = await getUnitsProfiles(isDefault);
    }

    axios
      .post(
        process.env.REACT_APP_APIURL + 'rosters/GetRosters',
        {
          startDate: Moment(startDate).format('YYYY-MM-DD'),
          enddate: Moment(endDate).format('YYYY-MM-DD'),
          locations: searchLocations ? searchLocations : getUnitLocations ? getUnitLocations : null,
          trustName: getTrust().trustName,
          email: getUser(),
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      )
      .then((response) => {
        let gridData = response.data;
        setjsonData(gridData);
        setLoading(false);
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          removeUserSession();
          props.history.push('/login');
        }
        console.log(error);
      });
  };

  const generateWGEmail = (
    startDate: Date,
    endDate: Date,
    orgUnitId: number,
    orgUnitName: string,
    notificationid: number,
    verbosityLevel: string | undefined,
  ) => {
    const token = getToken();
    if (!token) {
      return;
    }

    axios
      .post(
        process.env.REACT_APP_APIURL + 'sentinels',
        {
          sentinelType: 'OD',
          verbosityLevel: verbosityLevel === undefined ? 'availabledata' : verbosityLevel,
          orgunitid: orgUnitId,
          startdate: Moment(startDate).format('YYYY-MM-DD'),
          enddate: Moment(endDate).format('YYYY-MM-DD'),
          trustName: getTrust().trustName,
          emailOverride: getUser(),
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      )
      .then((response) => {
        let newNot = {
          row:
            'Ward Guardian for ' +
            orgUnitName +
            ' ' +
            Moment(startDate).format('DD-MM-YY') +
            ' ' +
            Moment(endDate).format('DD-MM-YY') +
            ' requested',
          show: true,
          id: notificationid,
        };
        setNotifications(notifications.concat(newNot));
        setTimeout(() => {
          hideNotification(notificationid);
        }, 8000);
      })
      .catch((error: any) => {
        setErrorNotification2(true);
        if (error.response.status === 401) {
          removeUserSession();
          props.history.push('/login');
        }
        console.log(error);
      });
  };

  const generateWG = (startDate: Date, endDate: Date, orgUnitId: number, orgUnitName: string) => {
    const token = getToken();
    if (!token) {
      return;
    }

    axios
      .post(
        process.env.REACT_APP_APIURL + 'Sentinels/GetSentinelHtml',
        {
          sentinelType: 'OD',
          verbosityLevel: selectedVerbosityLevel,
          orgunitid: orgUnitId,
          startdate: Moment(startDate).format('YYYY-MM-DD'),
          enddate: Moment(endDate).format('YYYY-MM-DD'),
          trustName: getTrust().trustName,
          emailOverride: getUser(),
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      )
      .then((response) => {
        const min = 1;
        const max = 10000;
        const rand = min + Math.random() * (max - min);
        localStorage.setItem('ReportHtml' + rand, response.data[0].messageHtml);
        window.open(
          'ReportViewer/?reportid=' +
            rand +
            '&org=' +
            encodeURIComponent(orgUnitName) +
            '&start=' +
            Moment(startDate).format('DD-MM-YY') +
            '&end=' +
            Moment(endDate).format('DD-MM-YY'),
          '_blank',
        );
      })
      .catch((error: any) => {
        setErrorNotification2(true);
        if (error.response.status === 401) {
          removeUserSession();
          props.history.push('/login');
        }
        console.log(error);
      });
  };

  const loadMetricMasters = async () => {
    let metricMaster: MetricMaster[] | undefined = await GetMetricMaster(getTrust().trustName)!;

    if (metricMaster !== undefined) {
      setMetricMasters(metricMaster);
    }
  };

  function Sum(array: any, key: string) {
    return array.reduce(function (r: any, a: {[x: string]: any}) {
      return r + a[key];
    }, 0);
  }

    function CheckColumnHasTarget(columnName:string, metricCode?:string) {
        return metricMaster.find(
            (c) =>
                c && c.targetValue !== null && c.sourceColumnName != null && (c.sourceColumnName.toUpperCase() === columnName.toUpperCase() || c.metricCode === metricCode),
        )
    }
    
  const HeaderCellRender = (td: any | null, props: GridCellProps) => {
    if (td && td.props.children && props.rowType === 'groupHeader') {
      let columns: any[] = [];
      if (MyGrid.current) {
        let grid: Grid = MyGrid.current;

        if (grid.columns.length > 0) {
          var i;

          for (i = 0; i < grid.columns.length; i++) {
            columns = columns.concat(MyGrid.current.columns[i].children);
          }

          let rowHtml: any = [];

          rowHtml.push(
            <td
              key={Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
              className="k-table-td k-grid-content-sticky"
              colSpan={8}
              style={{...props.style, textAlign: 'left', padding: '0px 8px', borderRightColor: 'transparent'}}
              role="gridcell">
              {' '}
              {td.props.children.props.children[0]} {td.props.children.props.children[1]} : {props.dataItem.items.length}{' '}
              {`Roster${props.dataItem.items.length > 1 ? 's' : ''}`}
            </td>,
          );
          columns.forEach(function (value, index) {
            if (value !== undefined) {
              let cellConfig: CellConfig = dataGridColumns.gridColumns.filter(
                (item) => item && item.field === value.field,
              )[0] as CellConfig;
              const metricConfig = metricMaster.find(
                (i) => (i.metricCode && cellConfig && i.metricCode === cellConfig.metriccode) || i.sourceColumnName === cellConfig.field,
              );
              let groupValue: any;
              if (cellConfig.groupcalc !== undefined) {
                if (
                    totalsMode === 'counts' && value && CheckColumnHasTarget(value.field, value.metriccode)
                ) {
                  groupValue = getCompliantRosters(props.dataItem.items, value.field, false);
                }
                if (
                    totalsMode === 'percentages' && value && CheckColumnHasTarget(value.field, value.metriccode)
                ) {
                  if (value.field === 'complianceScore') {
                    groupValue = `${(
                      (getCompliancyScoreAverage(props.dataItem.items, metrics) / props.dataItem.items.length / metrics.length) *
                      100
                    ).toFixed(1)}%`;
                  } else {
                    groupValue = getCompliantRosters(props.dataItem.items, value.field, true);
                  }
                }
                if (cellConfig.groupcalc === 'sum' && totalsMode === 'totals') {
                  if (cellConfig.sortType && cellConfig.sortType === 'hhmm') {
                    groupValue = convertTohhmm(hhmmSum(props.dataItem.items, value.field));
                  } else if (value.field === 'sameApprover') {
                    groupValue = getCompliantRosters(props.dataItem.items, value.field);
                  } else {
                    groupValue = Sum(props.dataItem.items, value.field!);
                  }
                }
                if (cellConfig.groupcalc === 'average' && totalsMode === 'totals') {
                  let dataArray: any[] = props.dataItem.items;
                  let divisor = dataArray.filter((item) => item[value.field!] != null).length;
                  groupValue = Sum(props.dataItem.items, value.field!) / divisor;
                  if (value.field === 'complianceScore') {
                    groupValue = `${(getCompliancyScoreAverage(dataArray, metrics) / dataArray.length).toFixed(0)} of ${metrics.length}`;
                  }
                  if (cellConfig && 'exportType' in cellConfig && cellConfig.exportType === 'money') {
                    groupValue = groupValue.toFixed(2);
                    groupValue = !isNaN(groupValue) ? groupValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : 0;
                  }
                }
              }

              if (index >= 7) {
                rowHtml.push(
                  <td
                    colSpan={1}
                    role="gridcell"
                    style={{
                      ...props.style,
                      textAlign: 'right',
                      padding: '0px 8px',
                      borderRightColor: 'transparent',
                      left: index === 7 ? '700px' : '0px',
                    }}
                    className={index === 7 ? 'k-grid-content-sticky' : ''}
                    key={`${td.props.children.props.children[0]}_${index}`}>
                    {typeof groupValue === 'number' && !isNaN(groupValue)
                      ? groupValue.toFixed(metricConfig ? metricConfig.displayRounding : 0)
                      : typeof groupValue === 'string'
                      ? groupValue
                      : ''}
                  </td>,
                );
              }
            }
          });
          return rowHtml;
        }
      }
    }
    return td;
  };

  const GetFooterCellHandler = (customcelltype: string, field: string, metricCode?: string, sortType?: string) => {
    if (totalsMode === 'totals') {
      if (customcelltype === 'sum') {
        return (props: any) => (
          <SumFooterCell
            data={groupable && gridState.group && gridState.group.length > 0 ? flattenedGroupData() : resultState.data}
            metricConfig={metricMaster.find((i) => metricCode && i.metricCode && i.metricCode === metricCode)}
            sortType={sortType}
            {...props}
          />
        );
      } else if (customcelltype === 'average') {
        return (props: any) => (
          <AverageFooterCell
            data={groupable && gridState.group && gridState.group.length > 0 ? flattenedGroupData() : resultState.data}
            metricConfig={metricMaster.find((i) => metricCode && i.metricCode && i.metricCode === metricCode)}
            cellConfig={gridColumnsDef.Columns.find((i) => metricCode && i.metriccode && i.metriccode === metricCode)}
            sortType={sortType}
            {...props}
          />
        );
      }
    } else if (
        totalsMode === 'counts' && CheckColumnHasTarget(field, metricCode) && metricCode
    ) {
      return (props: any) => (
        <CountsFooterCell
          metricCode={metricCode}
          data={groupable && gridState.group && gridState.group.length > 0 ? flattenedGroupData() : resultState.data}
          {...props}
        />
      );
    } else if (
        totalsMode === 'percentages' && CheckColumnHasTarget(field, metricCode)  && metricCode
    ) {
      return (props: any) => (
        <PercentagesFooterCell
          metricCode={metricCode}
          data={groupable && gridState.group && gridState.group.length > 0 ? flattenedGroupData() : resultState.data}
          {...props}
        />
      );
    }
  };

  const onColumnsSubmit = (columnsState: any) => {
    let newChildCols: any[] = [];

    columnsState.forEach((c: any) => {
      if (c && c.field) {
        const colExists = dataGridColumns.gridColumns.find((col) => col && col.field && col.field === c.field);
        if (!colExists) {
          newChildCols.push(c);
        }
      }
    });

    let cols = gridColumnsDef.ParentColumns.map((column: any) => {
      if (columnsState && columnsState.find((col: any) => col && column && col.group === column.group)) return column;
      if (newChildCols && newChildCols.find((c) => c && column && column.group === c.group)) return column;
    });

    cols = cols.map((col) => {
      if (columnsState.find((c: any) => c && col && c.group === col.group)) return col;
    });

    const totalString = colsToString(cols);
    handleActiveColumns({metricCodes: totalString, longName: '', positionIndex: 0, profileType: 1, id: 1});
    setDataGridColumns({gridColumns: columnsState, gridParentColumns: cols});
  };

  const onParentColumnsSubmit = (columnsState: any) => {
    let newParentCols: any[] = [];
    columnsState.forEach((c: any) => {
      if (c && c.group) {
        const colExists = dataGridColumns.gridParentColumns.find((col) => col && col.group && col.group === c.group);
        if (!colExists) {
          newParentCols.push(c);
        }
      }
    });

    let cols: any = [...dataGridColumns.gridColumns];

    cols = gridColumnsDef.Columns.map((column: any) => {
      if (cols.find((c: any) => column && c && column.field === c.field)) return column;
      if (newParentCols.length > 0 && newParentCols.find((c) => c && column && column.group === c.group)) return column;
    });

    cols = cols.map((col: any) => {
      if (columnsState.find((c: any) => c && col && c.group === col.group)) return col;
    });

    const totalString = colsToString(cols);
    handleActiveColumns({metricCodes: totalString || '', longName: '', positionIndex: 0, profileType: 1, id: 1});
    setDataGridColumns({gridColumns: cols, gridParentColumns: columnsState});
  };

  const onColumnLockToggle = (column: any, lockedState: any) => {
    let tempGridColumns = [...dataGridColumns.gridColumns];
    tempGridColumns
      .filter((items: {field: any}) => items.field === column)
      .forEach(function (item: {locked?: boolean | undefined}) {
        item.locked = lockedState;
      });
    setDataGridColumns((prevState) => ({...prevState, gridColumns: tempGridColumns}));
  };

  const GetCellHandler = (customcelltype: string) => {
    if (customcelltype === 'wardguardianlink') {
      return WardGuardianLinkCellRender;
    }
    if (customcelltype === 'wardguardianemaillink') {
      return WardGuardianEmailLinkCellRender;
    }
    if (customcelltype === 'approverviolation') {
      return ApproverViolationCellRender;
    }
    if (customcelltype === 'wgtickcell') {
      return WGTickCell;
    }
    return undefined;
  };

  const WardGuardianLinkCellRender = (props: any) => {
    return <WardGuardianLinkCell {...props} ClickCallBack={handleRowButtonClick} />;
  };

  const WardGuardianEmailLinkCellRender = (props: any) => {
    return <WardGuardianEmailLinkCell {...props} ClickCallBack={handleRowButtonEmailClick} />;
  };

  const ApproverViolationCellRender = (props: any) => {
    const col = gridColumnsDef.Columns.filter((item) => item.field === props.field)[0];
    return (
      <ApproverViolationCell
        {...props}
        cellConfig={col}
        metricConfig={metricMaster.find((item) => item.metricCode && col && item.metricCode === col.metriccode)}
        metrics={metrics}
        group={gridState.group}
      />
    );
  };

  const CustomHeaderCell = (props: any) => {
    const col = gridColumnsDef.Columns.filter((item) => item.field === props.field)[0];
    return (
      <HeaderCell
        filterApplied={gridState.filter!}
        {...props}
        data={resultState}
        setGridState={setGridState}
        gridState={gridState}
        jsonData={jsonData}
        cellConfig={col}
        metricMaster={metricMaster.find((item) => item.metricCode && col && item.metricCode === col.metriccode)}
      />
    );
  };

  const createCell = (
    column: {
      show: any;
      title: string | undefined;
      field: string;
      filter?: any | undefined;
      width?: string | undefined;
      customcell?: boolean | undefined;
      locked?: boolean | undefined;
      customcelltype?: string | undefined;
      format?: string | undefined;
      footercelltype?: string | undefined;
      sortType?: string;
      group?: string;
      metriccode?: string;
    },
    idx: string | number | undefined,
  ): any => {
    if (column.show) {
      const groupColumns = dataGridColumns.gridColumns.filter(
        (items: {group: string; show: any}) => items && column && items.group === column.group && items.show === true,
      );
      let totalWidth = 0;
      let newWidth;
      groupColumns.forEach((col) => {
        totalWidth += Number(col.width.replace('px', ''));
      });
      const parentColumn = dataGridColumns.gridParentColumns.find((col) => col && column && col.group === column.group);
      const parentWidth = parentColumn && Number(parentColumn.width.replace('px', ''));
      if (parentWidth && totalWidth < parentWidth) {
        const restOfLength = Number(groupColumns[groupColumns.length - 1].width.replace('px', ''));
        newWidth = restOfLength + parentWidth - restOfLength;
      }
      if (
          totalsMode === 'counts' && CheckColumnHasTarget(column.field, column.metriccode)
      ) {
        newWidth = 100;
      }
      const filter = columnFilters.find((f) => f && column && f.field === column.field);

      return (
        <Column
          key={`${column.field}-${idx}`}
          id={`${column.field}-${idx}`}
          field={column.field}
          title={column.title}
          filter={column.filter}
          locked={column.locked}
          width={newWidth || column.width}
          format={column.format}
          headerClassName={`${
            filter && filter.filterType === 'Failed' ? 'activeredtick' : filter && filter.filterType === 'Passed' ? 'activegreentick' : ''
          }`}
          columnMenu={(props) => (
            <CustomColumnMenu
              {...props}
              setGridState={setGridState}
              columns={dataGridColumns.gridColumns}
              cellConfig={gridColumnsDef.Columns.filter((item) => item.field === column.field)[0]}
              metrics={metricMaster}
              onColumnsSubmit={onColumnsSubmit}
              onColumnLockToggle={onColumnLockToggle}
              locked={column.locked}
              setColumnFilters={setColumnFilters}
              columnFilters={columnFilters}
            />
          )}
          cell={GetCellHandler(column.customcelltype!)}
          headerCell={CustomHeaderCell}
          footerCell={GetFooterCellHandler(column.footercelltype!, column.field, column.metriccode, column.sortType)}
        />
      );
    }
  };
  //#endregion

  const handleRowButtonEmailClick = (props: any) => {
    let dataItem = props.dataItem;
    setErrorNotification2(false);
    notificationId = notificationId + 1;
    generateWGEmail(
      dataItem['rosterStartDate'],
      dataItem['rosterEndDate'],
      dataItem['orgUnitId'],
      dataItem['orgUnitName'],
      notificationId,
      selectedVerbosityLevel,
    );
  };

  const handleRowButtonClick = (props: any) => {
    notificationId = notificationId + 1;
    let dataItem = props.dataItem;
    let newNot = {
      row:
        'Ward Guardian for ' +
        dataItem['orgUnitName'] +
        ' ' +
        Moment(dataItem['rosterStartDate']).format('YYYY-MM-DD') +
        ' ' +
        Moment(dataItem['rosterEndDate']).format('YYYY-MM-DD') +
        ' requested, please wait for new tab to open',
      show: true,
      id: notificationId,
    };
    setNotifications(notifications.concat(newNot));
    setTimeout(() => {
      hideNotification(notificationId);
    }, 8000);

    generateWG(dataItem['rosterStartDate'], dataItem['rosterEndDate'], dataItem['orgUnitId'], dataItem['orgUnitName']);
  };

  const dataStateChange = (event?: GridDataStateChangeEvent) => {
    const gridSettings = event ? event.dataState : {...gridState, group: undefined};
    setGridState(gridSettings.group && gridSettings.group.length < 1 ? {...gridSettings, group: undefined} : gridSettings);
    onGroupsToggle(gridSettings);
  };

  const filtersChangeCallBack = (searchLocations: string, startDate?: Date, endDate?: Date, isRestoringDefault?: boolean) => {
    loadData(
      startDate === undefined ? defaultStartDate : startDate,
      endDate === undefined ? defaultEndDate : endDate,
      searchLocations,
      false,
      isRestoringDefault || false,
    );
  };

  const redoCallBack = () => {
    loadData(defaultStartDate, defaultEndDate, undefined, true, false, true, true);
    handleActiveLinkedProfile(undefined);
    setKpiStateMode('All');
    setColumnFilters([]);
    setGridState(initialDataState);
    handleActiveColumns(undefined);
    handleActiveUnits(undefined);
    setLocationsString('');
  };

  const excelExport = () => {
    save(_exporter);
  };

  function loadDefaults() {
    let tempDefaultVerbosityLevel = getUserSettings().defaultVerbosityLevel;

    if (tempDefaultVerbosityLevel) {
      selectedVerbosityLevelCallBack(tempDefaultVerbosityLevel);
    } else {
      selectedVerbosityLevelCallBack('availabledata');
    }
  }

  const save = (component: React.RefObject<ExcelExport>) => {
    if (component.current) {
      const options: WorkbookOptions = component.current.workbookOptions();
      if (options.sheets && options.sheets[0].rows) {
        const newRows: any[] = [];
        const groups: any[] = [];
        const data = resultState.data;
        let totalsData = data;
        const totalCells: any[] = [];
        let groupCount = 0;
        let parentColumns: WorkbookSheetRowCell[] = [];
        let columns: WorkbookSheetRowCell[] = [];

        if (Array.isArray(gridState.group) && gridState.group.length > 0) {
          parentColumns.push({background: '#7a7a7a', color: '#fff'});
          columns.push({background: '#7a7a7a', color: '#fff'});
        }
        dataGridColumns.gridParentColumns.forEach((pCol) => {
          const childCols = dataGridColumns.gridColumns.filter((c) => c && pCol && c.group === pCol.group);
          if (childCols && childCols.length > 0) {
            parentColumns.push({
              background: '#7a7a7a',
              color: '#fff',
              value: pCol.title,
              colSpan: childCols.length,
              rowSpan: 1,
            });
          }
        });

        dataGridColumns.gridColumns.forEach((col) => {
          columns.push({
            background: '#7a7a7a',
            color: '#fff',
            value: col && col.title,
            colSpan: 1,
            rowSpan: 1,
          });
        });

        newRows.push({cells: parentColumns, type: 'header'});
        newRows.push({cells: columns, type: 'header'});

        if (Array.isArray(gridState.group) && gridState.group.length > 0) {
          let groupsTotalsData: any[] = [];
          data.forEach((g, index) => {
            groupsTotalsData = groupsTotalsData.concat(g.items);
            groups[index] = [];
            groups[index][0] = {
              value: `${g.value}: ${g.items && g.items.length} Rosters`,
              background: '#dfdfdf',
              color: '#333',
              bold: true,
            };
            dataGridColumns.gridColumns.forEach((col, idx) => {
              let groupValue: any;
              if (col.groupcalc !== undefined) {
                if (col.groupcalc === 'sum' && totalsMode === 'totals') {
                  if (col.sortType && col.sortType === 'hhmm') {
                    groupValue = convertTohhmm(hhmmSum(g.items, col.field));
                  } else if (col.field === 'sameApprover') {
                    groupValue = getCompliantRosters(g.items, col.field);
                  } else {
                    groupValue = Sum(g.items, col.field!);
                  }
                } else if (col.groupcalc === 'average' && totalsMode === 'totals') {
                  let dataArray: any[] = g.items;
                  let divisor = dataArray.filter((item) => item[col.field!] != null).length;
                  groupValue = Sum(g.items, col.field!) / divisor;
                  if (col.exportType && col.exportType === 'money') {
                    groupValue = groupValue
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
                  }
                  if (col.field === 'complianceScore') {
                    groupValue = `${(getCompliancyScoreAverage(dataArray, metrics) / dataArray.length).toFixed(0)} of ${metrics.length}`;
                  }
                }
                if (
                    totalsMode === 'counts' && CheckColumnHasTarget(col.field, col.metriccode)
                ) {
                  groupValue = getCompliantRosters(g.items, col.field, false);
                }
                if (
                    totalsMode === 'percentages' && CheckColumnHasTarget(col.field, col.metriccode)
                ) {
                  groupValue = getCompliantRosters(g.items, col.field, true);
                  if (col.field === 'complianceScore') {
                    groupValue = `${((getCompliancyScoreAverage(g.items, metrics) / g.items.length / metrics.length) * 100).toFixed(1)}%`;
                  }
                }
              }
              const metricConfig = metricMaster.find((i) => col.metriccode && i.metricCode && i.metricCode === col.metriccode);

              groupValue =
                typeof groupValue === 'number' && !isNaN(groupValue) && metricConfig
                  ? groupValue.toFixed(Number(metricConfig.displayRounding))
                  : groupValue === 'NaN'
                  ? '0.00'
                  : typeof groupValue === 'string'
                  ? groupValue
                  : undefined;
              groups[index].push({value: groupValue, background: '#dfdfdf', color: '#333', textAlign: col.textalign, bold: true});
            });
            newRows.push({type: 'group-header', cells: groups[groupCount], level: 0});
            g.items.forEach((row: any) => {
              let cells: WorkbookSheetRowCell[] = [];
              dataGridColumns.gridColumns.forEach((col, idx) => {
                let cell: {[x: string]: any} = {};
                if (idx === 0) {
                  cells.push({value: undefined, background: '#dfdfdf'});
                }
                if (col.field in row && col.filter !== 'date') {
                  cell.value = row[col.field];
                  cell.textAlign = col.textalign;
                }
                if ('rosterViolations' in row) {
                  const hasViolation = row['rosterViolations'].find((violation: {field: string}) => violation.field === col.field);
                  if (hasViolation) cell.color = '#FF0000';
                }
                if (col.filter === 'date' && col.field in row) {
                  cell.value =
                    convertDateToTimestamp(true, row[col.field]) === 0 ? undefined : convertDateToTimestamp(true, row[col.field]);
                  cell.textAlign = col.textalign;
                }
                if (col.field === 'complianceScore' && 'rosterViolations' in row) {
                  let violationsCount = 0;
                  let totalCols = metrics.length;
                  row.rosterViolations &&
                    row.rosterViolations.forEach((rv: any) => {
                      if (metrics.find((col: any) => col.field === rv.field)) {
                        violationsCount++;
                      }
                    });
                  cell.value = `${totalCols - violationsCount} of ${totalCols}`;
                  cell.textAlign = col.textalign;
                }
                if (col.exportType && col.exportType === 'money') {
                  cell.value = row[col.field]
                    ? row[col.field]
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    : '';
                }

                cells.push(cell);
              });
              newRows.push({type: 'data', cells, level: 1});
            });
            groupCount++;
          });
          totalsData = groupsTotalsData;
        } else {
          data.forEach((row) => {
            let cells: WorkbookSheetRowCell[] = [];
            dataGridColumns.gridColumns.forEach((col) => {
              let cell: {[x: string]: any} = {};
              if (col && col.field in row && col.filter !== 'date') {
                cell.value = row[col.field];
                cell.textAlign = col.textalign;
              }
              if ('rosterViolations' in row) {
                const hasViolation = row['rosterViolations'].find((violation: {field: string}) => col && violation.field === col.field);
                if (hasViolation) cell.color = '#FF0000';
              }
              if (col && col.filter === 'date' && col.field in row) {
                cell.value = convertDateToTimestamp(true, row[col.field]) === 0 ? undefined : convertDateToTimestamp(true, row[col.field]);
                cell.textAlign = col.textalign;
              }
              if (col.field === 'complianceScore' && 'rosterViolations' in row) {
                let violationsCount = 0;
                let totalCols = metrics.length;
                row.rosterViolations &&
                  row.rosterViolations.forEach((rv: any) => {
                    if (metrics.find((col: any) => col.field === rv.field)) {
                      violationsCount++;
                    }
                  });
                cell.value = `${totalCols - violationsCount} of ${totalCols}`;
                cell.textAlign = col.textalign;
              }
              if (col.exportType && col.exportType === 'money') {
                cell.value = row[col.field]
                  ? row[col.field]
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '';
              }
              cells.push(cell);
            });
            newRows.push({type: 'data', cells});
          });
        }

        dataGridColumns.gridColumns.forEach((col, idx) => {
          let groupValue: any;
          if (idx === 0 && gridState.group) {
            totalCells.push({value: undefined, background: '#dfdfdf'});
          }
          if (col && col.groupcalc !== undefined) {
            if (col.groupcalc === 'sum' && totalsMode === 'totals') {
              if (col.sortType && col.sortType === 'hhmm') {
                groupValue = convertTohhmm(hhmmSum(totalsData, col.field));
              } else if (col.field === 'sameApprover') {
                groupValue = getCompliantRosters(totalsData, col.field);
              } else {
                groupValue = Sum(totalsData, col.field!);
              }
            } else if (col.groupcalc === 'average' && totalsMode === 'totals') {
              let dataArray: any[] = totalsData;
              let divisor = dataArray.filter((item) => item[col.field!] != null).length;
              groupValue = Sum(totalsData, col.field!) / divisor;
              if (col.exportType && col.exportType === 'money') {
                groupValue =
                  groupValue !== 'NaN'
                    ? groupValue
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                    : '0.00';
              }
            }
            if (
                totalsMode === 'counts' && CheckColumnHasTarget(col.field, col.metriccode)
            ) {
              groupValue = getCompliantRosters(totalsData, col.field, false);
            }
            if (
                totalsMode === 'percentages' && CheckColumnHasTarget(col.field, col.metriccode)
            ) {
              groupValue = getCompliantRosters(totalsData, col.field, true);
            }
          }
          const metricConfig = metricMaster.find((i) => col.metriccode && i.metricCode && i.metricCode === col.metriccode);

          groupValue =
            typeof groupValue === 'number' && !isNaN(groupValue) && metricConfig
              ? groupValue.toFixed(Number(metricConfig.displayRounding))
              : groupValue === 'NaN'
              ? '0.00'
              : typeof groupValue === 'string'
              ? groupValue
              : undefined;
          totalCells.push({
            value: groupValue,
            background: '#dfdfdf',
            color: col && col.groupcalc === 'average' ? '#2e75b7' : '#333',
            textAlign: col && col.textalign,
            bold: true,
          });
        });

        newRows.push({type: 'data', cells: totalCells});
        options.sheets[0].rows = newRows;
      }
      component.current.save(options);
    }
  };

  const advancedCallBack = () => {
    if (groupable) setGridState({...gridState, group: []});
    setGroupable(!groupable);
  };

  const hideNotification = (i: number) => {
    let tot = notifications;
    if (tot !== undefined) {
      const newNots = tot.filter((t) => t.id !== i);
      setNotifications(newNots);
    }
  };

  const closeNotification = (notification: NotificationItem) => {
    let tot = notifications;
    if (tot !== undefined) {
      const newNots = tot.filter((t) => t.id !== notification.id);
      setNotifications(newNots);
    }
  };

  const selectedVerbosityLevelCallBack = (selectedLevel: any) => {
    setSelectedVerbosityLevel(selectedLevel);
  };

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;

      if (item.groupId) {
        const collapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(collapsedIds);
      }
    },
    [collapsedState],
  );

  const handlePageChange = (event: GridPageChangeEvent) => {
    setGridState({...gridState, skip: event.page.skip});
  };

  const onGroupsToggle = (groupField?: any) => {
    const dataStateWithoutPaging = processWithGroups(jsonData, groupField ? groupField : {group: gridState.group});
    setCollapsedState(
      collapsedState.length
        ? []
        : getGroupIds({data: dataStateWithoutPaging && 'data' in dataStateWithoutPaging ? dataStateWithoutPaging.data : []}),
    );
  };

  const onColumnResize = (event: GridColumnResizeEvent) => {
    const {columns, targetColumnId, newWidth, end} = event;
    if (end) {
      const isParentColumn = columns.find((col) => col.id === targetColumnId);
      const gridColumnsCopy = [...dataGridColumns.gridColumns];
      if (isParentColumn) {
        const gridParentColumnsCopy = [...dataGridColumns.gridParentColumns];
        const indexToUpdate = gridParentColumnsCopy.findIndex((col: any) => col.field === isParentColumn.field);
        if (indexToUpdate >= 0) {
          gridParentColumnsCopy[indexToUpdate].width = `${newWidth}px`;
          if (Array.isArray(isParentColumn.children)) {
            const parentColumnWidth = newWidth / isParentColumn.children.length;
            isParentColumn.children.forEach((col: any) => {
              const childIndexToUpdate = dataGridColumns.gridColumns.findIndex((c) => c.field === col.field);
              if (childIndexToUpdate >= 0) {
                gridColumnsCopy[childIndexToUpdate].width = `${parentColumnWidth}px`;
              }
            });
          }
          setUpdatedGridColumns({gridColumns: gridColumnsCopy, gridParentColumns: gridParentColumnsCopy});
        }
      } else {
        let childCol: GridColumnProps | undefined;
        columns.forEach((pcol) => {
          if (Array.isArray(pcol.children)) {
            pcol.children.forEach((col: any) => {
              if (col.id === targetColumnId) childCol = col;
            });
          }
        });
        if (childCol) {
          const indexToUpdate = dataGridColumns.gridColumns.findIndex((col) => childCol && col.field === childCol.field);
          if (indexToUpdate >= 0) {
            gridColumnsCopy[indexToUpdate].width = `${newWidth}px`;
            setUpdatedGridColumns((prevState: any) => ({...prevState, gridColumns: gridColumnsCopy}));
          }
        }
      }
    }
  };

  const onColumnReorder = (event: GridColumnReorderEvent) => {
    const {columns, columnId} = event;
    const isParentColumn: any = columns.find((col) => col.id === columnId);
    let gridColumnsCopy = [...dataGridColumns.gridColumns];
    let gridParentColumnsCopy = [...dataGridColumns.gridParentColumns];
    if (isParentColumn) {
      const sortedColumns = columns.sort((a: any, b: any) => (a.orderIndex > b.orderIndex ? 1 : -1));
      gridParentColumnsCopy = gridParentColumnsCopy.sort((a: any, b: any) => {
        return sortedColumns.findIndex((c) => c.title === a.title) - sortedColumns.findIndex((c) => c.title === b.title);
      });
    } else {
      const flattenedChildColumns: any[] = [];
      columns.forEach((pcol) => {
        if (Array.isArray(pcol.children)) {
          pcol.children.forEach((col: any) => {
            flattenedChildColumns.push(col);
          });
        }
      });
      const sortedFlattenedChildColumns = flattenedChildColumns.sort((a: any, b: any) => (a.orderIndex > b.orderIndex ? 1 : -1));
      gridColumnsCopy = gridColumnsCopy.sort((a: any, b: any) => {
        return (
          sortedFlattenedChildColumns.findIndex((c) => c.field === a.field) -
          sortedFlattenedChildColumns.findIndex((c) => c.field === b.field)
        );
      });
    }
    setUpdatedGridColumns({gridColumns: gridColumnsCopy, gridParentColumns: gridParentColumnsCopy});
  };

  const newData = React.useMemo(() => {
    return setExpandedState({
      data: resultState.data,
      collapsedIds: collapsedState,
    });
  }, [resultState, collapsedState, totalsMode, groupable]);

  const ParentColumnMenuCell = React.useMemo(
    () => (props: any) =>
      <CustomParentColumnMenu {...props} columns={dataGridColumns.gridParentColumns} onColumnsSubmit={onParentColumnsSubmit} />,
    [dataGridColumns],
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleKpiStateModeChange = (kpiState: KPIState) => {
    const colsWithTragets: any[] = [];
    dataGridColumns.gridColumns.forEach((col) => {
      const metric = metricMaster.find((metric) => metric && col && metric.metricCode === col.metriccode);
      const hasBestOutcome = metric && metric.bestOutcome;
      if (hasBestOutcome) colsWithTragets.push(col);
    });
    const newColumnFilters: KPIFilter[] = [];
    colsWithTragets.forEach((col) =>
      newColumnFilters.push({
        field: col.field,
        filterType: kpiState === 'Failed' ? 'Failed' : kpiState === 'Passed' ? 'Passed' : 'All',
      }),
    );
    setColumnFilters(newColumnFilters);
  };

  useEffect(() => {
    handleKpiStateModeChange(kpiStateMode);
  }, [dataGridColumns]);

  useEffect(() => {
    if (!isSidebarOpen) {
      handleIsCreatingNewProfile(false);
      if (
        activeProfile &&
        sidebarActiveProfile &&
        checkProfileTypes(activeProfile, sidebarActiveProfile) &&
        activeProfile.id === sidebarActiveProfile.id &&
        activeProfile.profileType === sidebarActiveProfile.profileType
      ) {
        getColumnProfiles();
      }
      if (
        sidebarActiveUnitProfileCtx &&
        sidebarActiveUnitProfile &&
        checkProfileTypes(sidebarActiveUnitProfileCtx, sidebarActiveUnitProfile) &&
        sidebarActiveUnitProfileCtx.id === sidebarActiveUnitProfile.id &&
        sidebarActiveUnitProfileCtx.profileType === sidebarActiveUnitProfile.profileType
      ) {
        loadData(dates.startDate || defaultStartDate, dates.endDate || defaultEndDate, undefined, true, false, true);
      }
      handleNewUnitProfile({profile: undefined, isStatic: false});
      handleNewProfile({profile: undefined, isStatic: false});
    } else {
      if (!activeProfile) handleActiveProfile(undefined);
      if (!sidebarActiveUnitProfileCtx) handleActiveUnitProfile(undefined);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (activeLinkedProfile) {
      loadData(dates.startDate || defaultStartDate, dates.endDate || defaultEndDate, undefined, false, true);
    }
  }, [activeLinkedProfile]);

  React.useEffect(() => {
    handleWindowResize();
    loadMetricMasters();
    loadDefaults();
    loadData(defaultStartDate, defaultEndDate, undefined, false, true);
  }, []);

  return (
    <>
      <NavMenus {...props} selectedTab={selectedAppBarTab} disabledTabs={[true, false, true]} />
      <WardGuardianFiltersView
        setDataGridColumns={setDataGridColumns}
        filtersChangeCallBack={filtersChangeCallBack}
        redoCallBack={redoCallBack}
        exportExcelCallBack={excelExport}
        advancedCallBack={advancedCallBack}
        expandCollapseCallBack={onGroupsToggle}
        selectedVerbosityLevelCallBack={selectedVerbosityLevelCallBack}
        selectedVerbosityLevel={selectedVerbosityLevel}
        history={props.history}
        loading={false}
        rowCount={rosterCount()}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        maxRowsForAdvanced={rowLimitForNoVirtual}
        setLocationsString={setLocationsString}
        locationsString={locationsString}
        setDates={setDates}
        setTotalsMode={setTotalsMode}
        totalsMode={totalsMode}
        setKpiStateMode={setKpiStateMode}
        kpiStateMode={kpiStateMode}
        dates={dates}
        gridState={gridState}
        handleKpiStateModeChange={handleKpiStateModeChange}
        setErrorNotification={setErrorNotification}
      />

      <div ref={gridContainerRef}>
        <ExcelExport data={resultState.data} ref={_exporter} group={gridState.group} collapsible>
          {(jsonData.length === 0 || loading) && loadingPanel}
          {React.useMemo(() => {
            return (
              <Grid
                style={{
                  height: `calc(100vh - ${gridHeight}px)`,
                  maxHeight: `calc(100vh - ${gridHeight}px)`,
                  fontFamily: 'Roboto",sans-serif',
                  marginRight: '20px',
                }}
                rowHeight={30}
                data={
                  groupable ? newData : newData.slice(gridState.skip, gridState.skip !== undefined ? gridState.skip + pageSize : pageSize)
                }
                pageSize={groupable ? undefined : pageSize}
                total={rosterCount()}
                resizable={true}
                reorderable={true}
                sortable={true}
                {...gridState}
                scrollable={groupable ? 'scrollable' : 'virtual'}
                onDataStateChange={dataStateChange}
                onColumnResize={onColumnResize}
                onColumnReorder={onColumnReorder}
                groupable={{footer: 'none', enabled: groupable}}
                dataItemKey="id"
                onExpandChange={onExpandChange}
                onPageChange={handlePageChange}
                expandField="expanded"
                cellRender={HeaderCellRender}
                ref={MyGrid}>
                {dataGridColumns.gridParentColumns.map((parentItems: {group: string}) =>
                  dataGridColumns.gridParentColumns
                    .filter((items: {group: string}) => items && parentItems && items.group === parentItems.group)
                    .map(
                      (
                        column: {show: any; title?: string | undefined; locked?: boolean | undefined; width?: string},
                        idx: string | number | undefined,
                      ) =>
                        column.show && (
                          <Column
                            title={column.title}
                            key={`${column.title}-${idx}`}
                            id={`${column.title}-${idx}`}
                            locked={column.locked}
                            headerCell={CustomHeaderCell}
                            columnMenu={ParentColumnMenuCell}>
                            {dataGridColumns.gridColumns
                              .filter(
                                (items: {group: string; show: any}) =>
                                  items && parentItems && items.group === parentItems.group && items.show === true,
                              )
                              .map(createCell)}
                          </Column>
                        ),
                    ),
                )}
              </Grid>
            );
          }, [newData, dataGridColumns, gridHeight, groupable, totalsMode, selectedVerbosityLevel])}
        </ExcelExport>
      </div>

      {notifications!.map((notification: {show: any; row: string; id: number}, i) => {
        return (
          notification.show && (
            <Notification
              key={i}
              open={notification.show}
              onClose={() => closeNotification(notification)}
              severity="info"
              text={notification.row}
            />
          )
        );
      })}
      {errorNotification2 && (
        <Notification
          open={errorNotification2}
          onClose={() => setErrorNotification2(false)}
          severity="error"
          text="Oops! Something went wrong ..."
        />
      )}
      <Notification
        open={errorNotification.show}
        onClose={() =>
          setErrorNotification((errorNotification) => {
            return {...errorNotification, show: false};
          })
        }
        severity="error"
        text={errorNotification.text}
      />
    </>
  );
}
