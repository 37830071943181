import * as React from 'react';
import { GetMetricMaster, GetMetricCategories, GetHelp } from '../Utils/Common';
import { MetricMaster, MetricCategories, HelpText } from '../Utils/types';
import * as queryString  from 'query-string';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Loader } from "@progress/kendo-react-indicators";

export default function Help(props: { history: string[]; }) {

    const [metricMasters, setMetricMasters] = React.useState<MetricMaster[]>([])
    const parsed = queryString.parse(window.location.search);
    const [metricCategories, setMetricCategories] = React.useState<MetricCategories[]>([])
    const [helpTexts, setHelpTexts] = React.useState<HelpText[]>([])
    const [loading, setLoading] = React.useState(false)

    const loadHelp = async () => {

        if (parsed !== undefined && parsed !== null) {
            let trust:any = parsed.trust
            let metricMaster: MetricMaster[] | undefined = await GetMetricMaster(trust)!

            if (metricMaster !== undefined) {
                setMetricMasters(metricMaster)
            }
        }

        let metricCategoriesList : MetricCategories[] | undefined = await GetMetricCategories()!

        if (metricCategoriesList !== undefined) {
            setMetricCategories(metricCategoriesList)
        }

        let helpTextList: HelpText[] | undefined = await GetHelp()!

        if (helpTextList !== undefined) {
            setHelpTexts(helpTextList)
        }

        setLoading(false)
    }

    function createMarkup(htmlString: string) { return { __html: htmlString }; };

    React.useEffect(() => {
        setLoading(true)
        async function loadContent() {
            await loadHelp();
        }

        loadContent()
    }, []);



    return (
        <div>
            {!{ loading }.loading ? (
                    <div className="helpviewer">
                    <title>Ward Guardian Help</title>
                    <h1>Help</h1>
                        Welcome to the help section of Ward Guardian. This guide with help you interpret the Ward Guardians that you recieve, and how to understand the advice given.
                        <div>
                            {metricCategories.map(catItem => {
                                return (
                                    <div key={catItem.metricCategoryId.toString()} id={catItem.metricCategoryId.toString()} >
                                        <br />
                                        <br />
                                        <h2>{catItem.longname}</h2>
                                        <div className="panelbar-wrapper">
                                            <PanelBar expandMode={'multiple'}>
                              
                                                {helpTexts.filter(items => items.metricCategoryId === catItem.metricCategoryId).map(helpItem => {
                                                    return (
                                                        <PanelBarItem expanded={true} title={helpItem.helpHeader} id={helpItem.helpCode} key={"PBar" + helpItem.helpId.toString()}>
                                                            <div id={"div" + helpItem.helpId.toString()} className="helpdiv" dangerouslySetInnerHTML={createMarkup(helpItem.helpText)} />
                                                            {metricMasters.filter(metrics => metrics.metricMasterId === helpItem.masterMetricId).length > 0 &&
                                                                <div className="panelbar-wrapper" key={helpItem.helpCode.toString() + "sub2"}>
                                                                    <PanelBar>
                                                                    <PanelBarItem title={'Metric'} expanded={false}>
                                                                        <table className="metricstable">
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Description</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                            {metricMasters.filter(metrics => metrics.metricMasterId === helpItem.masterMetricId).map(metric => {
                                                                                return (
                                                                                    <tr key={helpItem.masterMetricId}>
                                                                                        <td>{metric.shortName}</td>
                                                                                        <td>{metric.description}</td>
                                                                                        <td>{metric.policyAction}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                            </table>
                                                                    </PanelBarItem>
                                                                    </PanelBar>
                                                                </div>

                                                            }
                                                        </PanelBarItem>
                                                    )
                                                })
                                                }
                                            </PanelBar>
                                        </div>
                                    </div>)
                            })}
                        </div>
                    </div>
                ) : (
                <Loader
                    size="medium"
                    type='infinite-spinner'
                    style={{
                        position: "relative",
                        width: "200px",
                        top: "30%",
                        left: "50%"
                    }}
                />
            )
            }
            </div>
    );
};


