import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';
import * as queryString from 'query-string';

import '../login.css';

function ResetPassword(props: {history: string[]}) {
  const [loading, setLoading] = useState(false);
  const password = useFormInput('');
  const confirmPassword = useFormInput('');
  const [error, setError] = useState('');
  const parsed = queryString.parse(window.location.search);
  const email = useFormInput(parsed.email as string);
  const token = useFormInput(parsed.token as string);

  // handle button click of login form
  const handleRegister = () => {
    setError('');
    setLoading(true);

    if (password.value !== confirmPassword.value) {
      setError('Your password do not match');
      setLoading(false);
      return;
    }

    axios
      .post(process.env.REACT_APP_APIURL + 'auth/user/resetpassword', {
        email: email.value,
        password: password.value,
        PasswordConfirm: confirmPassword.value,
        Token: token.value,
      })
      .then((response) => {
        setLoading(false);
        props.history.push('/login');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response == null) setError('Something went wrong. Please try again later.');
        else if (error.response.status === 500) setError(error.response.data.detail);
        else setError('Something went wrong. Please try again later.');
      });
  };

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Reset Password</h1>
              <p>Please enter your new password below to reset it</p>
              <input type="hidden" {...email} />
              <input type="hidden" {...token} />
            </div>
            <div className="login-form">
              <div className="control-group">
                <input type="password" {...password} autoComplete="new-password" id="resetpassword-password" placeholder="Password" />
                <label className="login-field-icon fui-user" htmlFor="resetpassword-password"></label>
              </div>
              <div className="control-group">
                <input
                  type="password"
                  {...confirmPassword}
                  autoComplete="new-password"
                  id="resetpassword-confirmpassword"
                  placeholder="Confirm Password"
                />
                <label className="login-field-icon fui-user" htmlFor="resetpassword-confirmpassword"></label>
              </div>
              {error && (
                <>
                  <span style={{color: 'red'}}>{error}</span>
                  <br />
                </>
              )}
              <br />

              <input className="btn" type="button" value={loading ? 'Loading...' : 'Reset'} onClick={handleRegister} disabled={loading} />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useFormInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: {target: {value: React.SetStateAction<string>}}) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default ResetPassword;
