import * as React from 'react';
import {useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import { getToken, GetTrustSettings, setTrust, getTrust, setUserSettings } from '../Utils/Common';
import jwt_decode from 'jwt-decode';
import {Trust, TrustSettings} from '../Utils/types';

import '../login.css';

interface JwtPayload {
  TrustAccess: string;
}

export default function TrustPicker(props: {history: any[]}) {
  const [dropdownSelectedValue, setDropdownSelectedValue] = useState();
  const [dropdownValues, setDropdownValues] = useState();

  const handleGo = async () => {
    let trustSettings: TrustSettings | undefined = await GetTrustSettings(getTrust().trustId);
    if (trustSettings === undefined || trustSettings === null) {
      props.history.push('/DataQuality');
    } else {
      if (trustSettings.softwareProvider === 'Allocate') {
        props.history.push('/wardguardian');
      } else {
        props.history.push('/DataQuality');
      }
    }
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    let trust: Trust = {
      trustId: event.target.value.TrustId,
      trustName: event.target.value.TrustName,
    };
      setTrust(trust);
      let UserSettings = {
          defaultVerbosityLevel: event.target.value.VerbosityLevel,
          canChooseAllLocation: event.target.value.CanChooseAllLocation,
          canCreateTrustProfiles: event.target.value.CanCreateTrustProfiles
      }
      setUserSettings(UserSettings)

    setDropdownSelectedValue(event.target.value);
  };

  React.useEffect(() => {
    const token = getToken();
    var decoded = jwt_decode<JwtPayload>(token!);
    const valuesArray = JSON.parse(decoded.TrustAccess);
    setDropdownValues(valuesArray);
  }, []);

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="login">
                  <div className="login-screen" style={{ width: '290px' }}>
            <div className="app-title">
              <h1 className="h1">Please pick a Trust</h1>
              <br />
            </div>

            <div className="login-form">
              <div className="control-group" >
                <DropDownList
                  data={dropdownValues}
                  textField="TrustName"
                  dataItemKey="TrustId"
                  value={dropdownSelectedValue}
                                  onChange={handleChange}
                                  style={{ width: '140px' }}
                />
                              <input className="btnright" id="submit" type="button" value="Go" onClick={handleGo} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
