import React from 'react';
import {Snackbar, makeStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface INotification {
  open: boolean;
  onClose: () => void;
  text: string;
  severity: 'error' | 'success' | 'info' | 'warning';
}

const useStyles = makeStyles({
  root: {
    fontSize: '15px',
    marginBottom: '20px',
  },
});

const Notification = ({open, onClose, text, severity}: INotification) => {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={7000}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      onClose={() => onClose()}
      onBlur={() => onClose()}>
      <Alert
        severity={severity}
        variant="filled"
        classes={{
          root: classes.root,
        }}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
