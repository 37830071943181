import React from 'react';
import {Box} from '@material-ui/core';
import {Button} from '@progress/kendo-react-buttons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faPencil} from '@fortawesome/free-solid-svg-icons';

interface ActionsProps {
  onEdit?: () => void;
  onDelete?: () => void;
  isDisbaled?: boolean;
}

const Actions = ({onEdit, onDelete, isDisbaled}: ActionsProps) => {
  return (
    <Box display="flex" style={{paddingLeft: '5px'}}>
      <Button className="redoButton2" onMouseDown={() => onEdit && onEdit()} title="Edit profile">
        <FontAwesomeIcon icon={faPencil} style={{fontSize: '15px'}} />
      </Button>
      {!isDisbaled ? (
        <Button className="redoButton2" onMouseDown={() => onDelete && onDelete()} title="Delete profile">
          <FontAwesomeIcon icon={faTrash} style={{fontSize: '15px'}} />
        </Button>
      ) : (
        <Box style={{minWidth: '46px', width: '46px'}}></Box>
      )}
    </Box>
  );
};

export default Actions;
