import * as React from 'react';
import { Grid, GridColumn as Column, GridDataStateChangeEvent} from '@progress/kendo-react-grid';
import axios from 'axios';
import { removeUserSession, getToken, getTrust } from '../Utils/Common';
import { Loader } from "@progress/kendo-react-indicators";
import StaffAuditFiltersView from './StaffAuditFiltersView';
import Moment from 'moment';
import { SearchLocation, TrustSettings } from '../Utils/types'
import gridColumnsDef from './StaffAuditGridColumns.json'; //defined the columns that are included and their default visability
import { CustomColumnMenu } from './ColumnMenu.jsx';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { SortDescriptor, process as gridprocess, State} from '@progress/kendo-data-query';
import StaffAuditDataStructure from "./StaffAuditDataStructure.json";
import { Popup } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuSelectEvent, TabStrip, TabStripSelectEventArguments, TabStripTab} from '@progress/kendo-react-layout';
import '../custom.css';
import { TickCell, ToolTipCell, RagCell, StaffDetailsLinkCell, SumFooterCell, HeaderCell, TodayTomorrowCell, RightAlignCell } from './GridCellTypes'
import { filterBy } from '@progress/kendo-data-query';

export default function StaffAuditView(props: { history: any[]; }) {

    const fakeTabStripItems = [{
        disabled: false,
        id: "ContractHours",
        text: "Contract Hours",
    },
    {
        disabled: false,
        id: "AnnualLeave",
        text: "Annual Leave",
    },
    {
        disabled: false,
        id: "Sickness",
        text: "Sickness",
    }]

    let contractHoursSort: SortDescriptor[] = [{ field: "sD_ContractHours", dir: "desc" }]
    let annualLeaveSort: SortDescriptor[] = [{ field: "allHours", dir: "desc" }]
    let sicknessSort: SortDescriptor[] = [{ field: "sickHours", dir: "desc" }]
    let dt = new Date()
    let defaultEndDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0)

    const dataState: State = {
        sort: contractHoursSort,
        skip: 0,
        take: 60
    };

    const [jsonData, setjsonData] = React.useState(StaffAuditDataStructure)
    const [trustSettingsData, setTrustSettingsData] = React.useState <TrustSettings>()
    const [gridState, setGridState] = React.useState(dataState)
    const [loading, setLoading] = React.useState(false)
    const [gridColumns, setGridColumns] = React.useState(gridColumnsDef.Columns)
    const [gridParentColumns, setGridParentColumns] = React.useState(gridColumnsDef.ParentColumns)
    const [gridHeight, setGridHeight] = React.useState('600px')
    const [gridWidth, setGridWidth] = React.useState('1000px')
    const [loaderWidth, setLoaderWidth] = React.useState('1000px')
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date())

    const [rowCount, setRowCount] = React.useState(0)
    const [selectedFakeTab, setSelectedFakeTab] = React.useState(0)
    const [groupable, setGroupable] = React.useState(false)

    let processedData = gridprocess(jsonData, gridState)


    const filtersChangeCallBack = (startDate: Date, endDate: Date, searchLocations: SearchLocation) => {
        setSelectedEndDate(endDate)
        loadGrid(startDate, endDate, searchLocations);
    }

    const redoCallBack = () =>
    {
        getTrustSettings(getTrust()!.trustId)
    }

    //this is the main method for getting the data for the grid
    const loadGrid = (startDate: Date, endDate: Date, searchLocations: SearchLocation | undefined) => {
        setSelectedEndDate(endDate)
        setLoading(true)
        const token = getToken();
        if (!token) {
            return;
        }

        axios.post(process.env.REACT_APP_APIURL + 'AuditStaffs/GetAuditStaff', {
            startDate: Moment(startDate).format('yyyy-MM-DD'),
            enddate: Moment(endDate).format('yyyy-MM-DD'),
            locations: searchLocations === undefined ? null : searchLocations.locations,
            filterlevel: searchLocations === undefined ?null: searchLocations.locations === '' ? null : searchLocations.isDirectorate ===true?0:1,
            trust: getTrust().trustName 
        },
            {
                headers:
                {
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                setLoading(false)
                setjsonData(response.data)
                console.log(response.data)
                setRowCount(response.data.length)
            }).catch((error: any) => {
                if (error.response.status === 401) {
                    removeUserSession();
                    props.history.push('/login');
                }
                console.log(error)
                setLoading(false)
            });
    }

    //this gets the trust setting and is used for setting the default values
    const getTrustSettings = (trustId: number) => {
        const token = getToken();
        if (!token) {
            return;
        }

        axios.get(process.env.REACT_APP_APIURL + 'trustsettings/' + trustId,
            {
                headers:
                {
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                setTrustSettingsData(response.data)
                let dt = new Date()
                let defaultEndDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
                setSelectedEndDate(defaultEndDate)
                loadGrid(response.data.auditStartDate, defaultEndDate, undefined) //load defulat date range and locations
            }).catch((error: any) => {
                console.log(error)
                if (error.response.status === 401) {
                    removeUserSession();
                    props.history.push('/login');
                }
                setLoading(false)
            });
    }

    React.useEffect(() => {
        setLoading(false)

        getTrustSettings(getTrust()!.trustId)
        handleResize()
    }, []);

    const onColumnsSubmit = (columnsState:any) => {
        setGridColumns(columnsState)
    }

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setGridState(event.dataState);
    }

    let excelExport: ExcelExport | null
    const exportExcelCallBack = () => {
        excelExport!.save(jsonData)
    }

    const advancedCallBack = () => {
        setGroupable(!groupable)
    }

    const handleStaffDetailsButtonClick = (props: any) => {

        let dataItem = props.dataItem
        //dataItem["rosterEnds"]  
        window.open('staffdetailsviewer?staffId=' + dataItem["staffId"] + '&scanWeeks=' + dataItem["contractWeeks"].toFixed(0) + '&endDate=' + Moment(selectedEndDate).format('yyyy-MM-DD') + '&staffname=' + dataItem["allName"], "_blank")
    }

    //#region Context Menu
    let blurTimeoutRef: number | undefined;
    let menuWrapperRef: HTMLDivElement | null;
    const [contextMenuState, setContextMenuState] = React.useState(false)
    const [contextMenuOffset, setContextMenuOffset] = React.useState({left:0, top:0})
    
    const handleContextMenuOpen = (e: { preventDefault?: () => void; clientX?: any; clientY?: any; }, dataItem: any) => {
        setContextMenuOffset({ left: e.clientX, top: e.clientY })
        setContextMenuState(true)
    }

    const rowRender = (trElement: any, dataItem: any) => {
        //if (dataItem.dataItem[dataItem.field] === undefined) {
        //    // shows loading cell if no data
        //    return <td> <span className="k-placeholder-line"></span></td>;
        //}

        const trProps = {
            ...trElement.props,
            onContextMenu: (e: { preventDefault: () => void; }) => {
                e.preventDefault()
                handleContextMenuOpen(e, dataItem.dataItem)
            }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const onFocusHandler = () => {
        clearTimeout(blurTimeoutRef);
        blurTimeoutRef = undefined;
    };

    const onBlurTimeout = () => {
        setContextMenuState(false)

        blurTimeoutRef = undefined;
    };

   const onBlurHandler = (event: any) => {
        clearTimeout(blurTimeoutRef);
        blurTimeoutRef = setTimeout(onBlurTimeout);
    };


    const handleOnSelect = (e: MenuSelectEvent) => {
        switch (e.item.text) {
            case "Open Sentinel":
                props.history.push('/wardguardian');
                break;
             
            default:
        }
        setContextMenuState(false);
    }

    //#endregion

    //#region create grid columns


    const GetCellHandler = (customcelltype: string) => {

        if (customcelltype === 'rag') {
            return RagCellRender;
        }
        else if (customcelltype === 'tick') {
            return TickCell;
        }
        else if (customcelltype === 'tooltip') {
            return ToolTipCellRender;
        }
        else if (customcelltype === 'staffdetailslink') {
            return StaffDetailsLinkCellRender;
        }
        else if (customcelltype === 'todaytomorrow') {
            return TodayTomorrowCellRender;
        }
        else if (customcelltype === 'rightaligncell') {
            return RightAlignCellRender;
        }
        return undefined;
    }

    const TodayTomorrowCellRender = (props: any) => {
        let cellConfig = gridColumnsDef.Columns.filter(item => item.field === props.field)[0]
        return (<TodayTomorrowCell {...props} cellConfig={cellConfig} />)
    }

    const RagCellRender = (props: any) => {
        let cellConfig = gridColumnsDef.Columns.filter(item => item.field === props.field)[0]
        return (<RagCell {...props} cellConfig={cellConfig} />)
    }

    const ToolTipCellRender = (props: any) => {
        return (<ToolTipCell {...props} cellConfig={gridColumnsDef.Columns.filter(item => item.field === props.field)[0]} />)
    }

    const RightAlignCellRender = (props: any) => {
        return (<RightAlignCell {...props} cellConfig={gridColumnsDef.Columns.filter(item => item.field === props.field)[0]} />)
    }

    const StaffDetailsLinkCellRender = (props: any) => {
        return (<StaffDetailsLinkCell {...props} ClickCallBack={handleStaffDetailsButtonClick} />)
    }

    const GetFooterCellHandler = (customcelltype: string) => {

        if (customcelltype === 'sum') {
            return SumFooterCellRender;
        }
        return undefined;
    }

    const SumFooterCellRender = (props: any) => {
        return (<SumFooterCell data={filterBy(jsonData, dataState.filter!)} {...props} />)
    } 

    const CustomHeaderCell = (props: any) => {
        return (<HeaderCell filterApplied={gridState.filter!} {...props} cellConfig={gridColumnsDef.Columns} />)
    }

    const createCell = (column: {
        show: any;
        title: string | undefined;
        field: string | undefined;
        filter: any | undefined;
        width?: string | undefined;
        customcell?: boolean | undefined;
        customcelltype?: string| undefined;
        format?: string | undefined;
        footercelltype?: string | undefined

    }, idx: string | number | undefined): any =>
    {
        if (column.show) {
                return < Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    width={column.width}
                    format={column.format}
                    columnMenu={
                        props =>
                            <CustomColumnMenu
                                {...props}
                                columns={gridColumns}
                                onColumnsSubmit={onColumnsSubmit}
                            />
                    }
                    cell={GetCellHandler(column.customcelltype!)!}
                    footerCell={GetFooterCellHandler(column.footercelltype!)}
                    headerCell={CustomHeaderCell}
                />
        }
    }
    //#endregion

    //#region resize the grid to fill the area
    function handleResize() {
        let offset: number = 236
        let gridHeight = window.innerHeight - offset
        setGridHeight(gridHeight + "px")
        let gridWidth = window.innerWidth - 3
        setGridWidth(gridWidth + "px")
        setLoaderWidth(gridWidth -800+ "px")
    }


    window.addEventListener('resize', handleResize)
    //#endregion

    const handleFakeTabSelect = (event: TabStripSelectEventArguments) =>
    {
        setSelectedFakeTab(event.selected)

        if (fakeTabStripItems[event.selected].id === "ContractHours") {
            gridState.sort = contractHoursSort
        }
        else if (fakeTabStripItems[event.selected].id === "AnnualLeave") {
            gridState.sort = annualLeaveSort
        }
        else if (fakeTabStripItems[event.selected].id === "Sickness") {
            gridState.sort = sicknessSort
        }

        let tempGridColumns: any = [...gridColumns]
        //first hide everything
        tempGridColumns.forEach(function (item: { show: boolean; }) {
            item.show = false;
        })
        //we always want staff details to be shown
        tempGridColumns.filter((items: { group: string; }) => items.group === 'StaffDetails').forEach(function (item: { show: boolean; }) {
            item.show = true;
        })
        //now show for selection
        tempGridColumns.filter((items: { group: any; }) => items.group === fakeTabStripItems[event.selected].id).forEach(function (item: { show: boolean; }) {
            item.show = true;
        })

        let tempParentGridColumns: any = [...gridParentColumns]

        //first hide everything
        tempParentGridColumns.forEach(function (item: { show: boolean; }) {
            item.show = false;
        })
        //we always want staff details to be shown
        tempParentGridColumns.filter((items: { group: string; }) => items.group === 'StaffDetails').forEach(function (item: { show: boolean; }) {
            item.show = true;
        })
        //now show for selection
        tempParentGridColumns.filter((items: { group: any; }) => items.group === fakeTabStripItems[event.selected].id).forEach(function (item: { show: boolean; }) {
            item.show = true;
        })

        setGridParentColumns(tempParentGridColumns)
        setGridColumns(tempGridColumns)
    }

    return (
        <div>
            <div>
                <TabStrip selected={selectedFakeTab} onSelect={handleFakeTabSelect} className="hiddentabcontentarea filterstab">
                    {fakeTabStripItems.map(item => {
                        return (
                            <TabStripTab key={item.id} disabled={item.disabled} title={item.text}></TabStripTab>
                        );
                    })}
                </TabStrip>
            </div>
            <div>
                {!{ loading }.loading ? (
                    <div>
                        <StaffAuditFiltersView
                    filtersChangeCallBack={filtersChangeCallBack}
                    redoCallBack={redoCallBack}
                    exportExcelCallBack={exportExcelCallBack}
                    advancedCallBack={advancedCallBack}
                    history={props.history}
                    loading={loading}
                    rowCount={rowCount} 
                    defaultStartDate={trustSettingsData === undefined ? new Date(2015, 1, 1) : trustSettingsData.auditStartDate!}
                    defaultEndDate={defaultEndDate}/>
                        
                    <Popup
                        offset={contextMenuOffset}
                        show={contextMenuState}
                        popupClass={'popup-content'}
                    >
                        <div
                            onFocus={onFocusHandler}
                            onBlur={onBlurHandler}
                            tabIndex={-1}
                            ref={el => (menuWrapperRef = el)}
                            >
                                <Menu vertical={true} style={{ display: 'inline-block' }} onSelect={handleOnSelect}>
                                    <MenuItem text="Open Sentinel" cssClass="contextmenu" cssStyle={{ color: "white", background: "#444444" }} />
                                    <MenuItem text="Open Shifts" cssStyle={{ color: "white", background: "#444444" }} />
                                    <MenuItem text="Open Staff Details" cssStyle={{ color: "white", background: "#444444" }} />
                                    <MenuItem text="Set dates to Whole Rosters" cssStyle={{ color: "white", background: "#444444" }} />
                            </Menu>
                        </div>
                        </Popup>
                        <ExcelExport
                            data={jsonData}
                            ref={exporter => excelExport = exporter}
                        >
                            <Grid
                                style={{ height: gridHeight, fontFamily: 'Roboto",sans-serif', width: gridWidth }}
                                rowHeight={30}
                                data={processedData}
                                total={processedData.total}
                                resizable={true}
                                reorderable={true}
                                sortable={true}
                                {...gridState}
                                scrollable={'virtual'}
                                onDataStateChange={dataStateChange}
                                rowRender={rowRender}
                                groupable={{ footer: 'visible', enabled: groupable }}
                                dataItemKey="staffId"
                            >
                                {gridParentColumns.map((parentItems: { group: string }) =>

                                    gridParentColumns.filter((items: { group: string; }) => items.group === parentItems.group).map((column: { show: any; title: string | undefined; }, idx: string | number | undefined) =>
                                        column.show && (<Column title={column.title} key={idx}
                                        >
                                            { gridColumns.filter((items: { group: string; show: any }) => items.group === parentItems.group && items.show === true).map(createCell)}

                                        </Column>)

                                    ))
                                }
                            </Grid></ExcelExport>
                    </div>
                ) : (
                        <Loader
                            size="medium"
                            type='infinite-spinner'
                            style={{
                                position: "relative",
                                width: "200px",
                                top: "30%",
                                left: "50%"
                            }}
                        />
                    )}
            </div>
        </div>
    );
}
