import {getUser, getTrust, getCurrentContextUser} from '../../../Utils/Common';
import {StaticOrUserProfile} from '../collapsable-list/ColumnsCollapsableList';
import {SetStateAction, useEffect, useRef} from 'react';
import {getProfiles, updateItems, getLinkedProfiles} from './util-requests';
import {updateTreeViewColumns} from '../collapsable-list/util';
import {TreeNode} from '../Treeview';
import {LinkedProfile} from './pages/LinkedProfiles';
import {SentinelTypes, Subscription, SubscriptionOrgUnit, SubscriptionSentinelType, VerbosityLevel} from './pages/Subscriptions';
import moment from 'moment';

export interface GenericObject {
  isNew?: boolean;
  inEdit?: boolean;
  id?: number;
  positionIndex?: number;
}

export interface Positions {
  firstValue: number;
  secondValue: number;
}

export type ContextProps<T> = {
  reorder: (dataItem: T) => void;
  dragStart: (dataItem: T) => void;
  dragEnd: () => void;
};

export type HandleNotification = (show: boolean, text?: string | undefined) => void;

export type CommonProps = {
  initialProfileState: StaticOrUserProfile | undefined;
  setInitialProfileState: React.Dispatch<React.SetStateAction<StaticOrUserProfile | undefined>> | undefined;
  handleErrorNotification: HandleNotification;
  setGridData: React.Dispatch<React.SetStateAction<StaticOrUserProfile[]>>;
  setProfilesLength?: React.Dispatch<React.SetStateAction<number>>;
  email?: string;
};

export type LinkedProfileProps = {
  initialProfileState: LinkedProfile | undefined;
  setInitialProfileState: React.Dispatch<React.SetStateAction<LinkedProfile | undefined>> | undefined;
  handleErrorNotification: HandleNotification;
  setGridData: React.Dispatch<React.SetStateAction<LinkedProfile[]>>;
};

export type SubscriptionProps = {
  handleErrorNotification: HandleNotification;
  setGridData: React.Dispatch<React.SetStateAction<Subscription[]>>;
  orgUnits?: SubscriptionOrgUnit[];
};

export const checkProfileTypes = (dataItem: StaticOrUserProfile, item: StaticOrUserProfile) => {
  if ((!item.hasOwnProperty('email') && !dataItem.hasOwnProperty('email')) || ('email' in item && 'email' in dataItem)) {
    return true;
  }
  return false;
};

export function useDidMount() {
  const mountRef = useRef(false);

  useEffect(() => {
    mountRef.current = true;
  }, []);

  return () => mountRef.current;
}

export const isDraggable = (dataItem: StaticOrUserProfile, isOceansblueUser: boolean): boolean => {
  if (isOceansblueUser && dataItem && !dataItem.hasOwnProperty('email')) return true;
  if (!isOceansblueUser && dataItem && 'email' in dataItem) return true;
  return false;
};

export const emailRegex = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const createUserProfile = (
  gridData: StaticOrUserProfile[],
  profilesLength: number,
  profileType: number,
  metricCodes?: string,
): StaticOrUserProfile => {
  return {
    id: Math.round(Math.random() * 100000000),
    longName: '',
    metricCodes: metricCodes || '',
    onLoadFlag: false,
    positionIndex:
      gridData[profilesLength - 1] && 'positionIndex' in gridData[profilesLength - 1]
        ? gridData[profilesLength - 1]['positionIndex'] + 1
        : 1,
    email: getUser(),
    inEdit: true,
    isNew: true,
    profileType,
  };
};

export const createSubscription = (verbosityLevelId: number): Subscription => {
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);

  return {
    emails: [{email: getUser()}],
    orgUnits: [],
    startDate: moment().toDate(),
    endDate: moment().add(90, 'days').toDate(),
    verbosityLevelId,
    trustId: getTrust().trustId,
    setupUserEmail: getUser(),
    sentinelTypes: [{sentinelType: {sentinelId: 1}}, {sentinelType: {sentinelId: 2}}, {sentinelType: {sentinelId: 4}}],
    active: true,
    inEdit: true,
    isNew: true,
  };
};

export const orgUnitsStrToNumArr = (str: string): SubscriptionOrgUnit[] => {
  const childUnits = str.split(';')[1];
  if (childUnits) {
    const orgUnitsStr = childUnits.split(',');
    if (orgUnitsStr) {
      const orgUnits: SubscriptionOrgUnit[] = orgUnitsStr.map((unit) => {
        return {orgUnitId: Number(unit)};
      });
      return orgUnits;
    }
  }
  return [];
};

export const createLinkedProfile = (gridData: LinkedProfile[]): LinkedProfile => {
  return {
    id: Math.round(Math.random() * 100000000),
    longName: '',
    positionIndex: gridData.length,
    email: getUser(),
    inEdit: true,
    isNew: true,
    trustId: getTrust().trustId,
    unitProfileId: 0,
    unitProfileIsStatic: false,
    columnProfileId: 0,
    columnProfileIsStatic: false,
    isActive: true,
  };
};

export const createTrustProfile = (gridData: StaticOrUserProfile[], profileType: number, metricCodes?: string): StaticOrUserProfile => {
  return {
    id: Math.round(Math.random() * 100000000),
    longName: '',
    metricCodes: metricCodes || '',
    onLoadFlag: false,
    positionIndex:
      gridData[gridData.length - 1] && 'positionIndex' in gridData[gridData.length - 1]
        ? gridData[gridData.length - 1]['positionIndex'] + 1
        : 1,
    inEdit: true,
    isNew: true,
    trustId: getTrust().trustId,
    profileType,
  };
};

export const isDisabled = (dataItem: any, isOceansblueUser: boolean): boolean => {
  if (dataItem) {
    if (!isOceansblueUser && dataItem['trustId']) {
      return true;
    }
  }
  return false;
};

export const edit = (
  dataItem: StaticOrUserProfile,
  gridData: StaticOrUserProfile[],
  setGridData: React.Dispatch<SetStateAction<StaticOrUserProfile[]>>,
) => {
  const gridDataCopy = [...gridData];
  gridDataCopy.map((item) => {
    if (item['id'] === dataItem['id'] && checkProfileTypes(dataItem, item)) {
      item.inEdit = true;
      return item;
    } else {
      return item;
    }
  });
  setGridData(gridDataCopy);
};

export const genericEdit = <T extends GenericObject, K extends keyof T>(
  dataItem: T,
  gridData: T[],
  setGridData: React.Dispatch<SetStateAction<T[]>>,
  field: K,
) => {
  const gridDataCopy = [...gridData];
  gridDataCopy.map((item) => {
    if (item[field] === dataItem[field]) {
      item.inEdit = true;
      return item;
    } else {
      return item;
    }
  });
  setGridData(gridDataCopy);
};

export const reorderData = (
  item: StaticOrUserProfile,
  activeItem: StaticOrUserProfile | null,
  gridData: StaticOrUserProfile[],
  setDroppedItemIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
) => {
  if (activeItem && item.id === activeItem.id) return;
  if (activeItem && !checkProfileTypes(activeItem, item)) return;

  let reorderedData = gridData.slice();
  let prevIndex = reorderedData.findIndex((p) => activeItem && p.id === activeItem.id);
  let nextIndex = reorderedData.findIndex((p) => item && p.id === item.id);

  if (prevIndex >= 0 && nextIndex >= 0 && reorderedData[nextIndex]) {
    setDroppedItemIndex(reorderedData[nextIndex]['positionIndex']);
  }
};

export const reorderDataLinkedProfiles = (
  item: LinkedProfile,
  activeItem: LinkedProfile | null,
  gridData: LinkedProfile[],
  setDroppedItemIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
) => {
  if (activeItem && item.id === activeItem.id) return;

  let reorderedData = gridData.slice();
  let prevIndex = reorderedData.findIndex((p) => activeItem && p.id === activeItem.id);
  let nextIndex = reorderedData.findIndex((p) => item && p.id === item.id);

  if (prevIndex >= 0 && nextIndex >= 0 && reorderedData[nextIndex]) {
    setDroppedItemIndex(reorderedData[nextIndex]['positionIndex']);
  }
};

export const addUserProfile = (
  gridData: StaticOrUserProfile[],
  setGridData: React.Dispatch<React.SetStateAction<StaticOrUserProfile[]>>,
  profilesLength: number,
  handleErrorNotification: HandleNotification,
  profileType: number,
  metricCodes?: string,
) => {
  const existingNewProfile = gridData.find((item) => item.isNew === true);
  const existingProfileInEdit = gridData.find((item) => item.inEdit === true);
  if (existingProfileInEdit || existingNewProfile) {
    handleErrorNotification(true, 'You are already editing a profile');
  } else {
    setGridData([
      ...gridData.slice(0, profilesLength),
      createUserProfile(gridData, profilesLength, profileType, metricCodes),
      ...gridData.slice(profilesLength),
    ]);
  }
};

export const addSubscription = (
  gridData: Subscription[],
  setGridData: React.Dispatch<React.SetStateAction<Subscription[]>>,
  verbosityLevelId: number,
  handleErrorNotification: HandleNotification,
) => {
  const existingNewProfile = gridData.find((item) => item.isNew === true);
  const existingProfileInEdit = gridData.find((item) => item.inEdit === true);
  if (existingProfileInEdit || existingNewProfile) {
    handleErrorNotification(true, 'You are already editing a subscription');
  } else {
    setGridData([...gridData, createSubscription(verbosityLevelId)]);
  }
};

export const addLinkedProfile = (
  gridData: LinkedProfile[],
  setGridData: React.Dispatch<React.SetStateAction<LinkedProfile[]>>,
  handleErrorNotification: HandleNotification,
) => {
  const existingNewProfile = gridData.find((item) => item.isNew === true);
  const existingProfileInEdit = gridData.find((item) => item.inEdit === true);
  if (existingProfileInEdit || existingNewProfile) {
    handleErrorNotification(true, 'You are already editing a profile');
  } else {
    setGridData([...gridData, createLinkedProfile(gridData)]);
  }
};

export const addTrustProfile = (
  gridData: StaticOrUserProfile[],
  setGridData: React.Dispatch<React.SetStateAction<StaticOrUserProfile[]>>,
  handleErrorNotification: HandleNotification,
  profileType: number,
  metricCodes?: string,
) => {
  const existingNewProfile = gridData.find((item) => item.isNew === true);
  const existingProfileInEdit = gridData.find((item) => item.inEdit === true);
  if (existingProfileInEdit || existingNewProfile) {
    handleErrorNotification(true, 'You are already editing a profile');
  } else {
    setGridData([...gridData, createTrustProfile(gridData, profileType, metricCodes)]);
  }
};

export const handleEdit = (
  dataItem: StaticOrUserProfile,
  gridData: StaticOrUserProfile[],
  setGridData: React.Dispatch<React.SetStateAction<StaticOrUserProfile[]>>,
  handleErrorNotification: HandleNotification,
) => {
  const existingProfileInEdit = gridData.find(
    (item) => item.inEdit === true && (item.id !== dataItem.id || (item.id === dataItem.id && !checkProfileTypes(item, dataItem))),
  );
  if (existingProfileInEdit) {
    handleErrorNotification(true, 'You are already editing a profile');
  } else {
    edit(dataItem, gridData, setGridData);
  }
};

export const genericHandleEdit = <T extends GenericObject, K extends keyof T>(
  dataItem: T,
  gridData: T[],
  setGridData: React.Dispatch<React.SetStateAction<T[]>>,
  handleErrorNotification: HandleNotification,
  errorMessage: string,
  field: K,
) => {
  const existingProfileInEdit = gridData.find((item) => item.inEdit === true && (item.id !== dataItem.id || item.id === dataItem.id));
  if (existingProfileInEdit) {
    handleErrorNotification(true, errorMessage);
  } else {
    genericEdit(dataItem, gridData, setGridData, field);
  }
};

export const checkForNewProfile = (gridData: StaticOrUserProfile[], props: CommonProps, profileType: number, id?: number) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength} = props;
  const newProfile = gridData.find((item) => item.isNew === true);

  if (newProfile && newProfile.id !== id) {
    getProfiles(
      {
        initialProfileState,
        setInitialProfileState,
        handleErrorNotification,
        setGridData,
        setProfilesLength,
        email: getCurrentContextUser(),
      },
      profileType,
      newProfile,
    );
  } else {
    getProfiles(
      {
        initialProfileState,
        setInitialProfileState,
        handleErrorNotification,
        setGridData,
        setProfilesLength,
        email: getCurrentContextUser(),
      },
      profileType,
    );
  }
};

export const checkForNewLinkedProfile = (gridData: LinkedProfile[], props: LinkedProfileProps, id?: number) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData} = props;
  const newProfile = gridData.find((item) => item.isNew === true);

  if (newProfile && newProfile.id !== id) {
    getLinkedProfiles({initialProfileState, setInitialProfileState, handleErrorNotification, setGridData}, newProfile);
  } else {
    getLinkedProfiles({initialProfileState, setInitialProfileState, handleErrorNotification, setGridData});
  }
};

export const onColumnsSave = async (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  activeProfile: StaticOrUserProfile | undefined,
  gridData: StaticOrUserProfile[],
  updatedColumns: TreeNode[],
  handleSuccessNotification: HandleNotification,
  profileType: number,
  props: CommonProps,
  isOceansblueUser: boolean,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength} = props;
  setIsModalOpen(false);

  const index = activeProfile && gridData.findIndex((item) => item.id === activeProfile.id && checkProfileTypes(item, activeProfile));
  if (index !== undefined && index >= 0 && activeProfile) {
    const profile: StaticOrUserProfile = {
      ...gridData[index],
      metricCodes: updateTreeViewColumns(updatedColumns),
      longName: activeProfile.longName,
    };
    updateItems(
      profile,
      handleSuccessNotification,
      gridData,
      profileType,
      {
        initialProfileState,
        setInitialProfileState,
        handleErrorNotification,
        setGridData,
        setProfilesLength,
      },
        isOceansblueUser,
    );
  } else {
    handleErrorNotification(true, `Oops something went wrong, please try again`);
  }
};
