import {ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import React, {useContext, useState, useEffect} from 'react';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import axios from 'axios';

import {isActiveProfile, getProfiles, getAllLocationsString} from './util';
import ConfirmModal from '../modals/ConfirmModal';
import {getToken, getUser} from '../../../Utils/Common';
import {SidebarContext} from '../../context/SidebarContextProvider';
import Actions from './Actions';
import {ProfilesResponse, ProfileBase, Profile, StaticOrUserProfile} from './ColumnsCollapsableList';
import {Notification as Notification2} from '../../context/SidebarContextProvider';
import Notification from '../Notification';
import {getLinkedProfiles} from '../grid/util-requests';
import {LinkedProfile} from '../grid/pages/LinkedProfiles';

interface UnitCollapsableListProps {
  isLoading: boolean;
  setActiveSearchLocations: React.Dispatch<React.SetStateAction<string>>;
}

const UnitsCollapsableList = ({isLoading, setActiveSearchLocations}: UnitCollapsableListProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<Profile | ProfileBase | null>(null);
  const [linkedProfiles, setLinkedProfiles] = useState<LinkedProfile[]>([]);
  const [profiles, setProfiles] = React.useState<ProfilesResponse | null>(null);
  const [errorNotification, setErrorNotification] = useState<Notification2>({text: '', show: false});
  const [successNotification, setSuccessNotification] = useState<Notification2>({text: '', show: false});
  const {
    handleSetHeader,
    handleSideBar,
    handleProfile,
    handleNewUnitProfile,
    handleIsCreatingNewProfile,
    activeUnitProfile,
    handleActiveUnitProfile,
    handleActiveLinkedProfile,
    handleActiveUnits,
    activeUnits,
  } = useContext(SidebarContext);
  const isOceansblueUser = getUser().includes('@oceansblue.co.uk');

  const handleErrorNotification = (show: boolean, text?: string) => {
    show && text ? setErrorNotification({text, show}) : setErrorNotification({...errorNotification, show});
  };

  const handleSuccessNotification = (show: boolean, text?: string) => {
    show && text ? setSuccessNotification({text, show}) : setSuccessNotification({...successNotification, show});
  };

  const handleChange = (item: Profile | ProfileBase, isStatic: boolean) => {
    if (activeUnitProfile && activeUnitProfile.id === item.id) {
      setOpen(false);
    } else {
      handleActiveUnitProfile(item);
      setActiveSearchLocations(item.metricCodes);
      const isLinkedProfile = linkedProfiles.find(
        (profile) => profile.unitProfileId === item.id && isStatic === profile.unitProfileIsStatic && profile.isActive,
      );
      if (isLinkedProfile) {
        handleActiveLinkedProfile(isLinkedProfile);
      } else {
        handleActiveLinkedProfile(undefined);
      }

      setOpen(false);
    }
  };

  const onConfirm = async () => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_APIURL +
          `profiles/DeleteProfile?email=${getUser()}&profileId=${activeItem && activeItem.id}&isStatic=${
            activeItem && !activeItem.hasOwnProperty('email') ? true : false
          }&profileTypeId=2`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );
      if (response.status === 200) {
        handleSuccessNotification(true, `The ${activeItem && activeItem.longName} profile was successfully deleted`);
      } else {
        handleErrorNotification(true, `Couldn't delete the ${activeItem && activeItem.longName} profile, please try again`);
      }
      setIsModalOpen(false);
    } catch (e) {
      console.log(e);
      handleErrorNotification(true, `Couldn't delete the ${activeItem && activeItem.longName} profile, please try again`);
    }
  };

  const addNewProfile = async (isStatic: boolean) => {
    handleSideBar(true);
    handleSetHeader('Units');
    handleProfile(undefined);
    handleIsCreatingNewProfile(true);
    if (!activeUnitProfile && !activeUnits) {
      const selectedLocations = await getAllLocationsString();
      handleActiveUnits({metricCodes: selectedLocations, longName: '', positionIndex: 0, profileType: 2, id: 1});
      handleNewUnitProfile({profile: undefined, isStatic});
    } else {
      handleNewUnitProfile({profile: activeUnitProfile, isStatic});
    }
  };

  const onEdit = (item: Profile | ProfileBase) => {
    handleSideBar(true);
    handleSetHeader('Units');
    handleIsCreatingNewProfile(false);
    handleProfile(item);
  };

  const onDelete = (item: Profile | ProfileBase) => {
    setIsModalOpen(true);
    setActiveItem(item);
  };

  const isInLinkedProfile = (item: StaticOrUserProfile, isStatic: boolean) => {
    const isLinkedProfile = linkedProfiles.find(
      (profile) => profile.unitProfileId === item.id && isStatic === profile.unitProfileIsStatic && profile.isActive,
    );
    if (isLinkedProfile) return true;
    return false;
  };

  useEffect(() => {
    if (open) {
      getProfiles({profileType: 2, handleErrorNotification, handleSetHeader, handleSideBar, setOpen, setProfiles});
      getLinkedProfiles({
        initialProfileState: undefined,
        setInitialProfileState: undefined,
        handleErrorNotification,
        setGridData: setLinkedProfiles,
      });
    }
  }, [open]);

  return (
    <>
      <span
        style={{position: 'relative'}}
        onBlur={(event: any) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setOpen(false);
          }
        }}>
        <TelButton
          className="buttons-container-button"
          onClick={() => {
            setOpen((open) => !open);
          }}
          disabled={isLoading}
          title="Select units profile"
          iconClass="fa-solid fa-list">
          Unit Profiles
        </TelButton>
        {profiles && open && (
          <span
            style={{
              boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
              position: 'absolute',
              left: '5%',
              top: '35px',
              zIndex: 100,
              borderRadius: '5px',
              backgroundColor: 'white',
              color: 'black',
              maxHeight: '400px',
              overflowY: 'auto',
            }}>
            {profiles && profiles.profiles && profiles.profiles.length > 0 && (
              <>
                <div style={{display: 'flex', margin: '5px 0', alignItems: 'center'}}>
                  <ListItem style={{fontWeight: 600, fontSize: '13px', paddingLeft: '9px'}}>User Profiles</ListItem>
                  <TelButton
                    iconClass="fa-solid fa-user-plus"
                    className="buttons-container-button"
                    onClick={() => addNewProfile(false)}
                    title={`Create a new unit user profile using the current units`}
                    style={{
                      padding: '0 7px',
                      maxHeight: '30px',
                      minHeight: '30px',
                      marginRight: '10px',
                    }}>
                    {''}
                  </TelButton>
                </div>
                {profiles.profiles.map((item) => (
                  <ListItem
                    button
                    selected={isActiveProfile(item, activeUnitProfile)}
                    style={{
                      minWidth: '250px',
                      width: '100%',
                      padding: `3px 0 3px ${isActiveProfile(item, activeUnitProfile) ? '5' : '9'}px`,
                      borderLeft: isActiveProfile(item, activeUnitProfile) ? '4px solid #0080c1' : '',
                    }}
                    key={`user-profile-${item.id}`}
                    onMouseUp={() => handleChange(item, false)}>
                    {isInLinkedProfile(item, false) && (
                      <span
                        className="fa-solid fa-link"
                        style={{paddingRight: '3px', maxWidth: '17px', fontSize: '10px', fontWeight: 600}}></span>
                    )}
                    <ListItemText primary={item.longName} style={{paddingLeft: !isInLinkedProfile(item, false) ? '17px' : '0'}} />
                    <Actions onDelete={() => onDelete(item)} onEdit={() => onEdit(item)} />
                  </ListItem>
                ))}
              </>
            )}
            {profiles && profiles.profilesStatic && profiles.profilesStatic.length > 0 && (
              <>
                <div style={{display: 'flex', margin: '5px 0', alignItems: 'center'}}>
                  <ListItem style={{fontWeight: 600, fontSize: '13px', paddingLeft: '9px'}}>Trust Profiles</ListItem>
                  {isOceansblueUser && (
                    <TelButton
                      title={`Create a new unit trust profile using the current units`}
                      iconClass="fa-solid fa-hospital"
                      onClick={() => addNewProfile(true)}
                      style={{
                        padding: '0 7px',
                        maxHeight: '30px',
                        minHeight: '30px',
                        marginRight: '10px',
                      }}>
                      {''}
                    </TelButton>
                  )}
                </div>
                {profiles.profilesStatic.map((item) => (
                  <ListItem
                    selected={isActiveProfile(item, activeUnitProfile)}
                    button
                    style={{
                      minWidth: '250px',
                      width: '100%',
                      padding: `3px 0 3px ${isActiveProfile(item, activeUnitProfile) ? '5' : '9'}px`,
                      borderLeft: isActiveProfile(item, activeUnitProfile) ? '4px solid #0080c1' : '',
                    }}
                    key={`static-profile-${item.id}`}
                    onMouseUp={() => handleChange(item, true)}>
                    {isInLinkedProfile(item, true) && (
                      <span
                        className="fa-solid fa-link"
                        style={{paddingRight: '3px', maxWidth: '17px', fontSize: '10px', fontWeight: 600}}></span>
                    )}
                    <ListItemText primary={item.longName} style={{paddingLeft: !isInLinkedProfile(item, true) ? '17px' : '0'}} />
                    <Actions onDelete={() => onDelete(item)} onEdit={() => onEdit(item)} isDisbaled={!isOceansblueUser} />
                  </ListItem>
                ))}
              </>
            )}
          </span>
        )}
        <ConfirmModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          text={`Are you sure you want to delete the ${activeItem && activeItem.longName} profile?`}
          onConfirm={onConfirm}
        />
      </span>
      <Notification
        open={successNotification.show}
        onClose={() =>
          setSuccessNotification((successNotification) => {
            return {...successNotification, show: false};
          })
        }
        severity="info"
        text={successNotification.text}
      />
      <Notification
        open={errorNotification.show}
        onClose={() =>
          setErrorNotification((errorNotification) => {
            return {...errorNotification, show: false};
          })
        }
        severity="error"
        text={errorNotification.text}
      />
    </>
  );
};

export default UnitsCollapsableList;
