import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

import PublicRoute from '../Utils/PublicRoute';
import Login from '../Login';
import '../login.css';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // handle button click of login form
  const handleRegister = () => {
    setError('');
    setMessage('');
    setLoading(true);

    console.log('hi');
    console.log(process.env.NODE_ENV);
    console.log(process.env.REACT_APP_APIURL);
    axios
      .post(process.env.REACT_APP_APIURL + 'auth/user/resetpasswordrequest', {email: username.value})
      .then((response) => {
        setLoading(false);
        setMessage(
          "If you have a matching email address we have sent you an email to allow you to reset your password. If you don't recieve an email in the next few minuets please try again. Failing that please contact our support team for further assistance",
        );
      })
      .catch((error) => {
        setLoading(false);
        if (error.response == null) setError('Something went wrong. Please try again later.');
        else if (error.response.status === 500) setError(error.response.data.detail);
        else setError('Something went wrong. Please try again later.');
      });
  };

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Forgot Password</h1>
              <p>Please enter your email address below, if you have an account a reset email will be sent to you. </p>
            </div>
            <div className="login-form">
              <div className="control-group">
                <input type="text" {...username} autoComplete="new-password" id="forgotpassword-email" placeholder="Email" />
                <label className="login-field-icon fui-user" htmlFor="forgotpassword-email"></label>
              </div>
              {error && (
                <>
                  <small style={{color: 'red'}}>{error}</small>
                  <br />
                </>
              )}
              <br />
              {message && (
                <>
                  <span style={{color: '#777'}}>{message}</span>
                  <br />
                </>
              )}
              <br />
              <Link className="active" to="/Login">
                Click here to Login
              </Link>
              <PublicRoute path="/Login" component={Login} />
              <input
                className="btnright"
                type="button"
                value={loading ? 'Loading...' : 'Reset Password'}
                onClick={handleRegister}
                disabled={loading}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useFormInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: {target: {value: React.SetStateAction<string>}}) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default ForgotPassword;
