import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

import {setUserSession} from '../Utils/Common';
import PublicRoute from '../Utils/PublicRoute';
import Login from '../Login';
import '../login.css';

function Register(props: {history: string[]}) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const firstname = useFormInput('');
  const lastname = useFormInput('');
  const password = useFormInput('');
  const confirmPassword = useFormInput('');
  const [error, setError] = useState('');

  // handle button click of login form
  const handleRegister = () => {
    setError('');
    setLoading(true);

    if (password.value !== confirmPassword.value) {
      setError('Your password do not match');
      setLoading(false);
      return;
    }

    axios
      .post(process.env.REACT_APP_APIURL + 'auth/signup', {
        email: username.value,
        password: password.value,
        firstname: firstname.value,
        lastname: lastname.value,
      })
      .then((response) => {
        setLoading(false);
        setUserSession(response.data, username.value);
        props.history.push('/login');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response == null) setError('Something went wrong. Please try again later.');
        else if (error.response.status === 500) setError(error.response.data.detail);
        else setError('Something went wrong. Please try again later.');
      });
  };

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Register</h1>
              <p>If you don't have an account yet please register here. You will be able to login after your account has been approved.</p>
            </div>
            <div className="login-form">
              <div className="control-group">
                <input
                  type="text"
                  {...username}
                  autoComplete="new-password"
                  id="register-name"
                  placeholder="Username, this is your email address"
                />
                <label className="login-field-icon fui-user" htmlFor="register-name"></label>
              </div>
              <div className="control-group">
                <input type="text" {...firstname} autoComplete="new-password" id="register-firstname" placeholder="Firstname" />
                <label className="login-field-icon fui-user" htmlFor="register-firstname"></label>
              </div>
              <div className="control-group">
                <input type="text" {...lastname} autoComplete="new-password" id="register-lastname" placeholder="Lastname" />
                <label className="login-field-icon fui-user" htmlFor="register-lastname"></label>
              </div>
              <div className="control-group">
                <input type="password" {...password} autoComplete="new-password" id="register-password" placeholder="Password" />
                <label className="login-field-icon fui-user" htmlFor="register-password"></label>
              </div>
              <div className="control-group">
                <input
                  type="password"
                  {...confirmPassword}
                  autoComplete="new-password"
                  id="register-confirmpassword"
                  placeholder="Confirm Password"
                />
                <label className="login-field-icon fui-user" htmlFor="register-confirmpassword"></label>
              </div>
              {error && (
                <>
                  <span style={{color: 'red'}}>{error}</span>
                  <br />
                </>
              )}
              <br />
              <Link className="active" to="/Login">
                Click here to Login
              </Link>
              <PublicRoute path="/Login" component={Login} />
              <input
                className="btnright"
                type="button"
                value={loading ? 'Registering...' : 'Register'}
                onClick={handleRegister}
                disabled={loading}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useFormInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: {target: {value: React.SetStateAction<string>}}) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Register;
