import React from 'react';
import queryString from 'query-string';

export default function ReportViewer() {
  const [report, setReport] = React.useState('');
  const parsed = queryString.parse(window.location.search);

  React.useEffect(() => {
    let report = localStorage.getItem('ReportHtml' + parsed.reportid);
    if (report !== null) {
      setReport(report);
    }
    document.title = parsed.org + ' ' + parsed.start + ' ' + parsed.end;
    localStorage.removeItem('ReportHtml' + parsed.reportid);
  }, []);

  function createMarkup() {
    return {__html: report};
  }

  return (
    <div className="reportsviewer">
      <h1 style={{fontSize: '16px', paddingLeft: '15px' }}>
              {parsed.org + ' | Roster '} <strong> {parsed.start} </strong> {' to '} <strong> {parsed.end}</strong>
      </h1>
      <div className="reportview" dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
}
