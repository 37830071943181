import {TreeNode} from '../Treeview.jsx';
import {StaticOrUserProfile, ProfilesResponse} from './ColumnsCollapsableList.jsx';
import axios, {AxiosResponse} from 'axios';
import {getUser, getTrust, getToken} from '../../../Utils/Common';
import {HandleNotification, checkProfileTypes} from '../grid/util';
import {HeaderText} from '../../context/SidebarContextProvider.jsx';

interface CreateColumns {
  parentColumns: any[];
  childColumns?: any[];
  textField: string;
  valueField: string;
  compareField?: string;
  valueSecondField: string;
}

interface GetProfiles {
  handleSetHeader: (header: HeaderText) => void;
  handleSideBar: (isOpen: boolean) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProfiles: React.Dispatch<React.SetStateAction<ProfilesResponse | null>>;
  handleErrorNotification: HandleNotification;
  profileType: number;
}

const createChildColumns = (data: CreateColumns): any[] => {
  let childrenCols;
  if (data.parentColumns && data.parentColumns.every((row) => row && row.items === null)) {
    let tempArr: Object[] = [];
    data.parentColumns.forEach((item) => {
      tempArr.push(item);
    });
    childrenCols = tempArr;
  } else if (!data.childColumns) {
    let tempArr: Object[] = [];
    data.parentColumns.forEach((item) => {
      if (item.items) {
        item.items.forEach((i: Object) => {
          tempArr.push(i);
        });
      }
    });
    childrenCols = tempArr;
  } else {
    childrenCols = data.childColumns;
  }
  return childrenCols || [];
};

export function updateColumns(columns: string, data: CreateColumns) {
  let filteredParentColumns: any[] = [];
  let filteredChildColumns: any[] = [];
  if (columns) {
    const childColumns = columns.trim().includes(';') ? columns.trim().split(';')[1].split(',') : columns.trim().split(',');

    const childrenCols = createChildColumns(data);

    childrenCols.forEach((col) => {
      if (data.valueSecondField in col) {
        let areValuesMatched = false;
        if (typeof col[data.valueSecondField] === 'string' && childColumns.includes(col[data.valueSecondField])) areValuesMatched = true;
        if (typeof col[data.valueSecondField] === 'number' && childColumns.includes(col[data.valueSecondField].toString())) {
          areValuesMatched = true;
        }

        if (areValuesMatched) {
          filteredChildColumns.push(col);
          const hasParentIndex = filteredParentColumns.find((item) => {
            if (item && data.valueField in item && item[data.valueField]) {
              return item[data.valueField] === col[data.valueField];
            }
          });
          if (!hasParentIndex) {
            const itemToPush = data.parentColumns.find(
              (item) => data.valueField in item && data.valueField in col && item[data.valueField] === col[data.valueField],
            );
            itemToPush && filteredParentColumns.push(itemToPush);
          }
        }
      }
    });
  }

  return {parentColumns: filteredParentColumns, childColumns: filteredChildColumns};
}

function createColumns(data: CreateColumns) {
  let resultsArray: TreeNode[] = [];

  data.parentColumns.forEach((item) => {
    const obj = {
      text: data.textField in item && item[data.textField],
      value: data.valueField in item && item[data.valueField],
      items: [],
      checked: item.checked,
    };
    resultsArray.push(obj);
  });

  const childrenCols = createChildColumns(data);

  childrenCols.forEach((item) => {
    const obj = {
      text: data.textField in item && item[data.textField],
      value: data.valueSecondField in item && item[data.valueSecondField],
      checked: item.checked,
    };

    let indexWithItems, index;
    if (!data.compareField) {
      index = resultsArray.findIndex((i) => data.valueField in item && i.value === item[data.valueField]);
    } else {
      index = resultsArray.findIndex((i) => data.compareField && i.text === item[data.compareField]);
    }
    if (index >= 0 && resultsArray[index]) {
      indexWithItems = resultsArray[index].items;
    }

    if (indexWithItems) {
      indexWithItems.push(obj);
    }
  });

  return resultsArray;
}

export function transformColumnsToTreeview(columns: string, data: CreateColumns): TreeNode[] {
  let resultsArray = createColumns(data);
  if (columns) {
    let parentColumns: string[];
    let childColumns: string[];
    if (columns.includes(';')) {
      parentColumns = columns.trim().split(';')[0].split(',');
      childColumns = columns.trim().split(';')[1].split(',');
    } else if (data.parentColumns.every((row) => row && row.items === null)) {
      parentColumns = columns.trim().split(',');
      childColumns = [];
    } else {
      parentColumns = [];
      childColumns = columns.trim().split(',');
    }

    resultsArray.forEach((item) => {
      let index: number;
      if (item.value !== undefined && parentColumns && parentColumns.includes(item.value.toString())) {
        index = resultsArray.findIndex((i) => i.value === item.value);
        if (index >= 0 && resultsArray[index]) resultsArray[index].checked = true;
      }
      if (item.items && item.items.length > 0) {
        item.items.forEach((i) => {
          if (i.value !== undefined && childColumns.includes(i.value.toString())) {
            i.checked = true;
          }
        });
      }
    });
  }

  return resultsArray;
}

export function updateTreeViewColumns(data: TreeNode[]): string {
  let parentString: string = '';
  let childString: string = '';

  if (data) {
    data.forEach((item) => {
      if (item.checked) {
        if (parentString) {
          parentString += `,${item.value}`;
        } else {
          parentString += item.value;
        }
      }
      if (item.items) {
        item.items.forEach((i) => {
          if (i.checked) {
            if (childString) {
              childString += `,${i.value}`;
            } else {
              childString += i.value;
            }
          }
        });
      }
    });
  }

  const nonDuplicatesParentString = parentString ? [...new Set(parentString.split(','))] : '';
  const nonDuplicatesChildString = childString ? [...new Set(childString.split(','))] : '';
  if (nonDuplicatesParentString && nonDuplicatesChildString) return nonDuplicatesParentString + ';' + nonDuplicatesChildString;
  if (nonDuplicatesParentString && !nonDuplicatesChildString) return nonDuplicatesParentString + '';
  if (!nonDuplicatesParentString && nonDuplicatesChildString) return nonDuplicatesChildString + '';

  return '';
}

export const colsToString = (cols: any[]): string => {
  if (Array.isArray(cols) && cols.length > 0) {
    let parentString: string = '';
    let childString: string = '';
    let totalString: string = '';
    let currentParentString: string = '';
    cols.forEach((col) => {
      if (childString) childString += `${col ? ',' + col.field : ''}`;
      else childString += `${col ? col.field : ''}`;
      if (parentString && col && currentParentString !== col.group) {
        parentString += `${col ? ',' + col.group : ''}`;
        currentParentString = col && col.group;
      }
      if (!parentString) {
        parentString += `${col ? col.field : ''}`;
        currentParentString = col && col.group;
      }
    });
    if (childString && parentString) {
      totalString = `${parentString};${childString}`;
      return totalString;
    }
  }
  return '';
};

export const isActiveProfile = (item: StaticOrUserProfile, activeProfile?: StaticOrUserProfile): boolean => {
  if (activeProfile && checkProfileTypes(item, activeProfile)) {
    if (item.id === activeProfile.id) {
      return true;
    }
  }
  return false;
};

export const getProfiles = async (props: GetProfiles) => {
  const {handleErrorNotification, handleSetHeader, handleSideBar, setOpen, setProfiles, profileType} = props;
  try {
    const response: AxiosResponse<ProfilesResponse> = await axios.get(
      process.env.REACT_APP_APIURL + `profiles/GetProfiles?email=${getUser()}&trustId=${getTrust().trustId}&profileTypeId=${profileType}`,
      {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      },
    );

    if (response.data.profiles.length < 1 && response.data.profilesStatic.length < 1) {
      handleSideBar(true);
      handleSetHeader(profileType === 1 ? 'Columns' : 'Units');
      setOpen(false);
    }
    setProfiles(response.data);
    if (response.status !== 200) {
      handleErrorNotification(true, "Couldn't fetch profiles, please try again or check your connection");
    }
  } catch (err) {
    console.log(err);
    handleErrorNotification(true, "Couldn't fetch profiles, please try again or check your connection");
  }
};

export const getAllLocationsString = async (): Promise<string> => {
  const treeData = await getLocations();
  const locs = transformColumnsToTreeview('', {
    parentColumns: treeData || [],
    textField: 'locationName',
    valueField: 'locationId',
    valueSecondField: 'locationId',
    compareField: 'esrDivision',
  });
  locs.forEach((g) => {
    g.checked = true;
    if (Array.isArray(g.items)) {
      g.items.forEach((c) => {
        c.checked = true;
      });
    }
  });
  let selectedLocations = updateTreeViewColumns(locs);
  return selectedLocations || '';
};

export const getLocations = async (isChoosingFromAllLocations: boolean = false): Promise<TreeNode[] | undefined> => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_APIURL + 'HealthRosterLocations/GetLocations',
      {
        trustName: getTrust().trustName,
        email: getUser(),
        useLocString: !isChoosingFromAllLocations
      },
      {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      },
    );
    return response.data;
  } catch (err) {
      console.log(err);
}
};
