import React from 'react';
import {Box, Dialog, Typography, IconButton, DialogContent, DialogActions} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {useStyles} from '../../LocationsTreeView';

interface ConfirmModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: any;
  text: string;
}

const ConfirmModal = ({isModalOpen, setIsModalOpen, onConfirm, text}: ConfirmModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-label="delete-modal"
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        style={{backgroundColor: '#0080C1', padding: '0 10px '}}>
        <Typography variant="h6" style={{color: 'white'}}>
          Please confirm
        </Typography>
        <IconButton aria-label="close" onClick={() => setIsModalOpen(false)}>
          <FontAwesomeIcon icon={faTimes} style={{color: 'white'}} />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box justifyContent="center" display="flex" alignItems="center" style={{minHeight: '100px'}}>
          <Typography variant="body1" style={{fontSize: '1.3em'}}>
            {text}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" className={classes.defaultBtn} onClick={() => setIsModalOpen(false)}>
          No
        </Button>
        <Button autoFocus onClick={() => onConfirm()} className={classes.btn} variant="contained" color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
