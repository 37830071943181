import * as React from 'react';
import NavMenus from './NavMenu';
import StaffAuditView from './StaffAuditView'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useState } from 'react';

export default function DataQuality(props: { history: any[]; }) {

    const [selected, setSelected] = useState(0)

    const selectedAppBarTab:number = 0
    const handleSelect = (e: { selected: React.SetStateAction<number>; }) => {
        setSelected( e.selected )
    }

    return (
        <div>
            <NavMenus history={props.history} selectedTab={selectedAppBarTab} disabledTabs={[false, true, true]} />
            <TabStrip selected={selected} onSelect={handleSelect} className="secondtabs">
                <TabStripTab title="Staff" >
                    <div>
                        <StaffAuditView {...props}/>
                    </div>
                </TabStripTab>
                <TabStripTab title="Shifts">
                    <div>
                      Not yet implemented
                    </div>
                </TabStripTab>
            </TabStrip>
            
        </div>
    );
}
