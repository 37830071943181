import React, {createContext, ReactNode, useState} from 'react';

import {Profile, ProfileBase} from '../common/collapsable-list/ColumnsCollapsableList';
import {ActiveProfile} from '../WardGuardian';
import {LinkedProfile} from '../common/grid/pages/LinkedProfiles';

export interface Notification {
  text: string;
  show: boolean;
}

export type HeaderText = 'Units' | 'Columns' | 'Linked' | 'Subscriptions';

type SidebarContextProps = {
  isSidebarOpen: boolean;
  handleSideBar: (isOpen: boolean) => void;
  header: string | null;
  handleSetHeader: (header: HeaderText) => void;
  profile: Profile | ProfileBase | undefined;
  handleProfile: (profile: Profile | ProfileBase | undefined) => void;
  linkedProfile: LinkedProfile | undefined;
  handleLinkedProfile: (profile: LinkedProfile | undefined) => void;
  activeProfile: Profile | ProfileBase | undefined;
  handleActiveProfile: (profile: Profile | ProfileBase | undefined) => void;
  handleActiveEditedProfile: (profile: Profile | ProfileBase | undefined) => void;
  activeEditedProfile: Profile | ProfileBase | undefined;
  handleActiveUnitEditedProfile: (profile: Profile | ProfileBase | undefined) => void;
  activeUnitEditedProfile: Profile | ProfileBase | undefined;
  activeUnitProfile: Profile | ProfileBase | undefined;
  handleActiveUnitProfile: (profile: Profile | ProfileBase | undefined) => void;
  activeLinkedProfile: LinkedProfile | undefined;
  handleActiveLinkedProfile: (profile: LinkedProfile | undefined) => void;
  newProfile: ActiveProfile;
  handleNewProfile: (profile: ActiveProfile) => void;
  newUnitProfile: ActiveProfile;
  handleNewUnitProfile: (profile: ActiveProfile) => void;
  activeUnits: Profile | ProfileBase | undefined;
  handleActiveUnits: (profile: Profile | ProfileBase | undefined) => void;
  activeColumns: Profile | ProfileBase | undefined;
  handleActiveColumns: (profile: Profile | ProfileBase | undefined) => void;
  isCreatingNewProfile: boolean;
  handleIsCreatingNewProfile: (isOpen: boolean) => void;
};

export const SidebarContext = createContext<SidebarContextProps>({
  isSidebarOpen: false,
  handleSideBar: () => {},
  header: 'Columns',
  handleSetHeader: () => {},
  profile: undefined,
  handleProfile: () => {},
  linkedProfile: undefined,
  handleLinkedProfile: () => {},
  activeProfile: undefined,
  handleActiveProfile: () => {},
  activeEditedProfile: undefined,
  handleActiveEditedProfile: () => {},
  activeUnitEditedProfile: undefined,
  handleActiveUnitEditedProfile: () => {},
  newProfile: {isStatic: false, profile: null},
  handleNewProfile: () => {},
  newUnitProfile: {isStatic: false, profile: null},
  handleNewUnitProfile: () => {},
  activeUnitProfile: undefined,
  handleActiveUnitProfile: () => {},
  activeLinkedProfile: undefined,
  handleActiveLinkedProfile: () => {},
  activeUnits: undefined,
  handleActiveUnits: () => {},
  activeColumns: undefined,
  handleActiveColumns: () => {},
  isCreatingNewProfile: false,
  handleIsCreatingNewProfile: () => {},
});

export const SidebarContextProvider = ({children}: {children: ReactNode}) => {
  const [isCreatingNewProfile, setIsCreatingNewProfile] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [header, setHeader] = useState<HeaderText>('Columns');
  const [profile, setProfile] = useState<Profile | ProfileBase | undefined>(undefined);
  const [linkedProfile, setLinkedProfile] = useState<LinkedProfile | undefined>(undefined);
  const [activeProfile, setActiveProfile] = useState<Profile | ProfileBase | undefined>(undefined);
  const [activeEditedProfile, setActiveEditedProfile] = useState<Profile | ProfileBase | undefined>(undefined);
  const [activeUnitProfile, setActiveUnitProfile] = useState<Profile | ProfileBase | undefined>(undefined);
  const [activeLinkedProfile, setActiveLinkedProfile] = useState<LinkedProfile | undefined>(undefined);
  const [newUnitProfile, setNewUnitProfile] = useState<ActiveProfile>({isStatic: false, profile: null});
  const [newProfile, setNewProfile] = useState<ActiveProfile>({isStatic: false, profile: null});
  const [activeUnitEditedProfile, setActiveUnitEditedProfile] = useState<Profile | ProfileBase | undefined>(undefined);
  const [activeUnits, setActiveUnits] = useState<Profile | ProfileBase | undefined>(undefined);
  const [activeColumns, setActiveColumns] = useState<Profile | ProfileBase | undefined>(undefined);

  const handleIsCreatingNewProfile = (isOpen: boolean) => {
    if (isOpen) {
      setIsCreatingNewProfile(true);
    } else {
      setIsCreatingNewProfile(false);
    }
  };

  const handleSideBar = (isOpen: boolean) => {
    if (isOpen) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  };

  const handleProfile = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setProfile({...profile});
    else setProfile(undefined);
  };

  const handleLinkedProfile = (profile: LinkedProfile | undefined) => {
    if (profile) setLinkedProfile({...profile});
    else setLinkedProfile(undefined);
  };

  const handleActiveUnitProfile = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setActiveUnitProfile({...profile});
    else setActiveUnitProfile(undefined);
  };

  const handleActiveLinkedProfile = (profile: LinkedProfile | undefined) => {
    if (profile) setActiveLinkedProfile({...profile});
    else setActiveLinkedProfile(undefined);
  };

  const handleActiveProfile = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setActiveProfile({...profile});
    else setActiveProfile(undefined);
  };

  const handleActiveUnits = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setActiveUnits({...profile});
    else setActiveUnits(undefined);
  };

  const handleActiveColumns = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setActiveColumns({...profile});
    else setActiveColumns(undefined);
  };

  const handleActiveEditedProfile = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setActiveEditedProfile({...profile});
    else setActiveEditedProfile(undefined);
  };

  const handleNewProfile = (profile: ActiveProfile) => {
    if (profile) setNewProfile(profile);
    else setNewProfile({isStatic: false, profile: null});
  };

  const handleNewUnitProfile = (profile: ActiveProfile) => {
    if (profile) setNewUnitProfile({...profile});
    else setNewUnitProfile({isStatic: false, profile: null});
  };

  const handleActiveUnitEditedProfile = (profile: Profile | ProfileBase | undefined) => {
    if (profile) setActiveUnitEditedProfile({...profile});
    else setActiveUnitEditedProfile(undefined);
  };

  const handleSetHeader = (header: HeaderText) => {
    setHeader(header);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        handleSideBar,
        header,
        handleSetHeader,
        profile,
        handleProfile,
        linkedProfile,
        handleLinkedProfile,
        handleActiveProfile,
        activeProfile,
        handleActiveUnitProfile,
        activeUnitProfile,
        activeLinkedProfile,
        handleActiveLinkedProfile,
        activeEditedProfile,
        handleActiveEditedProfile,
        activeUnitEditedProfile,
        handleActiveUnitEditedProfile,
        newUnitProfile,
        handleNewProfile,
        handleNewUnitProfile,
        newProfile,
        activeColumns,
        activeUnits,
        handleActiveColumns,
        handleActiveUnits,
        handleIsCreatingNewProfile,
        isCreatingNewProfile,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};
