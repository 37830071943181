import React, {useEffect} from 'react';
import axios, {AxiosResponse} from 'axios';
import {Redirect} from 'react-router-dom';
import {getToken, getTrust, getUser} from '../Utils/Common';
import {Grid, GridColumn as Column, GridDataStateChangeEvent} from '@progress/kendo-react-grid';
import {DataResult, process as gridprocess, SortDescriptor, State} from '@progress/kendo-data-query';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import Button from '@material-ui/core/Button';
import {ExcelExport} from '@progress/kendo-react-excel-export';

import {loadingPanel} from './WardGuardian';
import {ColumnMenu} from './common/SimpleColumnMenu';
import MaterialTreeView, {TreeNode} from './common/Treeview';
import GenericModal from './common/modals/GenericModal';
import NavMenus from './NavMenu';
import {onSearch, useStyles as useStyles2} from './LocationsTreeView';
import {HeaderCell} from './common/SimpleHeaderCell';
import {NumberCell} from './common/NumberCell';
import {sortArrCustom} from './GridCellTypes';

export interface UserActivity {
  firstName: string;
  lastName: string;
  accountName: string;
  registeredDate: Date;
  registeredDateFormatted: string;
  trust: string;
  lastLoginDate: Date;
  lastLoginDateFormatted: string;
  lastLoggedInDaysAgo: number;
  loginCountLast3Months: number;
  loginCountTotal: number;
}

interface Trust {
  trustId: number;
  trust: string;
}

const createDataState = (data: UserActivity[], dataState: State) => {
  const dataStateCopy = {...dataState};
  dataStateCopy.sort = [];
  return {
    result: gridprocess(data, dataStateCopy),
    dataState: dataState,
  };
};

let defaultSort: SortDescriptor[] = [{field: 'loginCountTotal', dir: 'desc'}];

function transformArrayIntoNodes<T>(data: T[], selected: T[], textField: keyof T, valueField: keyof T): TreeNode[] {
  const transformedArray: TreeNode[] = data.map((n) => {
    return {
      value: n[valueField] as number,
      text: n[textField] as string,
      checked: selected.map((s) => s[valueField]).indexOf(n[valueField]) > -1,
      items: [],
    };
  });
  return transformedArray;
}

export default function UserActivityView() {
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [userActivity, setUserActivity] = React.useState<UserActivity[]>();
  const [gridData, setGridData] = React.useState<DataResult>();
  const [trusts, setTrusts] = React.useState<Trust[]>([]);
  const [selected, setSelected] = React.useState<Trust[]>([]);
  const [updatedColumns, setUpdatedColumns] = React.useState<TreeNode[]>([]);
  const [searchResults, setSearchResults] = React.useState<TreeNode[]>([]);
  const [gridHeight, setGridHeight] = React.useState<number>(0);
  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    sort: defaultSort,
  });

  const _export = React.useRef<ExcelExport | null>(null);
  const treeData = transformArrayIntoNodes(trusts, selected, 'trust', 'trustId');
  const classes2 = useStyles2();
  const trustsStr = selected.map((t) => t.trust).join(', ');
  const trustStrWidth = window.innerWidth > 1550 ? 80 : 60;
  let MyGrid = React.useRef<Grid>(null);

  const resultState = () => {
    let data: DataResult | undefined;
    const field = dataState.sort && dataState.sort[0] && dataState.sort[0].field;
    const col = MyGrid.current && MyGrid.current.columns.filter((item) => item && item.field === field)[0];
    if (col && (col.filter === 'date' || col.filter === 'numeric' || col.filter === 'text')) {
      const sortType = col.filter === 'date' ? 'date' : col.filter === 'numeric' ? 'number' : col.filter === 'text' ? 'text' : 'bool';
      if (sortType !== 'bool' && dataState.sort && gridData) {
        const sortedData = sortArrCustom(gridData.data, dataState, sortType, [], field, dataState.sort[0].dir);
        data = {data: sortedData.data, total: sortedData.data.length};
      }
    }
    let processedData = createDataState(data ? data.data : gridData ? gridData.data : [], dataState);
    return processedData;
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const getUserActivity = async () => {
    if (!getUser().includes('oceansblue')) return <Redirect to="/login"></Redirect>;
    try {
      const response: AxiosResponse<UserActivity[]> = await axios.get(
        process.env.REACT_APP_APIURL +
          `UserActivity/GeUserActivity?trustIds=${
            selected && selected.length > 0 ? selected.map((t) => t.trustId).join(',') : getTrust().trustId
          }&email=${getUser()}`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );
      if (response.status === 200) {
        setUserActivity(response.data);
        let updatedState: any = createDataState(response.data, dataState);
        setGridData({data: updatedState.result.data, total: updatedState.result.total});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTrusts = async () => {
    if (!getUser().includes('oceansblue')) return <Redirect to="/login"></Redirect>;
    try {
      const response: AxiosResponse<Trust[]> = await axios.get(process.env.REACT_APP_APIURL + 'trustsettings', {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      });
      if (response.status === 200) {
        setTrusts(response.data);
        const defaultTrust = response.data.find((t) => t.trustId === getTrust().trustId);
        if (defaultTrust) setSelected([defaultTrust]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = () => {
    const updatedColumnsCopy = onSearch(searchResults, updatedColumns);

    let newSelected: Trust[] = [...selected];

    trusts.forEach((t) => {
      if (
        updatedColumnsCopy.map((nt) => nt.value).indexOf(t.trustId) > -1 &&
        updatedColumnsCopy.find((nt) => nt.value === t.trustId)?.checked === true &&
        newSelected.map((nt) => nt.trustId).indexOf(t.trustId) < 0
      ) {
        newSelected.push(t);
      }
      if (
        updatedColumnsCopy.map((nt) => nt.value).indexOf(t.trustId) < 0 ||
        updatedColumnsCopy.find((nt) => nt.value === t.trustId)?.checked === false
      ) {
        newSelected = newSelected.filter((nt) => t.trustId !== nt.trustId);
      }
    });

    const defaultTrust = trusts.find((t) => t.trustId === getTrust().trustId);

    setSelected(newSelected.length < 1 && defaultTrust ? [defaultTrust] : newSelected);
    setSearchResults([]);
    setUpdatedColumns([]);
    setIsOpen(false);
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(userActivity ? userActivity : [], event.dataState);
    setGridData({data: updatedState.result.data, total: updatedState.result.total});
    setDataState(updatedState.dataState);
  };

  useEffect(() => {
    setLoading(true);
    getTrusts();
    document.title = 'User Activity';
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    getUserActivity();
    setLoading(false);
  }, [selected]);

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
      {loading && loadingPanel}
      {!loading && !userActivity ? (
        <div>Sorry something went wrong, please try to refresh the page.</div>
      ) : (
        <>
          <div>
            <NavMenus history={[]} selectedTab={1} disabledTabs={[true, false, true]} />
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                paddingBottom: '10px',
                paddingLeft: '7px',
                paddingTop: '20px',
                background: 'rgba(208, 239, 255, 0.3)',
              }}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label htmlFor="Locations" className="inputlables" style={{color: '#585959', fontWeight: 600}}>
                  Select Trusts
                </label>
                <TelButton
                  id="Trusts"
                  className="wardsearchbutton"
                  title={selected.map((t) => t.trust).join(', ')}
                  onClick={() => setIsOpen(true)}
                  style={{minHeight: '28.16px', minWidth: '500px', maxWidth: 'calc(100vw/3)'}}>
                  {trustsStr.length > trustStrWidth ? trustsStr.substr(0, trustStrWidth - 1) + '...' : trustsStr}
                </TelButton>
              </div>
              <TelButton onClick={excelExport} title="Export to Excel" iconClass="fa-solid fa-file-excel" style={{marginLeft: '14px'}}>
                Export
              </TelButton>
            </div>

            <GenericModal
              isModalOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
                setSearchResults([]);
                setUpdatedColumns([]);
              }}
              text={'Trusts'}
              buttons={
                <>
                  <Button
                    onClick={() => {
                      setIsOpen(false);
                      setSearchResults([]);
                      setUpdatedColumns([]);
                    }}
                    variant="contained"
                    title="Close the window"
                    className={classes2.defaultBtn}>
                    Close
                  </Button>

                  <Button onClick={handleSearch} variant="contained" title="Close the window and run the search" className={classes2.btn}>
                    Search
                  </Button>
                </>
              }>
              <MaterialTreeView
                nodes={treeData}
                setSearchResults={setSearchResults}
                setUpdatedColumns={setUpdatedColumns}
                updatedColumns={updatedColumns}
                searchResults={searchResults}
                placeholder="Select trusts"></MaterialTreeView>
            </GenericModal>
          </div>
          <div
            ref={(e) => {
              if (e) {
                setGridHeight(e.offsetTop + 7);
              }
            }}>
            <ExcelExport data={resultState().result.data} ref={_export}>
              <Grid
                {...dataState}
                ref={MyGrid}
                style={{
                  fontFamily: 'Roboto",sans-serif',
                  marginRight: '20px',
                  height: `calc(100vh - ${gridHeight}px)`,
                  maxHeight: `calc(100vh - ${gridHeight}px)`,
                }}
                rowHeight={30}
                resizable
                data={resultState().result.data}
                sortable
                onDataStateChange={dataStateChange}>
                <Column
                  title="First Name"
                  field="firstName"
                  filter="text"
                  width="120px"
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column
                  title="Last Name"
                  field="lastName"
                  filter="text"
                  width="120px"
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column
                  title="Email"
                  field="accountName"
                  filter="text"
                  width="250px"
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column
                  title="Registration Date"
                  field="registeredDateFormatted"
                  filter="date"
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column title="Trust Name" field="trust" filter="text" columnMenu={ColumnMenu} headerCell={HeaderCell}></Column>
                <Column
                  title="Last Logged In Date"
                  field="lastLoginDateFormatted"
                  filter="date"
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column
                  title="Last Logged In Days Ago"
                  field="lastLoggedInDaysAgo"
                  filter="numeric"
                  cell={NumberCell}
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column
                  title="Login Count Last 3 Months"
                  field="loginCountLast3Months"
                  filter="numeric"
                  cell={NumberCell}
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
                <Column
                  title="Login Count Total"
                  field="loginCountTotal"
                  filter="numeric"
                  cell={NumberCell}
                  columnMenu={ColumnMenu}
                  headerCell={HeaderCell}></Column>
              </Grid>
            </ExcelExport>
          </div>
        </>
      )}
    </div>
  );
}
