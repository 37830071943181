import axios, {AxiosResponse} from 'axios';

import {StaticOrUserProfile, ProfilesResponse} from '../collapsable-list/ColumnsCollapsableList';
import {
  checkProfileTypes,
  GenericObject,
  Positions,
  HandleNotification,
  checkForNewProfile,
  CommonProps,
  LinkedProfileProps,
  checkForNewLinkedProfile,
  SubscriptionProps,
  orgUnitsStrToNumArr,
  emailRegex,
} from './util';
import {getUser, getToken, getTrust, getCurrentContextUser} from '../../../Utils/Common';
import {LinkedProfile} from './pages/LinkedProfiles';
import {Subscription, SubscriptionEmail} from './pages/Subscriptions';

export async function remove(url: string): Promise<boolean> {
  try {
    const response = await axios.delete(process.env.REACT_APP_APIURL + url, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function update<T extends GenericObject>(dataItem: T, postUrl: string, patchUrl: string): Promise<boolean> {
    if (getCurrentContextUser() === null) {
        sessionStorage.setItem('contextUser', JSON.stringify(getUser()));
    }
    if (!dataItem.hasOwnProperty('email') && getCurrentContextUser() !== getUser()) return true;
  delete dataItem['inEdit'];
  if (dataItem['isNew']) {
    delete dataItem['isNew'];
    let newItem = {...dataItem};
    delete newItem['id'];
    try {
      const response: AxiosResponse<T> = await axios.post(process.env.REACT_APP_APIURL + postUrl, newItem, {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      });
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  } else {
    try {
      const response = await axios.patch(process.env.REACT_APP_APIURL + patchUrl, dataItem, {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      });
      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  return false;
}

export async function reorder(positions: Positions, patchUrl: string): Promise<boolean> {
  try {
    const response = await axios.patch(
      process.env.REACT_APP_APIURL + patchUrl,
      {firstPosition: positions.firstValue, secondPosition: positions.secondValue},
      {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      },
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const getProfiles = async (props: CommonProps, profileType: number, newProfile?: StaticOrUserProfile | null) => {
  try {
    const response: AxiosResponse<ProfilesResponse> = await axios.get(
      process.env.REACT_APP_APIURL +
        `profiles/GetProfiles?email=${props.email ? props.email : getUser()}&trustId=${getTrust().trustId}&profileTypeId=${profileType}`,
      {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      },
    );
    let allProfiles = [...response.data.profiles, ...response.data.profilesStatic];

    if (props.initialProfileState) {
      allProfiles.forEach((item) => {
        if (props.initialProfileState && item.id === props.initialProfileState.id && checkProfileTypes(props.initialProfileState, item)) {
          item.inEdit = true;
        }
      });
      props.setInitialProfileState && props.setInitialProfileState(undefined);
    }

    const length = response.data.profiles.length;
    if (response.data.profiles && response.data.profiles.length > 0 && props.setProfilesLength) props.setProfilesLength(length);
    const isProfileStillNew = allProfiles.find(
      (item) => newProfile && item.longName === newProfile.longName && checkProfileTypes(item, newProfile),
    );
    if (newProfile && !isProfileStillNew) {
      if (!newProfile.hasOwnProperty('email')) {
        allProfiles = [...allProfiles, newProfile];
        if (newProfile.positionIndex === 0) {
          const len = response.data.profilesStatic.length;
          if (response.data.profilesStatic[len - 1] && response.data.profilesStatic[len - 1].positionIndex) {
            newProfile.positionIndex = response.data.profilesStatic[len - 1].positionIndex + 1;
          }
        }
      }
      if ('email' in newProfile) {
        allProfiles = [...allProfiles.slice(0, length), newProfile, ...allProfiles.slice(length)];
        if (newProfile.positionIndex === 0) {
          const len = response.data.profiles.length;
          if (response.data.profiles[len - 1] && response.data.profiles[len - 1].positionIndex) {
            newProfile.positionIndex = response.data.profiles[len - 1].positionIndex + 1;
          }
        }
      }
    }
    props.setGridData(allProfiles);

    if (response.status !== 200) {
      props.handleErrorNotification(true, "Couldn't fetch profiles, please try again or check your connection");
    }
  } catch (e) {
    console.log(e);
    props.handleErrorNotification(true, "Couldn't fetch profiles, please try again or check your connection");
  }
};

export const getLinkedProfiles = async (props: LinkedProfileProps, newProfile?: LinkedProfile | null): Promise<LinkedProfile[]> => {
  try {
    const response: AxiosResponse<LinkedProfile[]> = await axios.get(
      process.env.REACT_APP_APIURL + `linkedprofiles/GetLinkedProfiles?email=${getUser()}&trustId=${getTrust().trustId}`,
      {
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
      },
    );

    if (props.initialProfileState) {
      response.data.forEach((item) => {
        if (props.initialProfileState && item.id === props.initialProfileState.id) {
          item.inEdit = true;
        }
      });
      props.setInitialProfileState && props.setInitialProfileState(undefined);
    }
    props.setGridData(response.data);

    if (response.status !== 200) {
      props.handleErrorNotification(true, "Couldn't fetch profiles, please try again or check your connection");
    }
    return response.data;
  } catch (e) {
    console.log(e);
    props.handleErrorNotification(true, "Couldn't fetch profiles, please try again or check your connection");
  }
  return [];
};

export const onConfirm = async (
  dataItem: StaticOrUserProfile | null,
  handleSuccessNotification: HandleNotification,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  gridData: StaticOrUserProfile[],
  props: CommonProps,
  profileType: number,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength} = props;
  if (dataItem) {
    const response = await remove(
      `profiles/DeleteProfile?email=${getCurrentContextUser() || getUser()}&profileId=${dataItem.id}&isStatic=${
        !dataItem.hasOwnProperty('email') ? true : false
      }&profileTypeId=${profileType}`,
    );
    if (response) {
      checkForNewProfile(
        gridData,
        {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength},
        profileType,
      );
      handleSuccessNotification(true, `The ${dataItem.longName} profile was successfully deleted`);
    } else {
      handleErrorNotification(true, `Couldn't delete the ${dataItem.longName} profile, please try again`);
    }
  }
  setIsModalOpen(false);
};

export const getSubs = async (
  setGridData: React.Dispatch<React.SetStateAction<Subscription[]>>,
  handleErrorNotification: HandleNotification,
) => {
  const response: AxiosResponse<Subscription[]> = await axios.get(
    process.env.REACT_APP_APIURL + `Subscriptions/GetSetupUserSubscriptions?email=${getUser()}&trustName=${getTrust().trustName}`,
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
  if (response.status === 200) {
    setGridData(response.data);
  } else {
    handleErrorNotification(true, "Couldn't fetch subscriptions, please try again");
  }
};

export const deleteLinkedProfile = async (
  dataItem: LinkedProfile | null,
  handleSuccessNotification: HandleNotification,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  gridData: LinkedProfile[],
  props: LinkedProfileProps,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData} = props;
  if (dataItem) {
    const response = await remove(`linkedprofiles/DeleteLinkedProfile?email=${getUser()}&profileId=${dataItem.id}`);
    if (response) {
      checkForNewLinkedProfile(gridData, {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData});
      handleSuccessNotification(true, `The ${dataItem.longName} profile was successfully deleted`);
    } else {
      handleErrorNotification(true, `Couldn't delete the ${dataItem.longName} profile, please try again`);
    }
  }
  setIsModalOpen(false);
};

export const reorderDataEnd = async (
  activeItem: StaticOrUserProfile | null,
  droppedItemIndex: number | undefined,
  handleSuccessNotification: HandleNotification,
  gridData: StaticOrUserProfile[],
  profileType: number,
  props: CommonProps,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength} = props;
  if (activeItem && activeItem.positionIndex && droppedItemIndex) {
    const response = await reorder(
      {firstValue: activeItem.positionIndex, secondValue: droppedItemIndex},
      `profiles/UpdateProfilePosition?email=${getUser()}&trustId=${getTrust().trustId}&isStatic=${
        activeItem && !activeItem.hasOwnProperty('email') ? true : false
      }&profileTypeId=${profileType}`,
    );
    if (response) {
      checkForNewProfile(
        gridData,
        {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength},
        profileType,
      );
      handleSuccessNotification(true, 'The profiles were reordered successfully');
    } else {
      handleErrorNotification(true, "Couldn't re-order these profiles, please try again");
    }
  } else {
    handleErrorNotification(true, "Couldn't re-order these profiles, please try again");
  }
};

export const reorderLinkedProfilesDataEnd = async (
  activeItem: LinkedProfile | null,
  droppedItemIndex: number | undefined,
  handleSuccessNotification: HandleNotification,
  gridData: LinkedProfile[],
  profileType: number,
  props: LinkedProfileProps,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData} = props;
  if (activeItem && activeItem.positionIndex && droppedItemIndex) {
    const response = await reorder(
      {firstValue: activeItem.positionIndex, secondValue: droppedItemIndex},
      `linkedprofiles/UpdateLinkedProfilePosition?email=${getUser()}&trustId=${getTrust().trustId}`,
    );
    if (response) {
      checkForNewLinkedProfile(gridData, {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData});
      handleSuccessNotification(true, 'The profiles were reordered successfully');
    } else {
      handleErrorNotification(true, "Couldn't re-order these profiles, please try again");
    }
  } else {
    handleErrorNotification(true, "Couldn't re-order these profiles, please try again");
  }
};

export const updateItems = async (
  dataItem: StaticOrUserProfile,
  handleSuccessNotification: HandleNotification,
  gridData: StaticOrUserProfile[],
  profileType: number,
  props: CommonProps,
  isOceansblueUser: boolean,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength} = props;
  const isNew = dataItem.isNew;
  if (!dataItem) {
    handleErrorNotification(true, 'Something went wrong, please try again');
    return;
  }
  const duplicateName = gridData.find((item) => {
    if (
      (item.longName.trim() == dataItem.longName.trim() && item.id !== dataItem.id) ||
      (item.id === dataItem.id && !checkProfileTypes(dataItem, item) && item.longName === dataItem.longName)
    )
      return item;
  });

  if (duplicateName) {
    handleErrorNotification(true, 'A profile with this name already exists');
    return;
  }
  if (!dataItem.longName.trim()) {
    handleErrorNotification(true, 'This profile has no name');
    return;
  }
  if (!dataItem.metricCodes) {
    handleErrorNotification(true, `This profile has no selected ${profileType === 2 ? 'units' : 'columns'}`);
    return;
  }

  if (!dataItem.hasOwnProperty('email') && !dataItem.hasOwnProperty('email') && 'onLoadFlag' in dataItem && dataItem.isNew !== true) {
    try {
      await axios.patch(
        process.env.REACT_APP_APIURL + `profiles/UpdateDefaultStaticProfile`,
        {
          email: getCurrentContextUser() || getUser(),
          id: dataItem.id,
          onLoadFlag: dataItem.onLoadFlag,
          profileType,
          trustId: getTrust().trustId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );
    } catch (e) {
      console.log(e);
      handleErrorNotification(true, `Couldn't update the ${dataItem.longName} profile, please try again`);
      return;
    }
  }
  if (!dataItem.hasOwnProperty('email') && !dataItem.hasOwnProperty('email') && !isOceansblueUser) {
    handleErrorNotification(true, 'Can"t make changes to this trust profile');
    return;
  }
  const response = await update<StaticOrUserProfile>(
    'email' in dataItem
      ? {...dataItem, email: getCurrentContextUser() || getUser(), trustId: getTrust().trustId}
      : {...dataItem, trustId: getTrust().trustId},
    `profiles/Create${!dataItem.hasOwnProperty('email') ? 'Static' : ''}Profile${
      !dataItem.hasOwnProperty('email') ? '?email=' + getCurrentContextUser() || getUser() : ''
    }`,
    `profiles/Update${!dataItem.hasOwnProperty('email') ? 'Static' : ''}Profile?email=${getCurrentContextUser() || getUser()}`,
  );
  if (response) {
    checkForNewProfile(
      gridData,
      {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData, setProfilesLength},
      profileType,
      );
    if (getCurrentContextUser() === null) {
        sessionStorage.setItem('contextUser', JSON.stringify(getUser()));
    }
    if (!dataItem.hasOwnProperty('email') && getCurrentContextUser() !== getUser() && isNew) {
      handleErrorNotification(true, `Couldn't create the ${dataItem.longName} profile for a non oceansblue user`);
    }
    handleSuccessNotification(true, `The ${dataItem.longName} profile was ${isNew ? 'created' : 'updated'} successfully`);
  } else {
    handleErrorNotification(true, `Couldn't update the ${dataItem.longName} profile, please try again`);
  }
};

export const updateLinkedProfile = async (
  dataItem: LinkedProfile,
  handleSuccessNotification: HandleNotification,
  gridData: LinkedProfile[],
  props: LinkedProfileProps,
) => {
  const {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData} = props;
  const isNew = dataItem.isNew;
  if (!dataItem) {
    handleErrorNotification(true, 'Something went wrong, please try again');
    return;
  }
  const duplicateName = gridData.find((item) => {
    if (item.longName.trim() == dataItem.longName.trim() && item.id !== dataItem.id) return item;
  });

  if (!dataItem.longName.trim()) {
    handleErrorNotification(true, 'This profile has no name');
    return;
  }
  if (duplicateName) {
    handleErrorNotification(true, 'A linked profile with this name already exists');
    return;
  }
  if (!dataItem.unitProfileId) {
    handleErrorNotification(true, 'A unit profile needs to be selected for the linked profile');
    return;
  }
  if (!dataItem.columnProfileId) {
    handleErrorNotification(true, 'A column profile needs to be selected for the linked profile');
    return;
  }

  const response = await update<LinkedProfile>(
    {...dataItem, trustId: getTrust().trustId},
    `linkedprofiles/CreateLinkedProfile`,
    `linkedprofiles/UpdateLinkedProfile`,
  );
  const duplicatedUnitProfile = gridData.find(
    (profile) => profile.unitProfileId === dataItem.unitProfileId && profile.unitProfileIsStatic === dataItem.unitProfileIsStatic,
  );
  if (duplicatedUnitProfile && !response) {
    handleErrorNotification(true, `There's already a linked profile using this unit profile`);
    return;
  }
  if (response) {
    checkForNewLinkedProfile(gridData, {initialProfileState, setInitialProfileState, handleErrorNotification, setGridData});
    handleSuccessNotification(true, `The ${dataItem.longName} profile was ${isNew ? 'created' : 'updated'} successfully`);
  } else {
    handleErrorNotification(true, `Couldn't update the ${dataItem.longName} profile, please try again`);
  }
};

export const deleteSubscription = async (
  handleErrorNotification: HandleNotification,
  handleSuccessNotification: HandleNotification,
  setGridData: React.Dispatch<React.SetStateAction<Subscription[]>>,
  sentinelSubscriptionId?: number,
) => {
  if (typeof sentinelSubscriptionId === 'undefined') {
    handleErrorNotification(true, 'Something went wrong, please try again');
    return;
  }
  const response: AxiosResponse<Subscription> = await axios.delete(
    process.env.REACT_APP_APIURL +
      `Subscriptions/DeleteSubscription?email=${getUser()}&trustId=${getTrust().trustId}&sentinelSubscriptionId=${sentinelSubscriptionId}`,
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
  if (response.status === 200) {
    handleSuccessNotification(true, 'The subscription has been successfully deleted');
    getSubs(setGridData, handleErrorNotification);
  } else {
    handleErrorNotification(true, 'Something went wrong, please try again');
  }
};

export const updateSubscription = async (handleSuccessNotification: HandleNotification,gridData: Subscription[],props: SubscriptionProps,dataItem?: Subscription,) => {
          const {
                handleErrorNotification, setGridData, orgUnits
          } = props;

          if (!dataItem) {
            handleErrorNotification(true, 'Something went wrong, please try again');
            return;
          }
          const dataItemCopy: Subscription = {...dataItem};
          orgUnits ? (dataItemCopy.orgUnits = orgUnits) : dataItemCopy.orgUnits;

          if (!Array.isArray(dataItemCopy.emails) || dataItemCopy.emails.length < 1) {
            handleErrorNotification(true, 'An email needs to be set for this subscription');
            return;
          }
          if (!Array.isArray(dataItemCopy.orgUnits) || dataItemCopy.orgUnits.length < 1) {
            handleErrorNotification(true, 'No units have been selected for this subscription');
            return;
          }
          if (!dataItemCopy.verbosityLevelId) {
            handleErrorNotification(true, 'No verbosity level has been selected for this subscription');
            return;
          }
          if (!Array.isArray(dataItemCopy.sentinelTypes) || dataItemCopy.sentinelTypes.length < 1) {
            handleErrorNotification(true, 'No event types have been selected for this subscription');
            return;
          }

          if (dataItemCopy.emails.filter((email) => !emailRegex.test(email.email)).length > 0) {
            handleErrorNotification(true, 'One or more of the emails are not in the right format of an email, e.g example@hotmail.com');
            return;
          }
            
          const response = await update<Subscription>(dataItemCopy, `Subscriptions/CreateSubscription`, `Subscriptions/UpdateSubscription`);
          if (response) {
            handleSuccessNotification(true, `You have successfully ${dataItem.isNew ? 'created' : 'updated'} this subscription`);
            getSubs(setGridData, handleErrorNotification);
          } else {
            handleErrorNotification(true, 'Something went wrong, please try again');
          }
    };
