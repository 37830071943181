import * as React from 'react';
import {useState} from 'react';
import axios from 'axios';

import '../login.css';
import {removeUserSession, getUser, getToken} from '../Utils/Common';
import NavMenus from './NavMenu';

function ChangePassword(props: {history: any[]}) {
  const [loading, setLoading] = useState(false);
  const currentpassword = useFormInput('');
  const password = useFormInput('');
  const confirmPassword = useFormInput('');
  const [error, setError] = useState('');

  // handle button click of login form
  const handleChangePassword = () => {
    setError('');
    setLoading(true);

    if (password.value !== confirmPassword.value) {
      setError('Your password do not match');
      setLoading(false);
      return;
    }

    axios
      .post(
        process.env.REACT_APP_APIURL + 'auth/user/changepassword',
        {
          email: getUser(),
          currentPassword: currentpassword.value,
          password: password.value,
          passwordConfirm: confirmPassword.value,
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      )
      .then((response) => {
        setLoading(false);
        removeUserSession();
        props.history.push('/login');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response == null) setError('Something went wrong. Please try again later.');
        else if (error.response.status === 401) {
          removeUserSession();
          setError(error.response.data.detail);
          props.history.push('/login');
        } else if (error.response.status === 500) setError(error.response.data.detail);
        else setError('Something went wrong. Please try again later.');
      });
  };

  return (
    <div className="loginbackground">
      <div>
        <NavMenus history={props.history} selectedTab={0} disabledTabs={[false, false, false]} />
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Change Password</h1>
              <p>Please enter your current and a new password below to reset it</p>
            </div>
            <div className="login-form">
              <div className="control-group">
                <input
                  type="password"
                  {...currentpassword}
                  autoComplete="new-password"
                  id="ChangePassword-currentpassword"
                  placeholder="Current Password"
                />
                <label className="login-field-icon fui-user" htmlFor="ChangePassword-password"></label>
              </div>
              <div className="control-group">
                <input type="password" {...password} autoComplete="new-password" id="ChangePassword-password" placeholder="Password" />
                <label className="login-field-icon fui-user" htmlFor="ChangePassword-password"></label>
              </div>
              <div className="control-group">
                <input
                  type="password"
                  {...confirmPassword}
                  autoComplete="new-password"
                  id="ChangePassword-confirmpassword"
                  placeholder="Confirm Password"
                />
                <label className="login-field-icon fui-user" htmlFor="ChangePassword-confirmpassword"></label>
              </div>
              {error && (
                <>
                  <span style={{color: 'red'}}>{error}</span>
                  <br />
                </>
              )}
              <br />

              <input
                className="btnright"
                type="button"
                value={loading ? 'Changing Password...' : 'Change'}
                onClick={handleChangePassword}
                disabled={loading}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useFormInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: {target: {value: React.SetStateAction<string>}}) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default ChangePassword;
