import {ListItem, ListItemText} from '@material-ui/core';
import React, {useContext, useState, useEffect} from 'react';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import axios from 'axios';

import {updateColumns, isActiveProfile, getProfiles, colsToString} from './util';
import ConfirmModal from '../modals/ConfirmModal';

import {SidebarContext} from '../../context/SidebarContextProvider';
import {GenericObject} from '../grid/util';
import gridColumnsDef from '../../WardGuardianGridColumns.json';
import Actions from './Actions';
import {getToken, getUser} from '../../../Utils/Common';
import {Notification as Notification2} from '../../context/SidebarContextProvider';
import Notification from '../Notification';

export interface ProfileBase extends GenericObject {
  longName: string;
  metricCodes: string;
  positionIndex: number;
  onLoadFlag?: boolean;
  profileType: number;
  trustId?: number;
}

export interface Profile extends ProfileBase {
  email: string;
}

export interface ProfilesResponse {
  profilesStatic: ProfileBase[];
  profiles: Profile[];
}

interface CollapsableListProps {
  isLoading: boolean;
  setDataGridColumns: React.Dispatch<React.SetStateAction<{gridColumns: any[]; gridParentColumns: any[]}>>;
}

export type StaticOrUserProfile = Profile | ProfileBase;

const ColumnsCollapsableList = ({isLoading, setDataGridColumns}: CollapsableListProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<Profile | ProfileBase | null>(null);
  const [profiles, setProfiles] = React.useState<ProfilesResponse | null>(null);
  const [errorNotification, setErrorNotification] = useState<Notification2>({text: '', show: false});
  const [successNotification, setSuccessNotification] = useState<Notification2>({text: '', show: false});
  const {
    handleSetHeader,
    handleSideBar,
    handleProfile,
    handleNewProfile,
    activeProfile: ctxActiveProfile,
    handleActiveProfile,
    handleActiveLinkedProfile,
    activeColumns,
    handleActiveColumns,
    handleIsCreatingNewProfile,
  } = useContext(SidebarContext);
  const isOceansblueUser = getUser().includes('@oceansblue.co.uk');

  const handleErrorNotification = (show: boolean, text?: string) => {
    show && text ? setErrorNotification({text, show}) : setErrorNotification({...errorNotification, show});
  };

  const handleSuccessNotification = (show: boolean, text?: string) => {
    show && text ? setSuccessNotification({text, show}) : setSuccessNotification({...successNotification, show});
  };

  const handleChange = (item: Profile | ProfileBase) => {
    if (ctxActiveProfile && ctxActiveProfile.id === item.id) {
      setOpen(false);
    } else {
      const cols = updateColumns(item.metricCodes, {
        parentColumns: gridColumnsDef.ParentColumns,
        childColumns: gridColumnsDef.Columns,
        textField: 'title',
        valueField: 'group',
        valueSecondField: 'field',
      });

      if (cols.parentColumns.length > 0 && cols.childColumns.length > 0) {
        setDataGridColumns({gridColumns: cols.childColumns, gridParentColumns: cols.parentColumns});
      } else {
        setDataGridColumns({gridColumns: gridColumnsDef.Columns, gridParentColumns: gridColumnsDef.ParentColumns});
      }
      handleActiveProfile(item);
      handleActiveLinkedProfile(undefined);
      setOpen(false);
    }
  };

  const onConfirm = async () => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_APIURL +
          `profiles/DeleteProfile?email=${getUser()}&profileId=${activeItem && activeItem.id}&isStatic=${
            activeItem && !activeItem.hasOwnProperty('email') ? true : false
          }&profileTypeId=1`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );
      if (response.status === 200) {
        handleSuccessNotification(true, `The ${activeItem && activeItem.longName} profile was successfully deleted`);
      } else {
        handleErrorNotification(true, `Couldn't delete the ${activeItem && activeItem.longName} profile, please try again`);
      }
      setIsModalOpen(false);
    } catch (e) {
      console.log(e);
      handleErrorNotification(true, `Couldn't delete the ${activeItem && activeItem.longName} profile, please try again`);
    }
  };

  const addNewProfile = (isStatic: boolean) => {
    handleSideBar(true);
    handleSetHeader('Columns');
    handleProfile(undefined);
    handleIsCreatingNewProfile(true);
    if (!ctxActiveProfile && !activeColumns) {
      const totalString = colsToString(gridColumnsDef.Columns);
      handleActiveColumns({metricCodes: totalString, longName: '', positionIndex: 0, profileType: 1, id: 1});
      handleNewProfile({profile: undefined, isStatic});
    } else {
      handleNewProfile({profile: ctxActiveProfile, isStatic});
    }
  };

  const onEdit = (item: Profile | ProfileBase) => {
    handleSideBar(true);
    handleSetHeader('Columns');
    handleIsCreatingNewProfile(false);
    handleProfile(item);
  };

  const onDelete = (item: Profile | ProfileBase) => {
    setIsModalOpen(true);
    setActiveItem(item);
  };

  useEffect(() => {
    if (open) {
      getProfiles({profileType: 1, handleErrorNotification, handleSetHeader, handleSideBar, setOpen, setProfiles});
    }
  }, [open]);

  return (
    <>
      <span
        style={{position: 'relative'}}
        onBlur={(event: any) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setOpen(false);
          }
        }}>
        <TelButton
          className="buttons-container-button"
          onClick={() => {
            setOpen((open) => !open);
          }}
          disabled={isLoading}
          title="Select columns profile"
          iconClass="fa-solid fa-table-columns">
          Column Profiles
        </TelButton>
        {profiles && open && (
          <span
            style={{
              boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
              position: 'absolute',
              left: '5%',
              top: '35px',
              zIndex: 100,
              borderRadius: '5px',
              backgroundColor: 'white',
              color: 'black',
              maxHeight: '400px',
              overflowY: 'auto',
            }}>
            {profiles && profiles.profiles && profiles.profiles.length > 0 && (
              <>
                <div style={{display: 'flex', margin: '5px 0', alignItems: 'center'}}>
                  <ListItem style={{fontWeight: 600, fontSize: '13px', paddingLeft: '9px'}}>User Profiles</ListItem>
                  <TelButton
                    iconClass="fa-solid fa-user-plus"
                    className="buttons-container-button"
                    onClick={() => addNewProfile(false)}
                    title={`Create a new column user profile using the current columns`}
                    style={{
                      padding: '0 7px',
                      maxHeight: '30px',
                      minHeight: '30px',
                      marginRight: '10px',
                    }}>
                    {''}
                  </TelButton>
                </div>
                {profiles.profiles.map((item) => (
                  <ListItem
                    button
                    selected={isActiveProfile(item, ctxActiveProfile)}
                    style={{
                      minWidth: '250px',
                      width: '100%',
                      padding: `3px 0 3px ${isActiveProfile(item, ctxActiveProfile) ? '5' : '9'}px`,
                      borderLeft: isActiveProfile(item, ctxActiveProfile) ? '4px solid #0080c1' : '',
                    }}
                    key={`user-profile-${item.id}`}
                    onMouseUp={() => handleChange(item)}>
                    <ListItemText primary={item.longName} />
                    <Actions onDelete={() => onDelete(item)} onEdit={() => onEdit(item)} />
                  </ListItem>
                ))}
              </>
            )}
            {profiles && profiles.profilesStatic && profiles.profilesStatic.length > 0 && (
              <>
                <div style={{display: 'flex', margin: '5px 0', alignItems: 'center'}}>
                  <ListItem style={{fontWeight: 600, fontSize: '13px', paddingLeft: '9px'}}>Trust Profiles</ListItem>
                  {isOceansblueUser && (
                    <TelButton
                      title={`Create a new column trust profile using the current columns`}
                      iconClass="fa-solid fa-hospital fa-fw"
                      className="buttons-container-button"
                      onClick={() => addNewProfile(true)}
                      style={{
                        padding: '0 7px',
                        maxHeight: '30px',
                        minHeight: '30px',
                        marginRight: '10px',
                      }}>
                      {''}
                    </TelButton>
                  )}
                </div>
                {profiles.profilesStatic.map((item) => (
                  <ListItem
                    selected={isActiveProfile(item, ctxActiveProfile)}
                    button
                    style={{
                      minWidth: '250px',
                      width: '100%',
                      padding: `3px 0 3px ${isActiveProfile(item, ctxActiveProfile) ? '5' : '9'}px`,
                      borderLeft: isActiveProfile(item, ctxActiveProfile) ? '4px solid #0080c1' : '',
                    }}
                    key={`static-profile-${item.id}`}
                    onMouseUp={() => handleChange(item)}>
                    <ListItemText primary={item.longName} />
                    <Actions onDelete={() => onDelete(item)} onEdit={() => onEdit(item)} isDisbaled={!isOceansblueUser} />
                  </ListItem>
                ))}
              </>
            )}
          </span>
        )}
        <ConfirmModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          text={`Are you sure you want to delete the ${activeItem && activeItem.longName} profile?`}
          onConfirm={onConfirm}
        />
      </span>
      <Notification
        open={successNotification.show}
        onClose={() =>
          setSuccessNotification((successNotification) => {
            return {...successNotification, show: false};
          })
        }
        severity="info"
        text={successNotification.text}
      />
      <Notification
        open={errorNotification.show}
        onClose={() =>
          setErrorNotification((errorNotification) => {
            return {...errorNotification, show: false};
          })
        }
        severity="error"
        text={errorNotification.text}
      />
    </>
  );
};

export default ColumnsCollapsableList;
