import {ListItem, ListItemText} from '@material-ui/core';
import React, {useState} from 'react';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import {KPIState} from '../../WardGuardian';

interface KpiStateCollapsableListProps {
  isLoading: boolean;
  setKpiStateMode: React.Dispatch<React.SetStateAction<KPIState>>;
  kpiStateMode: KPIState;
  handleKpiStateModeChange: (kpiState: KPIState) => void;
}

const KpiStateCollapsableList = ({isLoading, kpiStateMode, setKpiStateMode, handleKpiStateModeChange}: KpiStateCollapsableListProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <span
      style={{position: 'relative'}}
      onBlur={(event: any) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpen(false);
        }
      }}>
      <TelButton
        className="buttons-container-button"
        onClick={() => {
          setOpen((open) => !open);
        }}
        disabled={isLoading}
        title="Set visible columns state filter"
        iconClass={`fa-solid fa-key ${kpiStateMode === 'Failed' ? 'redtick' : kpiStateMode === 'Passed' ? 'greentick' : ''}`}>
        Column States
      </TelButton>
      {open && (
        <span
          style={{
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            position: 'absolute',
            left: '5%',
            top: '35px',
            zIndex: 100,
            borderRadius: '5px',
            backgroundColor: 'white',
            color: 'black',
            maxHeight: '400px',
            overflowY: 'auto',
          }}>
          <ListItem
            button
            selected={kpiStateMode === 'All'}
            style={{minWidth: '250px', width: '100%', paddingLeft: '15px'}}
            onClick={() => {
              setOpen(false);
              setKpiStateMode('All');
              handleKpiStateModeChange('All');
            }}>
            <ListItemText primary={'Set All'} title="Show all rosters" />
          </ListItem>
          <ListItem
            button
            selected={kpiStateMode === 'Passed'}
            style={{minWidth: '250px', width: '100%', paddingLeft: '15px'}}
            onClick={() => {
              setOpen(false);
              setKpiStateMode('Passed');
              handleKpiStateModeChange('Passed');
            }}>
            <ListItemText primary={'Set Pass'} title="Show rosters where all the columns pass" />
          </ListItem>
          <ListItem
            button
            selected={kpiStateMode === 'Failed'}
            style={{minWidth: '250px', width: '100%', paddingLeft: '15px'}}
            onClick={() => {
              setOpen(false);
              setKpiStateMode('Failed');
              handleKpiStateModeChange('Failed');
            }}>
            <ListItemText primary={'Set Fail'} title="Show rosters where all the columns fail" />
          </ListItem>
        </span>
      )}
    </span>
  );
};

export default KpiStateCollapsableList;
