import * as React from 'react';
import {formatNumber} from '@telerik/kendo-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartBar} from '@fortawesome/free-regular-svg-icons';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import {faPlayCircle, faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {CellConfig, MetricMaster} from '../Utils/types';
import {GridColumnMenuWrapper} from '@progress/kendo-react-grid';
import {State} from '@progress/kendo-data-query';
import {KPIFilter} from './WardGuardian';

export function RagCell(props: any) {
  if (props.rowType === 'groupFooter') {
    let groupFooter = GroupFooter(props);
    if (groupFooter !== undefined) {
      return groupFooter;
    }
    return <td></td>;
  }

  //logic for raging a cell

  let style = props.classNmae + ' whitenumcell';

  if (props.dataItem[props.field] < 95) {
    style = props.classNmae + ' rednumcell';
  } else if (props.dataItem[props.field] > 105) {
    style = props.classNmae + ' lightbluenumcell';
  }

  return (
    <td className={style} style={{...props.style, textAlign: 'right'}}>
      {props.cellConfig.format === undefined
        ? props.dataItem[props.field]
        : formatNumber(props.dataItem[props.field], props.cellConfig.format)}
    </td>
  );
}

export function getCompliancyScoreAverage(data: any[], metrics: any[]) {
  let totalCols = metrics.length;
  let totalCount = 0;
  data &&
    data.map((r) => {
      let violationsCount = 0;
      r.rosterViolations &&
        r.rosterViolations.forEach((rv: any) => {
          if (metrics.find((col: any) => col.field === rv.field)) {
            violationsCount++;
          }
        });
      totalCount += totalCols - violationsCount;
    });
  return totalCount;
}

export function ApproverViolationCell(props: any) {
  if (props.rowType === 'groupFooter') {
    let groupFooter = GroupFooter(props);
    if (groupFooter !== undefined) {
      return groupFooter;
    }
    return <td className={props.className} style={props.style}></td>;
  }
  let cssClass = props.className;
  let title = '';
  if (props.dataItem.rosterViolations !== undefined) {
    let violations = props.dataItem.rosterViolations.filter((item: {field: string}) => item.field === props.field);
    if (violations.length > 0) {
      title = violations[0].reason;
      let violationsMed = violations.filter((item: {violationLevel: number}) => item.violationLevel === 1);
      if (violationsMed.length > 0) {
        cssClass += ' orangetextcell';
      }
      let violationsHigh = violations.filter((item: {violationLevel: number}) => item.violationLevel === 2);
      if (violationsHigh.length > 0) {
        cssClass += ' redtextcell';
      }
    }
  }

  let style: React.CSSProperties = {
    textAlign: 'left',
  };
  if (props.cellConfig.textalign === 'right') {
    style = {textAlign: 'right'};
  }

  if (props.field === 'complianceScore') {
    let violationsCount = 0;
    let totalCols = props.metrics.length;
    props.dataItem.rosterViolations &&
      props.dataItem.rosterViolations.forEach((rv: any) => {
        if (props.metrics.find((col: any) => col.field === rv.field)) {
          violationsCount++;
        }
      });

    if (props.rowType === 'groupHeader' && cssClass === 'k-grid-content-sticky') {
      return <td></td>;
    }

    return (
      <td className={cssClass} title={title} style={{...props.style, ...style}} colSpan={props.colSpan}>{`${
        totalCols - violationsCount
      } of ${totalCols}`}</td>
    );
  }

  return (
    <td className={cssClass} title={title} style={{...props.style, ...style}} colSpan={props.colSpan}>
      {props.cellConfig.format === undefined
        ? props.dataItem[props.field]
        : formatNumber(props.dataItem[props.field], props.cellConfig.format)}
    </td>
  );
}

export function WGTickCell(props: any) {
  let icon = <span></span>;
  let value = (props.dataItem[props.field] += '');
  if (value === 'true') {
    icon = <span className="k-icon k-i-close redtick"></span>;
  } else if (value === 'false') {
    icon = <span className="k-icon k-i-check greentick"></span>;
  }
  return (
    <td className={props.className} style={{...props.style, textAlign: 'right'}} colSpan={props.colSpan}>
      {icon}
    </td>
  );
}

export function StandardTickCell(props: any) {
  let icon = <span></span>;
  let value = (props.dataItem[props.field] += '');
  if (value === 'true') {
    icon = <span className="k-icon k-i-check greentick"></span>;
  } else if (value === 'false') {
    icon = <span className="k-icon k-i-close redtick"></span>;
  }
  return (
    <td className={props.className} style={props.style} colSpan={props.colSpan}>
      {icon}
    </td>
  );
}

export function RightAlignCell(props: any) {
  if (props.rowType === 'groupFooter') {
    let groupFooter = GroupFooter(props);
    if (groupFooter !== undefined) {
      return groupFooter;
    }
    return <td colSpan={props.colSpan} className={props.className} style={props.style}></td>;
  }

  return (
    <td colSpan={props.colSpan} style={{...props.style, textAlign: 'right'}} className={props.className}>
      {props.cellConfig.format === undefined
        ? props.dataItem[props.field]
        : formatNumber(props.dataItem[props.field], props.cellConfig.format)}
    </td>
  );
}

export function StaffDetailsLinkCell(props: any) {
  if (props.rowType !== 'groupFooter' && props.rowType !== 'groupHeader') {
    let icon = (
      <TelButton className="staffdetailgridbutton">
        <FontAwesomeIcon icon={faChartBar} onClick={() => props.ClickCallBack(props)} />
      </TelButton>
    );
    return (
      <td className={props.className} style={props.style}>
        {icon}
      </td>
    );
  }
  return <td className={props.className} style={props.style}></td>;
}

export function WardGuardianLinkCell(props: any) {
  if (props.rowType !== 'groupFooter' && props.rowType !== 'groupHeader') {
    let icon = (
      <TelButton className="staffdetailgridbutton">
        <FontAwesomeIcon icon={faPlayCircle} />
      </TelButton>
    );
    return (
      <td colSpan={props.colSpan} className={props.className} style={props.style} onClick={() => props.ClickCallBack(props)}>
        {icon}
      </td>
    );
  }
  return <td></td>;
}

export function WardGuardianEmailLinkCell(props: any) {
  if (props.rowType !== 'groupFooter' && props.rowType !== 'groupHeader') {
    let icon = (
      <TelButton className="staffdetailgridbutton">
        <FontAwesomeIcon icon={faEnvelope} />
      </TelButton>
    );
    return (
      <td className={props.className} colSpan={props.colSpan} style={props.style} onClick={() => props.ClickCallBack(props)}>
        {icon}
      </td>
    );
  }
  return <td></td>;
}

export function TickCell(props: any) {
  let icon = <span></span>;
  let value = (props.dataItem[props.field] += '');
  switch (value) {
    case 'Match':
      icon = <span className="k-icon k-i-check greentick"></span>;
      break;
    case 'NotESR':
      icon = <span className="k-icon k-i-close"></span>;
      break;
    case 'ESRMissMatch':
      icon = <span className="k-icon k-i-close redtick"></span>;
      break;
    case 'RPCMissMatch':
      icon = <span className="k-icon k-i-close orangetick"></span>;
      break;
    case 'Part':
      icon = <span className="k-icon k-i-check greentick"></span>;
      break;
    default:
      icon = <span></span>;
      break;
  }
  return (
    <td colSpan={props.colSpan} className={props.className} style={props.style}>
      {icon}
    </td>
  );
}

export function ToolTipCell(props: any) {
  //add tool tip for today and tomorrow

  return (
    <td
      colSpan={props.colSpan}
      title={
        props.cellConfig !== undefined
          ? props.cellConfig.tooltipfield !== undefined
            ? props.dataItem[props.cellConfig.tooltipfield]
            : ''
          : ''
      }
      className={props.className}
      style={props.style}>
      {props.dataItem[props.field]}
    </td>
  );
}

export function TodayTomorrowCell(props: any) {
  //add tool tip for today and tomorrow

  let className: string = props.className;
  if (className !== undefined && props.cellConfig.colorfield !== undefined) {
    let num = props.dataItem[props.cellConfig.colorfield];
    switch (num) {
      case 1:
        className += ' todaytomorrowdarkblue';
        break;
      case 2:
        className += ' todaytomorrowred';
        break;
      case 3:
        className += ' todaytomorrowgrey';
        break;
      case 4:
        className += ' todaytomorroworange';
        break;
      default:
        className += '';
        break;
    }
  }

  return (
    <td
      colSpan={props.colSpan}
      title={
        props.cellConfig !== undefined
          ? props.cellConfig.tooltipfield !== undefined
            ? props.dataItem[props.cellConfig.tooltipfield]
            : ''
          : ''
      }
      className={className}
      style={props.sytle}>
      {props.dataItem[props.field]}
    </td>
  );
}

export function RowsCountFooterCell(props: any, gridData: any) {
  return (
    <td colSpan={props.colSpan} style={props.style} className={props.className}>
      Rows: {gridData.length}
    </td>
  );
}

function Sum(array: any, key: string) {
  if (key)
    return array.reduce(function (r: any, a: {[x: string]: any}) {
      return r + a[key];
    }, 0);
}

function convertToSeconds(hours: number, minutes: number) {
  return hours * 60 * 60 + minutes * 60;
}

function converthhmmToSeconds(date?: string) {
  if (date) {
    const splitDate = date.includes(':') ? date.split(':') : '';
    const [hours, minutes] = splitDate || [0, 0];
    return convertToSeconds(!isNaN(Number(hours)) ? Number(hours) : 0, !isNaN(Number(minutes)) ? Number(minutes) : 0);
  }
  return 0;
}

export function convertDateToTimestamp(isRawDate: boolean, date?: string) {
  if (date && date.trim() && !date.includes(':')) {
    const [days, month, year] = date.split('-');
    const newDate = isRawDate ? new Date(`${month}/${days}/${year}`).toISOString().split('T')[0] : new Date(`${month}/${days}/${year}`);
    return newDate;
  }
  if (date && date.trim() && date.includes(':')) {
    const dateTime = date.split(' ');
    const [days, month, year] = dateTime[0].trim().split('-');
    const [hours, minutes] = dateTime[1].trim().split(':');
    const newDate = isRawDate
      ? `${new Date(`${month}/${days}/${year}`).toISOString().split('T')[0]} ${hours}:${minutes}`
      : new Date(`${month}/${days}/${year} ${hours}:${minutes}:00`);
    return newDate;
  }

  return 0;
}

function convertOfStrToNumber(dataItem: any, metrics: any[]): number {
  let violationsCount = 0;
  let totalCols = metrics.length;
  dataItem.rosterViolations &&
    dataItem.rosterViolations.forEach((rv: any) => {
      if (metrics.find((col: any) => col.field === rv.field)) {
        violationsCount++;
      }
    });

  const num = totalCols - violationsCount;
  if (!isNaN(Number(num))) return Number(num);
  return 0;
}

export function convertTohhmm(seconds?: number) {
  if (seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const mDisplay = m > 10 ? m : '0' + m;
    return h + ':' + mDisplay;
  }
  return null;
}

export function hhmmSum(array: any, key: string) {
  if (key) {
    const formattedDates = array.map((date: any) => {
      if (key in date && typeof date[key] === 'string') {
        const splitDate = date[key].split(':');
        const [hours, minutes] = splitDate;
        return convertToSeconds(!isNaN(hours) ? Number(hours) : 0, !isNaN(minutes) ? Number(minutes) : 0);
      }
    });
    return formattedDates.reduce((a: number, b: number) => a + b, 0);
  }
}

export function getCompliantRosters(array: any, key: string, isPercentage?: boolean) {
  let compliantRostersCount = 0;
  array.forEach((roster: any) => {
    const violations = roster.rosterViolations && roster.rosterViolations.filter((item: {field: string}) => item.field === key);
    if (violations && violations.length < 1) compliantRostersCount++;
  });

  if (isPercentage) {
    return `${((compliantRostersCount / array.length) * 100).toFixed(1)}%`;
  }
  if (isPercentage === undefined) {
    return compliantRostersCount;
  }
  return `${compliantRostersCount} of ${array ? array.length : 0}`;
}

export function CountsFooterCell(props: any) {
  const dataArray: any[] = Array.from(props.data);
  const count = getCompliantRosters(dataArray, props.field, false);

  return (
    <td
      colSpan={props.colSpan}
      style={{...props.style, textAlign: 'right'}}
      className={`${props.className} footer-total-cell`}
      title={`This is a count of compliant rosters: ${count}`}>
      {count}
    </td>
  );
}

export function PercentagesFooterCell(props: any) {
  const dataArray: any[] = Array.from(props.data);
  const percentage = getCompliantRosters(dataArray, props.field, true);

  return (
    <td
      style={{...props.style, textAlign: 'right'}}
      className={`${props.className} footer-total-cell`}
      title="This is a percentage of compliant rosters">
      {percentage || '0%'}
    </td>
  );
}

export function SumFooterCell(props: any) {
  let metricConfig = props.metricConfig;

  let sum;
  if (props.sortType && props.sortType === 'hhmm') {
    sum = hhmmSum(Array.from(props.data), props.field);
  } else if (props.field === 'sameApprover') {
    sum = getCompliantRosters(props.data, props.field);
  } else {
    sum = Sum(Array.from(props.data), props.field);
  }

  return props.sortType && props.sortType === 'hhmm' ? (
    <td style={{...props.sytle, textAlign: 'right'}} className={`${props.className} footer-total-cell`} title="This is a sum">
      {sum ? convertTohhmm(sum) : ''}
    </td>
  ) : (
    <td style={{...props.sytle, textAlign: 'right'}} className={`${props.className} footer-total-cell`} title="This is a sum">
      {typeof sum === 'number' && !isNaN(sum) ? sum.toFixed(metricConfig === undefined ? 0 : metricConfig.displayRounding) : ''}
    </td>
  );
}

export function AverageFooterCell(props: any) {
  let metricConfig = props.metricConfig;
  let cellConfig = props.cellConfig;
  let dataArray: any[] = Array.from(props.data);
  let sum = Sum(dataArray, props.field);
  let average: string | number = 0;
  if (dataArray !== undefined) {
    let divisor = dataArray.filter((item) => item[props.field] != null).length;
    average = sum / divisor;
    if (cellConfig && cellConfig.exportType && cellConfig.exportType === 'money') {
      average = average
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  return (
    <td
      colSpan={props.colSpan}
      style={{...props.style, textAlign: 'right', color: '#2e75b7'}}
      className={`${props.className} footer-total-cell`}
      title="This is an average">
      {typeof average === 'number' && !isNaN(average)
        ? average.toFixed(metricConfig === undefined ? 1 : metricConfig.displayRounding)
        : typeof average === 'string' && average !== 'NaN'
        ? average
        : ''}
    </td>
  );
}

function GroupFooter(cellProps: any) {
  let cellConfig: CellConfig = cellProps.cellConfig;
  let metricConfig = cellProps.metricConfig;

  if (cellProps.rowType === 'groupFooter') {
    if (cellConfig !== undefined) {
      if (cellConfig.groupcalc !== undefined) {
        if (cellConfig.groupcalc === 'sum') {
          let sum = Sum(cellProps.dataItem.items, cellProps.field!);
          return (
            <td style={{...cellProps.style, textAlign: 'right'}} className={cellProps.className}>
              {typeof sum === 'number' && !isNaN(sum) ? sum.toFixed(metricConfig.displayRounding) : ''}
            </td>
          );
        } else if (cellConfig.groupcalc === 'average') {
          let dataArray: any[] = cellProps.dataItem.items;
          let divisor = dataArray.filter((item) => item[cellProps.field!] != null).length;
          let average = Sum(cellProps.dataItem.items, cellProps.field!) / divisor;
          return (
            <td style={{...cellProps.style, textAlign: 'right'}} className={cellProps.className}>
              {typeof average === 'number' && !isNaN(average) ? average.toFixed(metricConfig.displayRounding) : ''}
            </td>
          );
        } else if (cellConfig.groupcalc === 'othercolaverage') {
          if (cellConfig.groupcalcothercols !== undefined) {
            var otherCols = cellConfig.groupcalcothercols.split(',');
            if (otherCols !== undefined) {
              if (otherCols.length === 2) {
                let firstColSum = Sum(cellProps.dataItem.items, otherCols[0]);
                let secondColSum = Sum(cellProps.dataItem.items, otherCols[1]);
                let percent = (firstColSum / secondColSum) * 100;
                return (
                  <td style={{...cellProps.style, textAlign: 'right'}} className={cellProps.className}>
                    {typeof percent === 'number' && !isNaN(percent) ? percent.toFixed(metricConfig.displayRounding) : ''}
                  </td>
                );
              }
            }
          }
        }
      }
    }
  }
}

export function GroupFooterCell(cellProps: any, defaultRendering: React.ReactElement<HTMLTableCellElement> | null) {
  let groupFooter = GroupFooter(cellProps);
  if (groupFooter !== undefined) {
    return groupFooter;
  }
  return defaultRendering;
}

export const sortArrayOfObjects = (
  arr: any[],
  propertyName: string,
  order: 'asc' | 'desc' = 'asc',
  sortType: 'hhmm' | 'of' | 'date' | 'number' | 'text' | 'bool' = 'hhmm',
  metrics: any[],
) => {
  let sortedArr: any[] = [];
  switch (sortType) {
    case 'date':
      sortedArr = arr.sort((a, b) => {
        if (convertDateToTimestamp(false, a[propertyName]) < convertDateToTimestamp(false, b[propertyName])) {
          return -1;
        }
        if (convertDateToTimestamp(false, a[propertyName]) > convertDateToTimestamp(false, b[propertyName])) {
          return 1;
        }
        return 0;
      });
      break;
    case 'of':
      sortedArr = arr.sort((a, b) => {
        if (convertOfStrToNumber(a, metrics) < convertOfStrToNumber(b, metrics)) {
          return -1;
        }
        if (convertOfStrToNumber(a, metrics) > convertOfStrToNumber(b, metrics)) {
          return 1;
        }
        return 0;
      });
      break;
    case 'hhmm':
      sortedArr = arr.sort((a, b) => {
        if (converthhmmToSeconds(a[propertyName]) < converthhmmToSeconds(b[propertyName])) {
          return -1;
        }
        if (converthhmmToSeconds(a[propertyName]) > converthhmmToSeconds(b[propertyName])) {
          return 1;
        }
        return 0;
      });
      break;
    default:
      sortedArr = arr.sort((a, b) => {
        if (a[propertyName] < b[propertyName]) {
          return -1;
        }
        if (a[propertyName] > b[propertyName]) {
          return 1;
        }
        return 0;
      });
  }

  if (order === 'desc') {
    return sortedArr.reverse();
  }

  return sortedArr;
};

export function filterArrByCol(data: any[], columnFilters: KPIFilter[]): any[] {
  if (!data || data.length < 1) return [];
  let newArr: any[] = [];
  const dataCopy = [...data];

  if (Array.isArray(dataCopy)) {
    dataCopy.map((roster: any) => {
      let allViolations: any[] = [];
      if (roster.rosterViolations && columnFilters) {
        columnFilters.forEach((f) => {
          const violations = roster.rosterViolations && roster.rosterViolations.filter((item: {field: string}) => item.field === f.field);
          if (
            (f.filterType === 'Failed' && violations && violations.length < 1) ||
            (f.filterType === 'Passed' && violations && violations.length > 0)
          ) {
            allViolations.push(violations);
          }
        });
      }

      if (allViolations && allViolations.length < 1) newArr.push(roster);
    });
    return newArr;
  }
  return [];
}

export function sortArrCustom(
  data: any[] | undefined,
  state: State,
  sortType: 'hhmm' | 'of' | 'date' | 'number' | 'text' | 'bool',
  metrics: any[],
  field?: string,
  dir?: 'asc' | 'desc',
): {state: State; data: any[]} {
  let dataCopy = Array.isArray(data) ? [...data] : [];
  if (state && state.sort && field && data) {
    if (dir === 'asc') {
      const newData = sortArrayOfObjects(dataCopy, field, 'asc', sortType, metrics);
      return {data: newData, state: {...state, sort: [{field, dir: 'asc'}]}};
    }
    if (dir === 'desc') {
      const newData = sortArrayOfObjects(dataCopy, field, 'desc', sortType, metrics);
      return {data: newData, state: {...state, sort: [{field, dir: 'desc'}]}};
    }
  }

  return {state: {...state, sort: []}, data: dataCopy};
}

function onHeaderCellClick(cellConfig: CellConfig, cellProps: any, dir?: 'asc' | 'desc') {
  if (cellConfig) {
    cellProps.setGridState((state: State) => ({...state, sort: dir ? [{field: cellConfig.field, dir}] : []}));
  }
}

export function getGroupValue(
  totalsMode: 'totals' | 'counts' | 'percentages',
  metricMaster: MetricMaster[],
  items: any[],
  metrics: MetricMaster[],
  cellConfig: CellConfig,
) {
  let groupValue;
  if (
    totalsMode === 'counts' &&
    cellConfig &&
      metricMaster.find(
          (c) => c && c.targetValue !== null && (c.sourceColumnName === cellConfig.field || c.metricCode === cellConfig.metriccode),
    )
  ) {
    groupValue = getCompliantRosters(items, cellConfig.field || '', false);
  }
  if (
    totalsMode === 'percentages' &&
    cellConfig &&
      metricMaster.find(
          (c) => c && c.targetValue !== null && (c.sourceColumnName === cellConfig.field || c.metricCode === cellConfig.metriccode),
    )
  ) {
    if (cellConfig.field === 'complianceScore') {
      groupValue = `${((getCompliancyScoreAverage(items, metrics) / items.length / metrics.length) * 100).toFixed(1)}%`;
    } else {
      groupValue = getCompliantRosters(items, cellConfig.field || '', true);
    }
  }
  if (cellConfig && cellConfig.groupcalc === 'sum' && totalsMode === 'totals') {
    if (cellConfig.sortType && cellConfig.sortType === 'hhmm') {
      groupValue = hhmmSum(items, cellConfig.field || '');
    } else if (cellConfig.field === 'sameApprover') {
      groupValue = getCompliantRosters(items, cellConfig.field);
    } else {
      groupValue = Sum(items, cellConfig.field!);
    }
  }
  if (cellConfig && cellConfig.groupcalc === 'average' && totalsMode === 'totals') {
    let dataArray: any[] = items;
    let divisor = dataArray.filter((item) => item[cellConfig.field!] != null).length;
    groupValue = Sum(items, cellConfig.field!) / divisor;
    if (cellConfig.field === 'complianceScore') {
      groupValue = `${(getCompliancyScoreAverage(dataArray, metrics) / dataArray.length).toFixed(0)} of ${metrics.length}`;
    }
  }
  return groupValue;
}

export function HeaderCell(cellProps: any) {
  let cellConfig: CellConfig = cellProps.cellConfig;
  let metricMaster: MetricMaster = cellProps.metricMaster;
  const bestOutcome = metricMaster && metricMaster.bestOutcome && metricMaster.bestOutcome.trim();
  const dir =
    cellProps.gridState.sort.length < 1 ||
    (cellConfig && cellProps.gridState.sort.length > 0 && cellProps.gridState.sort[0].field !== cellConfig.field)
      ? 'asc'
      : cellConfig && cellProps.gridState.sort[0].dir === 'asc' && cellProps.gridState.sort[0].field === cellConfig.field
      ? 'desc'
      : undefined;

  let targetText = '';
  if (bestOutcome === 'Highest' && metricMaster.targetValue !== null && metricMaster.unit !== null) {
    targetText = ` The target is >= ${metricMaster.unitBefore ? metricMaster.unit : ''}${metricMaster.targetValue} ${
      !metricMaster.unitBefore ? metricMaster.unit : ''
    }.`;
  }
  if (bestOutcome === 'Lowest' && metricMaster.targetValue !== null && metricMaster.unit !== null) {
    targetText = ` The target is <= ${metricMaster.unitBefore ? metricMaster.unit : ''}${metricMaster.targetValue} ${
      !metricMaster.unitBefore ? metricMaster.unit : ''
    }.`;
  }
  if (bestOutcome === 'Closest' && metricMaster.unit !== null) {
    targetText = ` The target is between ${metricMaster.unitBefore ? metricMaster.unit : ''}${metricMaster.passLowerBound} and ${
      metricMaster.passUpperBound
    } ${!metricMaster.unitBefore ? metricMaster.unit : ''}.`;
  }
  if (bestOutcome === 'Equals' && metricMaster.targetValue !== null && metricMaster.unit !== null) {
    targetText = ` The target = ${metricMaster.unitBefore ? metricMaster.unit : ''}${metricMaster.targetValue} ${
      !metricMaster.unitBefore ? metricMaster.unit : ''
    }.`;
  }
  if (bestOutcome === 'Not Equals') {
    targetText = ` The target is to be ticked.`;
  }
  if (targetText === null) targetText = '';

  let headerTitle = cellProps.title;
  let style = {};
  if (cellConfig !== undefined) {
    if (cellConfig.headercell === 'linkcell') {
      style = {color: '#0F0BE8'}; //blue
    }
    if (cellConfig.headertooltip !== undefined) {
      headerTitle = cellConfig.headertooltip;
    }
  }
  if (metricMaster !== undefined) {
    headerTitle =
      metricMaster.description && metricMaster.policyAction
        ? metricMaster.description + ' ' + metricMaster.policyAction
        : metricMaster.description;
  }
  if (cellProps.filterApplied !== undefined && cellProps.filterApplied !== null && cellProps.filterApplied.filters !== undefined) {
    let isColFiltered: boolean = false;
    cellProps.filterApplied.filters.forEach((filterparent: {filters: any[]}) =>
      filterparent.filters.find((item: {field: any}) => {
        if (item.field === cellProps.field) {
          isColFiltered = true;
        }
      }),
    );

    if (isColFiltered) {
      return (
        <span className="k-cell-inner">
          <a
            style={{
              ...style,
            }}
            onClick={() => onHeaderCellClick(cellConfig, cellProps, dir)}>
            <span title={metricMaster ? headerTitle + targetText : headerTitle}>{cellProps.title}</span>
            <span className="k-icon k-i-filter-sm"></span>
            {cellProps.children}
          </a>
          <GridColumnMenuWrapper {...cellProps.columnMenuWrapperProps}></GridColumnMenuWrapper>
        </span>
      );
    } else {
      return (
        <span className="k-cell-inner">
          <a
            style={{
              ...style,
            }}
            onClick={() => onHeaderCellClick(cellConfig, cellProps, dir)}>
            <span title={metricMaster ? headerTitle + targetText : headerTitle}>{cellProps.title}</span>
            {cellProps.children}
          </a>
          <GridColumnMenuWrapper {...cellProps.columnMenuWrapperProps}></GridColumnMenuWrapper>
        </span>
      );
    }
  } else {
    return (
      <span className="k-cell-inner">
        <a
          style={{
            ...style,
          }}
          onClick={() => onHeaderCellClick(cellConfig, cellProps, dir)}>
          <span title={metricMaster ? headerTitle + targetText : headerTitle}>{cellProps.title}</span>
          {cellProps.children}
        </a>
        <GridColumnMenuWrapper {...cellProps.columnMenuWrapperProps}></GridColumnMenuWrapper>
      </span>
    );
  }
}
