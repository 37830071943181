import * as React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import Login from '../Login';
import {removeUserSession, getToken, getUser} from '../Utils/Common';
import PublicRoute from '../Utils/PublicRoute';
import '../login.css';

function Logout() {
  React.useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    axios
      .post(
        process.env.REACT_APP_APIURL + 'auth/signout/',
        {
          email: getUser(),
          password: '',
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      )
      .catch((error: any) => {
        console.log(error);
      });
    removeUserSession();
    localStorage.clear();
    return;
  }, []);

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Log out</h1>
              <br />
            </div>

            <div className="login-form">
              <div className="control-group">
                <p>
                  You have logged out.
                  <Link className="active" to="/Login">
                    {' '}
                    Click here to Login again
                  </Link>
                  <PublicRoute path="/Login" component={Login} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
