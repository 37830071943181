import React from 'react';
import queryString from 'query-string';
import axios from 'axios';
import Moment from 'moment';
import {Loader} from '@progress/kendo-react-indicators';

export default function PreBuiltReportViewer() {
  const [report, setReport] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);

  const parsed = queryString.parse(window.location.search);

  let arrayReport: string[];
  arrayReport = atob(parsed.ReportCode as string).split(',');
  React.useEffect(() => {
    setLoading(true);
    setErrorState(false);
    axios
      .post(process.env.REACT_APP_APIURL + 'Sentinels/GetReBuildSentinelHtml', {
        runId: arrayReport[0],
        orgunitid: arrayReport[3],
        startdate: arrayReport[1],
        enddate: arrayReport[2],
        trustName: arrayReport[4],
        emailOverride: arrayReport[5],
        personTypeId: arrayReport[7],
        verbosityLevel: arrayReport[8],
      })
      .then((response) => {
        setLoading(false);
        setErrorState(false);
        setReport(response.data[0].messageHtml);
      })
      .catch((error: any) => {
        console.log(error);
        setErrorState(true);
        setLoading(false);
      });
  }, []);

  function createMarkup() {
    console.log(report, 'report');
    return {__html: report};
  }

  return (
    <>
      {!{loading}.loading ? (
        <>
          {!{errorState}.errorState ? (
            <div className="reportsviewer">
              <h1 style={{fontSize: '16px', paddingLeft: '15px'}}>
                {arrayReport![6] + ' | Roster '} <strong> {Moment(arrayReport![1]).format('DD-MM-YY')} </strong> {' to '}{' '}
                <strong> {Moment(arrayReport![2]).format('DD-MM-YY')}</strong>
              </h1>
              <div className="reportview" dangerouslySetInnerHTML={createMarkup()} />
            </div>
          ) : (
            <div className="loginbackground">
              <div className="loginbody">
                <div className="login">
                  <div className="login-screen">
                    <div className="app-title">
                      <h1 className="h1" style={{color: 'red'}}>
                        {' '}
                        <strong> Error </strong>{' '}
                      </h1>
                      <br />
                    </div>

                    <div className="login-form">
                      <div className="control-group">
                        <p>
                          <span style={{fontSize: '16px', color: 'red'}}>Sorry your report could not be loaded at this time.</span>
                          <br />
                          <br />
                          Please click <a href="https://www.oceansbluecentral.com">here</a> to login.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader
          size="medium"
          type="infinite-spinner"
          style={{
            position: 'relative',
            width: '200px',
            top: '30%',
            left: '50%',
          }}
        />
      )}
    </>
  );
}
