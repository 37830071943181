import {ListItem, ListItemText} from '@material-ui/core';
import React, {useState} from 'react';
import {Button as TelButton} from '@progress/kendo-react-buttons';

interface TotalsCollapsableListProps {
  isLoading: boolean;
  setTotalsMode: React.Dispatch<React.SetStateAction<'totals' | 'counts' | 'percentages'>>;
  totalsMode: 'totals' | 'counts' | 'percentages';
}

const TotalsCollapsableList = ({isLoading, setTotalsMode, totalsMode}: TotalsCollapsableListProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <span
      style={{position: 'relative'}}
      onBlur={(event: any) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpen(false);
        }
      }}>
      <TelButton
        className="buttons-container-button"
        onClick={() => {
          setOpen((open) => !open);
        }}
        disabled={isLoading}
        title="Select total row format"
        iconClass="fa-solid fa-percent">
        Totals
      </TelButton>
      {open && (
        <span
          style={{
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            position: 'absolute',
            left: '5%',
            top: '35px',
            zIndex: 100,
            borderRadius: '5px',
            backgroundColor: 'white',
            color: 'black',
            maxHeight: '400px',
            overflowY: 'auto',
          }}>
          <ListItem
            button
            selected={totalsMode === 'totals'}
            style={{minWidth: '250px', width: '100%', paddingLeft: '15px'}}
            onClick={() => {
              setOpen(false);
              setTotalsMode('totals');
            }}>
            <ListItemText primary={'Sums and Averages'} title="Show column averages and sums" />
          </ListItem>
          <ListItem
            button
            selected={totalsMode === 'counts'}
            style={{minWidth: '250px', width: '100%', paddingLeft: '15px'}}
            onClick={() => {
              setOpen(false);
              setTotalsMode('counts');
            }}>
            <ListItemText primary={'Counts'} title="Show count of compliant rosters" />
          </ListItem>
          <ListItem
            button
            selected={totalsMode === 'percentages'}
            style={{minWidth: '250px', width: '100%', paddingLeft: '15px'}}
            onClick={() => {
              setOpen(false);
              setTotalsMode('percentages');
            }}>
            <ListItemText primary={'Percentages'} title="Show percentage of compliant rosters" />
          </ListItem>
        </span>
      )}
    </span>
  );
};

export default TotalsCollapsableList;
