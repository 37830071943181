import React, {useEffect} from 'react';
import axios, {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import {getToken, getTrust} from '../Utils/Common';

import NeTabGrid from './common/grid/NewTabGrid';
import {loadingPanel} from './WardGuardian';

interface ContractInfo {
  personId: string;
  surname: string;
  forenames: string;
  band: string;
  hoursaccountId: string;
  pwcValidFrom: string;
  pwcValidTo: string;
  poValidFrom: string;
  poValidTo: string;
  pwcValidFromDate: string;
  pwcValidToDate: string;
  poValidFromDate: string;
  poValidToDate: string;
  contractedTimeFormatted: string;
  contractedTime: number;
  selectType: string;
  wTDOptOutFormatted: string;
}

interface NetHoursInfo {
    groupDateFormatted: string;
    rosterEndDateFormatted: string;
    orgUnitName: string;
    personId: string;
    surname: string;
    forenames: string;
    band: string;
    assignmentNumber: string;
    contractedTimeFormatted: string;
    contractedTimeThisRosterFormatted: string;
    contractHoursNotUsedThisRosterFormatted: string;
    hoursThisRosterFormatted: string;
    allHoursFormatted: string;
    valueTypeDescription: string;
}

export default function PersonContractView() {
  const [loading, setLoading] = React.useState(false);
  const [contractInfo, setContractInfo] = React.useState<ContractInfo[]>();
    const [netHoursInfo, setNetHoursInfo] = React.useState<NetHoursInfo[]>();
    const { personId, contractedHours} = useParams<{personId: string; contractedHours: string}>();

  const getContractInfo = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse<ContractInfo[]> = await axios.get(
        process.env.REACT_APP_APIURL +
          `PersonContracts/${
            Number(personId) > 0 ? `GetPersonContract?personId=${personId}` : `GetContracts?contractedHours=${contractedHours}`
          }&trustId=${getTrust().trustId}`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        },
      );
      if (response.status === 200) {
        setContractInfo(response.data);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    };

    const getNetHoursInfo = async () => {
        setLoading(true);
        try {
            const response: AxiosResponse<NetHoursInfo[]> = await axios.get(
                process.env.REACT_APP_APIURL + `PersonNetHours/GetPersonNetHours?personId=${personId}&trustId=${getTrust().trustId}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + getToken(),
                    },
                },
            );
            if (response.status === 200) {
                setNetHoursInfo(response.data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };


  useEffect(() => {
      getContractInfo();
      getNetHoursInfo();
  }, []);

  return (
    <div style={{padding: '20px 35px'}}>
      {loading && loadingPanel}
      {!loading && !contractInfo && <div>Sorry something went wrong, please try to refresh the page.</div>}

      {Array.isArray(contractInfo) && contractInfo.length > 0 && (
        <>
          {Number(personId) > 0 ? (
            <>
              <h1
                style={{
                  fontSize: '16px',
                  paddingBottom: '10px',
                }}>{`Person Contracts for ${contractInfo[0].forenames} ${contractInfo[0].surname}`}</h1>
              <p style={{fontFamily: 'Helvetica', paddingLeft: '5px'}}>
                In the table below we're showing a breakdown of contracted hours for the selected staff member.
              </p>
            </>
          ) : (
            <>
              <h1
                style={{
                  fontSize: '16px',
                  paddingBottom: '10px',
                }}>{`Person Contracts for staff with more than ${(Number(contractedHours) / 60).toFixed(2)} contracted hours`}</h1>
              <p style={{fontFamily: 'Helvetica', paddingLeft: '5px'}}>
                {`In the table below we're showing all the staff members that have more than ${(Number(contractedHours) / 60).toFixed(
                  2,
                )} contracted hours.`}
              </p>
            </>
          )}
          <NeTabGrid>
            <tr>
              <th className="topheader">Staff</th>
              <th className="topheader">Band</th>
              <th className="topheader">Person Work Contract Valid From</th>
              <th className="topheader">Person Work Contract Valid To</th>
              <th className="topheader">Post Valid From</th>
              <th className="topheader">Post Valid To</th>
              <th className="topheader">Contracted Time</th>
              <th className="topheader">Select Type</th>
              <th className="topheader">WTD Opt Out</th>
            </tr>
            {contractInfo.map((con) => (
              <tr>
                <td className="TextCol" style={{textAlign: 'left'}}>
                  {Number(personId) > 0 ? (
                    <>{`${con.forenames} ${con.surname}`}</>
                  ) : (
                    <a
                      className="window-open-link"
                      onClick={() => {
                        window.open(`PersonContractView/${con.personId}/0`);
                      }}>{`${con.forenames} ${con.surname}`}</a>
                  )}
                </td>
                <td className="TextCol" style={{textAlign: 'left'}}>
                  {con.band}
                </td>
                <td className="TextCol">{con.pwcValidFromDate}</td>
                <td className="TextCol">{con.pwcValidToDate}</td>
                <td className="TextCol">{con.poValidFromDate}</td>
                <td className="TextCol">{con.poValidToDate}</td>
                <td className="TextCol">{con.contractedTimeFormatted}</td>
                <td className="TextCol">{con.selectType}</td>
                <td className="TextCol">{con.wTDOptOutFormatted}</td>
              </tr>
            ))}
          </NeTabGrid>
        </>
          )}
      <br/>
        <br />
      {loading && loadingPanel}
          {!loading && !netHoursInfo && <div>Sorry something went wrong, please try to refresh the page.</div>}

          {Array.isArray(netHoursInfo) && netHoursInfo.length > 0 && (
        <>
            <h1
                style={{
                    fontSize: '16px',
                    paddingBottom: '10px',
                      }}>{`Net Hours for ${netHoursInfo[0].forenames} ${netHoursInfo[0].surname}`}</h1>
            <p style={{ fontFamily: 'Helvetica', paddingLeft: '5px' }}>
                In the table below we're showing a breakdown of net hours for the selected staff member.
            </p>
          <NeTabGrid>
            <tr>
              <th className="topheader">Staff</th>
              <th className="topheader">Band</th>
              <th className="topheader">Assignment Number</th>
              <th className="topheader">Location</th>
              <th className="topheader">Roster Start Date</th>
              <th className="topheader">Roster End Date</th>
              <th className="topheader">Contracted Hours</th>
              <th className="topheader">Contracted Hours This Roster</th>
              <th className="topheader">Contracted Hours Not Used This Roster</th>
              <th className="topheader">Hours This Roster</th>
              <th className="topheader">Balance</th>
              <th className="topheader">Value Type</th>
            </tr>
            {netHoursInfo.map((con) => (
              <tr>
                <td className="TextCol" style={{textAlign: 'left'}}>
                    {`${con.forenames} ${con.surname}`}
                </td>
                <td className="TextCol" style={{textAlign: 'left'}}>
                  {con.band}
                </td>
                <td className="TextCol">{con.assignmentNumber}</td>
                <td className="TextCol">{con.orgUnitName}</td>
                <td className="TextCol">{con.groupDateFormatted}</td>
                <td className="TextCol">{con.rosterEndDateFormatted}</td>
                <td className="TextCol">{con.contractedTimeFormatted}</td>
                <td className="TextCol">{con.contractedTimeThisRosterFormatted}</td>
                <td className="TextCol">{con.contractHoursNotUsedThisRosterFormatted}</td>
                <td className="TextCol">{con.hoursThisRosterFormatted}</td>
                <td className="TextCol">{con.allHoursFormatted}</td>
                <td className="TextCol">{con.valueTypeDescription}</td>
              </tr>
            ))}
          </NeTabGrid>
        </>
      )}
    </div>
  );
}
