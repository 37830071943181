import * as React from 'react';
import {useState} from 'react';
import {DatePicker, DatePickerChangeEvent} from '@progress/kendo-react-dateinputs';
import LocationsTreeView from './LocationsTreeView';
import {SearchLocation} from '../Utils/types';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faRedo, faFileExcel, faCogs} from '@fortawesome/free-solid-svg-icons';

interface FiltersProps {
  history: any[];
  filtersChangeCallBack: (startDate: Date, endDate: Date, searchLocations: SearchLocation) => void;
  redoCallBack: () => void;
  exportExcelCallBack: () => void;
  advancedCallBack: () => void;
  loading: boolean;
  rowCount: number;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

function StaffAuditFiltersView(props: FiltersProps) {
  //set the date the end of the current month by default
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  let sl: SearchLocation = {isDirectorate: true, locations: '', locationNames: 'Select Ward/Unit'};
  const [searchLocations, setSearchLocations] = useState(sl);
  const [windowVisible, setWindowVisible] = useState(false);
  const [currentSearch, setCurrentSearch] = React.useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
    searchLocations: SearchLocation | undefined;
  }>();
  const [goButtonClass, setGoButtonClass] = React.useState('searchbutton');

  function CheckFilterChange(change: boolean | undefined) {
    if (currentSearch !== undefined) {
      if (change) {
        setGoButtonClass('searchbuttonupdate');
      } else {
        setGoButtonClass('searchbutton');
      }
    } else {
      setGoButtonClass('searchbuttonupdate');
    }
  }

  const handleFilters = () => {
    props.filtersChangeCallBack(startDate!, endDate!, searchLocations);
    setCurrentSearch({startDate, endDate, searchLocations});
    setGoButtonClass('searchbutton');
  };

  const handleExportToExcel = () => {
    props.exportExcelCallBack();
  };

  const handleAdvanced = () => {
    props.advancedCallBack();
  };

  const handleRestoreDefaults = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    props.redoCallBack();
  };

  const toggleDialog = () => {
    if ({windowVisible}.windowVisible) {
      setWindowVisible(!{windowVisible}.windowVisible);
    } else {
      setWindowVisible(!{windowVisible}.windowVisible);
    }
  };

  const handleStartDateChange = (event: DatePickerChangeEvent) => {
    setStartDate(event.target.value as Date);
    CheckFilterChange(currentSearch === undefined ? undefined : currentSearch.startDate !== event.target.value);
  };

  const handleEndDateChange = (event: DatePickerChangeEvent) => {
    setEndDate(event.target.value as Date);
    CheckFilterChange(currentSearch === undefined ? undefined : currentSearch.endDate !== event.target.value);
  };

  const handleSelectedLocation = (event: SearchLocation) => {
    setSearchLocations(event);
    CheckFilterChange(currentSearch === undefined ? undefined : currentSearch.searchLocations !== event);
  };

  function Truncate(str: string, n: number) {
    if (str.length === 0) {
      return 'Select Location';
    }
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  return (
    <div className="filtersview">
      <div className="row firstfilterrow">
        <div className="col-lg-6">
          <label htmlFor="Locations" className="inputlables">
            Ward/Unit
          </label>
          <TelButton id="Locations" className="wardsearchbutton" title={searchLocations.locationNames} onClick={toggleDialog}>
            {Truncate(searchLocations.locationNames, 55)}
          </TelButton>

          <LocationsTreeView
            history={props.history}
            selectedLocationsCallBack={handleSelectedLocation}
            windowVisible={windowVisible}
            closeButtonCallBack={toggleDialog}
            healthRoster={false}
          />
        </div>
        <div className="col-lg-2">
          <label htmlFor="startDate" className="inputlables">
            Start Date
          </label>
          <DatePicker
            id="startDate"
            format={'dd-MM-yyyy'}
            onChange={handleStartDateChange}
            value={startDate === undefined ? new Date(props.defaultStartDate) : startDate}
          />
        </div>
        <div className="col-lg-2">
          <label htmlFor="endDate" className="inputlables">
            End Date
          </label>
          <DatePicker
            id="endDate"
            format={'dd-MM-yyyy'}
            onChange={handleEndDateChange}
            value={endDate === undefined ? new Date(props.defaultEndDate) : endDate}
          />
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="row secondfilterrow">
        <div className="col-lg-4">
          <TelButton className="redoButton" onClick={handleAdvanced} disabled={props.loading} title="Advanced">
            <FontAwesomeIcon icon={faCogs} />
          </TelButton>
          <TelButton className="redoButton" onClick={handleExportToExcel} disabled={props.loading} title="Export to Excel">
            <FontAwesomeIcon icon={faFileExcel} />
          </TelButton>
          <TelButton className="redoButton" onClick={handleRestoreDefaults} disabled={props.loading} title="Restore Defaults">
            <FontAwesomeIcon icon={faRedo} />
          </TelButton>
          <span className="rowcount">Rows: {props.rowCount}</span>
        </div>
        <div className="col-lg-2"></div>
        <div className="col-lg-2"></div>
        <div className="col-lg-4">
          <div className="floatright">
            <TelButton className={goButtonClass} onClick={handleFilters} disabled={props.loading} title="Go">
              <FontAwesomeIcon icon={faSearch} />
            </TelButton>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

const useFormInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: {target: {value: React.SetStateAction<string>}}) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default StaffAuditFiltersView;
