import * as React from 'react';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import axios from 'axios';
import { removeUserSession, getToken, getTrust } from '../Utils/Common';
import { Loader } from "@progress/kendo-react-indicators";
import gridColumnsDef from './StaffAllocationsGridColumns.json'; //defined the columns that are included and their default visability
import { SortDescriptor, process as gridprocess, State } from '@progress/kendo-data-query';
import '../custom.css';
import StaffAllocationsDataStructure from './StaffAllocationsDataStructure.json'
import { StandardTickCell } from './GridCellTypes'
import queryString  from 'query-string';
import {
    Chart,
    ChartSeries,
    ChartLegend,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTooltip,
    AxisLabelClickEvent,
    SeriesClickEvent
} from '@progress/kendo-react-charts';
import { TrendData, TrendDataNew, ShiftData } from '../Utils/types'
import  Moment  from 'moment';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import 'hammerjs';

export default function StaffDetailsViewer(props: { history: any[]; }) {

    let contractHoursSort: SortDescriptor[] = [{ field: "started", dir: "desc" }]
    const parsed = queryString.parse(window.location.search);

    const dataState: State = {
        sort: contractHoursSort,
        skip: 0,
        take: 60
    };


    const [allocationData, setAllocationData] = React.useState(StaffAllocationsDataStructure)
    const [trendData, setTrendData] = React.useState <TrendData[]>([])
    const [trendNewData, setTrendNewData] = React.useState <TrendDataNew[]>([])
    const [shiftData, setShiftData] = React.useState<ShiftData[]>([])
    const [gridState, setGridState] = React.useState(dataState)
    const [allocationLoading, setAllocationLoading] = React.useState(false)
    const [trendLoading, setTrendLoading] = React.useState(false)
    const [trendNewLoading, setTrendNewLoading] = React.useState(false)
    const [shiftLoading, setShiftLoading] = React.useState(false)
    const [gridColumns, setGridColumns] = React.useState(gridColumnsDef.Columns)
    const [weeksArray, setWeeksArray] = React.useState([1])
    const [shiftsDate, setShiftsDate] = React.useState('')

    let processedData = gridprocess(allocationData, gridState)

    //this is the main method for getting the data for the grid
    const loadAllocationGrid = (staffId: any) => {
        setAllocationLoading(true)
        const token = getToken();
        if (!token) {
            return;
        }

        axios.post(process.env.REACT_APP_APIURL + 'StaffAllocations/GetStaffAllocations?staffId=' + staffId, null,
            {
                headers:
                {
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                setAllocationLoading(false)
                setAllocationData(response.data)
            }).catch((error: any) => {
                if (error.response.status === 401) {
                    removeUserSession();
                    props.history.push('/login');
                }
                console.log(error)
                setAllocationLoading(false)
            });
    }

    const loadTrendNewData = (staffId: any, endDate: any, scanWeek: any) => {
        setTrendNewLoading(true)
        const token = getToken();
        if (!token) {
            return;
        }
        axios.post(process.env.REACT_APP_APIURL + 'StaffTrendNew/GetStaffTrendNew',
            {
                "enddate": Moment(endDate).format('yyyy-MM-DD'),
                "staffid": staffId,
                "scanweek": scanWeek === 'null' ? 10 : scanWeek
            },
            {
                headers:
                {
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                setTrendNewLoading(false)
                setTrendNewData(response.data)
            }).catch((error: any) => {
                if (error.response.status === 401) {
                    removeUserSession();
                    props.history.push('/login');
                }
                console.log(error)
                setTrendNewLoading(false)
            });
    }

    const loadTrendData = (staffId:any, endDate:any, scanWeek:any) => {
        setTrendLoading(true)
        const token = getToken();
        if (!token) {
            return;
        }

        axios.post(process.env.REACT_APP_APIURL + 'StaffTrend/GetStaffTrend', 
            {
                "enddate": Moment(endDate).format('yyyy-MM-DD'),
                "staffid": staffId,
                "scanweek": scanWeek === 'null' ? 10 : scanWeek
            },
            {
                headers:
                {
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                setTrendLoading(false)
                setTrendData(response.data)
            }).catch((error: any) => {
                if (error.response.status === 401) {
                    removeUserSession();
                    props.history.push('/login');
                }
                console.log(error)
                setTrendLoading(false)
            });
    }

    const loadShiftsData = (staffId: any, startDate: any) => {
        setShiftLoading(true)
        const token = getToken();
        if (!token) {
            return;
        }
        let tempStartDate = startDate
        let shiftsDate = new Date(startDate.valueOf())
        let endDate = new Date(startDate.valueOf())
        setShiftsDate(Moment(shiftsDate).format('DD MMMM yyyy'))
        axios.post(process.env.REACT_APP_APIURL + 'AuditShifts/GetAuditShifts',
            {
                staffId: staffId,
                startDate: Moment(tempStartDate.setDate(tempStartDate.getDate()-7)).format('yyyy-MM-DD'),
                enddate: Moment(endDate.setDate(endDate.getDate() + 14)).format('yyyy-MM-DD'),
                trust: getTrust().trustName,
                padShifts: true
            },
            {
                headers:
                {
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                setShiftLoading(false)
                setShiftData(response.data)
                console.log(response.data)
            }).catch((error: any) => {
                if (error.response.status === 401) {
                    removeUserSession();
                    props.history.push('/login');
                }
                console.log(error)
                setShiftLoading(false)
            });
    }

    React.useEffect(() => {
        document.title = parsed.staffname === undefined? "Staff Member": '' + parsed.staffname
        setupWeeksDDl(parsed.scanWeeks)
        setAllocationLoading(false)
        setTrendLoading(false)
        setTrendNewLoading(false)
        loadAllocationGrid(parsed.staffId)
        loadTrendData(parsed.staffId, parsed.endDate, parsed.scanWeeks)
        loadTrendNewData(parsed.staffId, parsed.endDate, parsed.scanWeeks)
        loadShiftsData(parsed.staffId, new Date())
    }, []);

    const setupWeeksDDl = (week: any) => {
        const range = (start:number, stop:number, step:number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

        var foo = range(1, week, 1)
            setWeeksArray(foo)
    }

    const handleWeeksDDLChange = (event: ComboBoxChangeEvent) => {
        console.log(event.target.value)
        loadTrendData(parsed.staffId, parsed.endDate, event.target.value)
        loadTrendNewData(parsed.staffId, parsed.endDate, event.target.value)
    }

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setGridState(event.dataState);
    }

    //#region create grid columns

    const GetCellHandler = (customcelltype: string) => {

        if (customcelltype === 'tick') {
            return StandardTickCell;
        }
        return undefined;
    }


    const createCell = (column: {
        show: any;
        title: string | undefined;
        field: string | undefined;
        filter: any | undefined;
        width?: string | undefined;
        customcelltype?: string | undefined;
        format?: string | undefined;

    }, idx: string | number | undefined): any => {
        if (column.show) {
            return < Column
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                width={column.width}
                format={column.format}
                cell={GetCellHandler(column.customcelltype!)}
            />
        }
    }
    //#endregion

    //<ChartSeriesItemTooltip format="Weekly Hours For: {0}" />
    //                        <ChartSeriesItemTooltip format="Running Total: {0}" />

    const handleSeriesClick = (event: SeriesClickEvent) => {
        loadShiftsData(parsed.staffId, new Date(event.category))
    }

    const handleAxisLabelClick = (event: AxisLabelClickEvent) => {
        console.log(event)
    }

    const rosterHoursSeriesLabels = {
        visible: true, // Note that visible defaults to false
        padding: 1,
        font: 'bold 10px Arial, sans-serif',
        format: 'n2'
    };

    const weeklyShiftHoursSeriesLabels = {
        visible: true, // Note that visible defaults to false
        padding: 1,
        font: 'bold 10px Arial, sans-serif',
        format: 'n2',
        color:"#27B927"
    };

    const shiftDataSeriesLabels = {
        visible: true, // Note that visible defaults to false
        padding: 1,
        font: 'bold 10px Arial, sans-serif',
        format: 'n2',
        color: "#27B927"
    };

    return (
        <div className="shiftdetailsviewersurround">
            <div className="shiftdetailsviewerheadingdiv">
                <div className="row">
                    <div className="col-lg-8">
                        <h2>Weekly Shift Contract Hours - Click on weekly hours to refresh the Mini Shift Viewer below</h2>
                    </div>
                    <div className="col-lg-4">
                        Select how many weeks of history to view: <ComboBox data={weeksArray} defaultValue={parsed.scanWeeks} onChange={handleWeeksDDLChange} allowCustom={true} />
                    </div>
                </div>
            </div>
            
            <div className="shiftdetailsviewerdiv">
                <Chart pannable={{ lock: 'y' }} zoomable={{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }} onAxisLabelClick={handleAxisLabelClick} onSeriesClick={handleSeriesClick} >
                    <ChartTooltip shared={true} format="{0}" />
                    <ChartLegend position="bottom" orientation="horizontal" />
                   
                    <ChartSeries>
                        <ChartSeriesItem name="Weekly Shift Hours" color="#27B927" type="line" data={trendData} field="shifthours" labels={weeklyShiftHoursSeriesLabels} markers={{ border: { color:"red" } }} categoryField="scanWeek" >
                        </ChartSeriesItem>
                        <ChartSeriesItem name="Running Total Hours" color="#BBBBEE" type="bar" data={trendData} field="runTotHours" categoryField="scanWeek" >
                        </ChartSeriesItem >
                        <ChartSeriesItem name="Contract Hours" color="#9d0208" type="line" data={trendData} field="contractHours" categoryField="scanWeek" width={0} markers={{ visible: false}}>
                        </ChartSeriesItem >
                        <ChartSeriesItem name="Roster Hours" color="black" type="line" data={trendNewData} field="shifthours" labels={rosterHoursSeriesLabels} markers={{ type: 'triangle', rotation:270 }} categoryField="rosterStartDate" width={0} >
                        </ChartSeriesItem >
                    </ChartSeries>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem type="date" labels={{ format: 'dd MMM yyyy', step:30 }} ></ChartCategoryAxisItem >
                    </ChartCategoryAxis>
                </Chart>
            </div>
            <div className="shiftdetailsviewerheadingdiv">
                <h2>Mini Shift Viewer - Centered on week starting {shiftsDate}</h2>
            </div>
            <div className="shiftdetailsviewerdiv">
                <Chart style={{ height: 200 }} >
                    <ChartLegend position="top" orientation="vertical" />
                    <ChartSeries>
                        <ChartSeriesItem type="column" data={shiftData} field="shiftHours" categoryField="shiftDate" colorField="shiftColour" labels={shiftDataSeriesLabels} >
                        </ChartSeriesItem>
                    </ChartSeries>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem type="date"  labels={{ format: 'dd MMM yyyy' }} ></ChartCategoryAxisItem >
                    </ChartCategoryAxis>
                </Chart>
            </div>
            <div className="shiftdetailsviewerheadingdiv">
                <h2>Contract Time Records - Are these hours and data changes correct?</h2>
            </div>
            <div className="shiftdetailsviewerdiv">
                {!{ allocationLoading }.allocationLoading ? (
                    <div>
                            <Grid
                                style={{ fontFamily: 'Roboto",sans-serif'}}
                                rowHeight={30}
                                data={processedData}
                                total={processedData.total}
                                resizable={true}
                                reorderable={true}
                                sortable={true}
                                {...gridState}
                                onDataStateChange={dataStateChange}
                            >
                            {gridColumns.filter((items: { show: any }) => items.show === true).map(createCell)}
                            </Grid>
                    </div>
                ) : (
                        <Loader
                            size="medium"
                            type='infinite-spinner'
                            style={{
                                position: "relative",
                                width: "200px",
                                top: "30%",
                                left: "50%"
                            }}
                        />
                    )}
            </div>
        </div>
    );
}
