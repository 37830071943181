import React, {useState, useEffect, ChangeEvent, useContext} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import {ButtonGroup, Button} from '@material-ui/core';
import {Button as TelButton} from '@progress/kendo-react-buttons';
import {SidebarContext} from '../context/SidebarContextProvider';
import {transformColumnsToTreeview, getLocations, getAllLocationsString, colsToString} from './collapsable-list/util';
import gridColumnsDef from '../../components/WardGuardianGridColumns.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 700,
    },
    btn: {
      background: '#0080c1',
      color: 'white',
      textTransform: 'unset',
      fontSize: '12px',
      marginLeft: '5px',
      '&:hover': {
        background: '#0080c1',
        opacity: 0.9,
      },
    },
  }),
);

export type TreeNode = {
  value: string | number;
  text: string;
  checked: boolean;
  items?: TreeNode[];
};

type TreeviewProps = {
  nodes: TreeNode[];
  updatedColumns: TreeNode[];
  setUpdatedColumns: React.Dispatch<React.SetStateAction<TreeNode[]>>;
  searchResults: TreeNode[];
  setSearchResults: React.Dispatch<React.SetStateAction<TreeNode[]>>;
  placeholder: string;
  profileType?: number;
  onUserProfileClick?: () => void;
  onTrustProfileClick?: () => void;
  onAllLocationsClick?: () => void;
  isOceansblueUser?: boolean;
  canChooseAllLocation?: boolean;
  canCreateTrustProfiles?: boolean;
  isChoosingFromAllLocations?: boolean;
};

const MaterialTreeView = ({
  nodes,
  setUpdatedColumns,
  placeholder,
  searchResults,
  setSearchResults,
  updatedColumns,
  profileType,
  isOceansblueUser,
  canChooseAllLocation,
  canCreateTrustProfiles,
  isChoosingFromAllLocations,
  onTrustProfileClick,
  onUserProfileClick,
  onAllLocationsClick
}: TreeviewProps) => {
  const [checkedNodes, setCheckedNodes] = useState<(string | number)[]>([]);
  const [intermediateNodes, setIntermediateNodes] = useState<(string | number)[]>([]);
  const [stateNodes, setStateNodes] = useState<TreeNode[]>(nodes);
  const [isSelectAllMode, setIsSelectAllMode] = useState<'all' | 'none' | 'selected'>('all');
  const {activeUnitProfile, activeProfile, newProfile, newUnitProfile, activeUnits, activeColumns, isCreatingNewProfile} =
    useContext(SidebarContext);

  const classes = useStyles();

  const handleCheck = (node: TreeNode) => {
    let updatedCheckedNodes = [...checkedNodes];
    let updatedIntermediateNodes = [...intermediateNodes];

    // Check or uncheck the current node

    if (updatedCheckedNodes.includes(node.value) || updatedIntermediateNodes.includes(node.value)) {
      updatedCheckedNodes = updatedCheckedNodes.filter((n) => n !== node.value);
      updatedIntermediateNodes = updatedIntermediateNodes.filter((n) => n !== node.value);
    } else {
      updatedCheckedNodes.push(node.value);
    }

    // Check or uncheck all child nodes
    if (Array.isArray(node.items)) {
      if (updatedCheckedNodes.includes(node.value)) {
        node.items.forEach((n) => {
          updatedCheckedNodes.push(n.value);
        });
        //Need to change it here to make it so it goes through the items and checks all of them or unchecks all of them since it's the parent node
      } else {
        node.items.forEach((n) => {
          updatedCheckedNodes = updatedCheckedNodes.filter((node) => node !== n.value);
        });
      }
    } else {
      let parentNode: TreeNode | undefined;
      let nodesToCheck: TreeNode[] | undefined;

      if (searchResults.length > 0) nodesToCheck = searchResults;
      else nodesToCheck = nodes;

      nodesToCheck.forEach((nd) => {
        if (Array.isArray(nd.items)) {
          nd.items.forEach((n) => {
            if (n.value === node.value) {
              parentNode = nd;
            }
          });
        }
        if (Array.isArray(node.items) && nd.value === node.value) {
          parentNode = nd;
        }
      });

      if (parentNode && Array.isArray(parentNode.items)) {
        let uncheckedCount = 0;
        let checkedCount = 0;

        parentNode.items.forEach((n) => {
          if (!updatedCheckedNodes.includes(n.value)) {
            uncheckedCount++;
          } else {
            checkedCount++;
          }
        });

        if (uncheckedCount === parentNode.items.length) {
          updatedCheckedNodes = updatedCheckedNodes.filter((n) => parentNode && n !== parentNode.value);
          updatedIntermediateNodes = updatedIntermediateNodes.filter((n) => parentNode && n !== parentNode.value);
        }
        if (parentNode && !updatedCheckedNodes.includes(parentNode.value) && checkedCount === parentNode.items.length) {
          updatedCheckedNodes.push(parentNode.value);
          updatedIntermediateNodes = updatedIntermediateNodes.filter((n) => parentNode && n !== parentNode.value);
        }
        if (
          parentNode &&
          !updatedIntermediateNodes.includes(parentNode.value) &&
          checkedCount > 0 &&
          checkedCount !== parentNode.items.length
        ) {
          updatedIntermediateNodes.push(parentNode.value);
          updatedCheckedNodes = updatedCheckedNodes.filter((n) => parentNode && n !== parentNode.value);
        }
      }
    }
    // Check or uncheck the parent node if all child nodes are checked or unchecked

    setCheckedNodes(updatedCheckedNodes);
    setIntermediateNodes(updatedIntermediateNodes);
    updateColumns(updatedCheckedNodes, updatedIntermediateNodes);
  };

  const updateCheckedNodes = (
    nodes: TreeNode[],
    updatedNodes: (string | number)[],
    updatedIntermedNodes?: (string | number)[],
  ): TreeNode[] => {
    const nodesCopy = [...nodes];
    nodesCopy.forEach((node) => {
      if (updatedNodes.includes(node.value)) node.checked = true;
      else if (updatedIntermedNodes && updatedIntermedNodes.includes(node.value)) node.checked = true;
      else node.checked = false;
      if (Array.isArray(node.items)) {
        node.items.forEach((n) => {
          if (updatedNodes.includes(n.value)) n.checked = true;
          else n.checked = false;
        });
      }
    });

    return nodesCopy;
  };

  const updateColumns = (updatedNodes: (string | number)[], updatedIntermedNodes?: (string | number)[]) => {
    if (searchResults.length > 0) {
      const newSearchNodes = updateCheckedNodes(searchResults, updatedNodes, updatedIntermedNodes);
      setSearchResults(newSearchNodes);
    } else {
      let nodesCopy = [...nodes];
      const newNodes = updateCheckedNodes(nodesCopy, updatedNodes, updatedIntermedNodes);
      setUpdatedColumns(newNodes);
    }
  };

  const handleSearch = (e: string) => {
    if (e === undefined) {
      return;
    }
    let nodesCopy = [...nodes];

    const searchNodes = search(nodesCopy, e);

    if (isSelectAllMode === 'all') {
      onSellectAll(searchNodes);
    } else {
      if (searchResults.length > 0) {
        let intermediateNodesCopy = [...intermediateNodes];
        const checkedNodesCopy = [...checkedNodes];
        searchNodes.forEach((node) => {
          let checkedCount = 0;
          if (Array.isArray(node.items)) {
            node.items.forEach((n) => {
              if (checkedNodesCopy.includes(n.value)) checkedCount++;
            });
          }
          if (checkedCount === 0 && intermediateNodesCopy.includes(node.value)) {
            intermediateNodesCopy = intermediateNodesCopy.filter((n) => n !== node.value);
          }
          if (checkedCount > 0 && !intermediateNodesCopy.includes(node.value)) {
            intermediateNodesCopy.push(node.value);
          }
        });
        setIntermediateNodes(intermediateNodesCopy);
      }
      setStateNodes(searchNodes);
    }
  };

  const search = (items: TreeNode[], term: string) => {
    if (!term) {
      setSearchResults([]);
      return items;
    }
    const searchItems = items.reduce((acc: TreeNode[], item) => {
      if (contains(item.text, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        let newItems = search(item.items, term);
        if (newItems && newItems.length > 0) {
          acc.push({
            text: item.text,
            items: newItems,
            checked: item.checked,
            value: item.value,
          });
        }
      }
      return acc;
    }, []);
    setSearchResults(searchItems);
    return searchItems;
  };

  const contains = (text: string, term: string) => {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  };

  const onclearColumns = () => {
    const dataCopy = searchResults && searchResults.length > 0 ? [...searchResults] : [...nodes];
    dataCopy.forEach((item) => {
      item.checked = false;
      if (item.items) {
        item.items.forEach((i) => {
          i.checked = false;
        });
      }
    });

    if (searchResults && searchResults.length > 0) {
      const clearedNodes: (string | number)[] = [];
      let checkedNodesCopy = [...checkedNodes];
      let intermediateNodesCopy = [...intermediateNodes];
      let nodesCopy = [...nodes];

      dataCopy.forEach((node) => {
        clearedNodes.push(node.value);
        if (checkedNodesCopy.includes(node.value)) {
          checkedNodesCopy = checkedNodesCopy.filter((item) => item !== node.value);
        }
        if (intermediateNodesCopy.includes(node.value)) {
          intermediateNodesCopy = intermediateNodesCopy.filter((item) => item !== node.value);
        }
        if (Array.isArray(node.items)) {
          node.items.forEach((n) => {
            clearedNodes.push(n.value);
            if (checkedNodesCopy.includes(n.value)) {
              checkedNodesCopy = checkedNodesCopy.filter((item) => item !== n.value);
            }
          });
        }
      });

      nodesCopy.forEach((node) => {
        if (checkedNodesCopy.includes(node.value)) {
          node.checked = true;
        }
        if (intermediateNodesCopy.includes(node.value)) {
          node.checked = true;
        }
        if (Array.isArray(node.items)) {
          node.items.forEach((n) => {
            if (checkedNodesCopy.includes(n.value)) {
              n.checked = true;
            }
          });
        }
      });

      setCheckedNodes(checkedNodesCopy);
      setIntermediateNodes(intermediateNodesCopy);
      setUpdatedColumns(nodesCopy);
      setSearchResults(
        searchResults.map((node) => {
          return {...node, checked: false};
        }),
      );
    } else {
      setCheckedNodes([]);
      setIntermediateNodes([]);
      setUpdatedColumns(dataCopy);
      setSearchResults([]);
    }
  };

  const onSellectAll = (selectAllNodes: TreeNode[]) => {
    let checkedNodesCopy = [...checkedNodes];

    if (searchResults.length > 0) {
      let updatedColumnsCopy = [...updatedColumns];
      let intermediateNodesCopy = [...intermediateNodes];

      let selectAllNodesChecked: string[] = [];

      selectAllNodes.forEach((node) => {
        selectAllNodesChecked.push(node.value.toString());
        node.checked = true;
        if (node.items) {
          node.items.forEach((n) => {
            selectAllNodesChecked.push(n.value.toString());
            n.checked = true;
          });
        }
      });

      updatedColumnsCopy.forEach((node) => {
        if (selectAllNodesChecked.includes(node.value.toString())) checkedNodesCopy.push(node.value);
        else {
          checkedNodesCopy = checkedNodesCopy.filter((n) => n !== node.value);
          intermediateNodesCopy = intermediateNodesCopy.filter((n) => n !== node.value);
        }
        if (node.items) {
          node.items.forEach((childNode) => {
            if (selectAllNodesChecked.includes(childNode.value.toString())) checkedNodesCopy.push(childNode.value);
            else {
              checkedNodesCopy = checkedNodesCopy.filter((n) => n !== childNode.value);
            }
          });
        }
      });

      setCheckedNodes(checkedNodesCopy);
      setIntermediateNodes(intermediateNodesCopy);
      setStateNodes(selectAllNodes);
      setSearchResults(selectAllNodes);
    } else {
      let intermediateNodesCopy = [...intermediateNodes];
      selectAllNodes.forEach((node) => {
        node.checked = true;

        if (!checkedNodes.includes(node.value.toString())) checkedNodesCopy.push(node.value);
        if (intermediateNodesCopy.includes(node.value)) intermediateNodesCopy = intermediateNodesCopy.filter((n) => n !== node.value);

        if (node.items) {
          node.items.forEach((n) => {
            n.checked = true;
            if (!checkedNodes.includes(n.value.toString())) checkedNodesCopy.push(n.value);
          });
        }
      });

      setIntermediateNodes(intermediateNodesCopy);
      setCheckedNodes(checkedNodesCopy);
      setStateNodes(selectAllNodes);
      setSearchResults(selectAllNodes);
    }
  };


  const onSelected = async () => {
    let data: TreeNode[] = [];
    if (profileType === 1) {
      const metricCodes =
        activeProfile && activeProfile.metricCodes
          ? activeProfile.metricCodes
          : activeColumns && activeColumns.metricCodes
          ? activeColumns.metricCodes
          : colsToString(gridColumnsDef.Columns);
      data = transformColumnsToTreeview(metricCodes, {
        parentColumns: gridColumnsDef.ParentColumns,
        childColumns: gridColumnsDef.Columns,
        textField: 'title',
        valueField: 'group',
        valueSecondField: 'field',
      });
    }
    if (profileType === 2) {
      let selectedLocations = '';
      if (!activeUnitProfile && !activeUnits) {
        selectedLocations = await getAllLocationsString();
      }
      const locations = await getLocations();

      const metricCodes =
        activeUnitProfile && activeUnitProfile.metricCodes
          ? activeUnitProfile.metricCodes
          : activeUnits && activeUnits.metricCodes
          ? activeUnits.metricCodes
          : selectedLocations;
      data = transformColumnsToTreeview(metricCodes, {
        parentColumns: locations || [],
        textField: 'locationName',
        valueField: 'locationId',
        valueSecondField: 'locationId',
        compareField: 'esrDivision',
      });
    }
    if (data.length > 0) {
      let checkNodes: (string | number)[] = [];
      let intermedNodes: (string | number)[] = [];

      data.forEach((node) => {
        if (node.items) {
          let checkedNodesCount = 0;
          node.items.forEach((n) => {
            if (n.checked) {
              checkNodes.push(n.value);
              checkedNodesCount++;
            }
          });
          if (node.items.length > 0 && checkedNodesCount === node.items.length) {
            checkNodes.push(node.value);
          }
          if (node.items.length > 0 && checkedNodesCount > 0 && checkedNodesCount !== node.items.length) {
            intermedNodes.push(node.value);
          }
          if (node.items.length < 1 && node.checked) {
            checkNodes.push(node.value);
          }
        }
      });
      setIntermediateNodes(intermedNodes);
      setCheckedNodes(checkNodes);
      setUpdatedColumns(data);
    }
    if (data.length < 1) {
      const dataCopy = searchResults && searchResults.length > 0 ? [...searchResults] : [...nodes];
      dataCopy.forEach((item) => {
        item.checked = false;
        if (item.items) {
          item.items.forEach((i) => {
            i.checked = false;
          });
        }
      });

      setCheckedNodes([]);
      setIntermediateNodes([]);
      setUpdatedColumns(dataCopy);
      setSearchResults([]);
    }
  };



  useEffect(() => {
    let checkNodes: (string | number)[] = [];
    let intermedNodes: (string | number)[] = [];
    let nodesCopy = [...stateNodes];

    nodesCopy.forEach((node) => {
      if (node.items) {
        let checkedNodesCount = 0;
        node.items.forEach((n) => {
          if (n.checked) {
            checkNodes.push(n.value);
            checkedNodesCount++;
          }
        });
        if (node.items.length > 0 && checkedNodesCount === node.items.length) {
          checkNodes.push(node.value);
        }
        if (node.items.length > 0 && checkedNodesCount > 0 && checkedNodesCount !== node.items.length) {
          intermedNodes.push(node.value);
        }
        if (node.items.length < 1 && node.checked) {
          checkNodes.push(node.value);
        }
      }
    });
    setIntermediateNodes(intermedNodes);
    setCheckedNodes(checkNodes);
    setUpdatedColumns(nodesCopy);

    if (
      (profileType === 1 && isCreatingNewProfile && (newProfile.profile || activeColumns)) ||
      (profileType === 2 && isCreatingNewProfile && (newUnitProfile.profile || activeUnits))
    ) {
      setIsSelectAllMode('selected');
    }
  }, []);

  const renderTree = (nodes: TreeNode[]): React.ReactNode => (
    <TreeItem
      key={nodes[0].value.toString()}
      nodeId={`nodeid-${nodes[0].value.toString()}`}
      label={
        <>
          <Checkbox
            checked={checkedNodes.includes(nodes[0].value)}
            indeterminate={intermediateNodes.includes(nodes[0].value)}
            style={{
              color: `rgba(0, 128, 193,${intermediateNodes.includes(nodes[0].value) ? '0.8' : '1'})`,
              fontSize: '45px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleCheck(nodes[0]);
            }}
          />
          {nodes[0].text}
        </>
      }>
      {Array.isArray(nodes[0].items) ? nodes[0].items.map((node) => renderTree([node])) : null}
    </TreeItem>
  );

    
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', marginBottom: '15px'}}>
        <input
          autoFocus
          key="search-bar"
          id="searchbox"
          className="k-textbox width100"
          onChange={(e) => handleSearch(e.target.value)}
          style={{marginRight: '10px'}}
          placeholder={placeholder}
        />
        {profileType && (
          <TelButton
            iconClass="fa-solid fa-user-plus"
            onClick={() => onUserProfileClick && onUserProfileClick()}
            title={`Create a new ${profileType === 1 ? 'column' : 'unit'} user profile using the current ${
              profileType === 1 ? 'columns' : 'units'
            }`}
            style={{marginLeft: '5px', padding: '0 7px'}}></TelButton>
        )}
              {canCreateTrustProfiles && profileType && (
          <TelButton
            title={`Create a new ${profileType === 1 ? 'column' : 'unit'} trust profile using the current ${
              profileType === 1 ? 'columns' : 'units'
            }`}
            iconClass="fa-solid fa-hospital"
            onClick={() => onTrustProfileClick && onTrustProfileClick()}
            style={{margin: '0 5px', padding: '0 7px'}}></TelButton>
        )}
        {canChooseAllLocation && (
            <TelButton
                title={`Select from all locations`}
            iconClass="fa-solid fa-globe-europe"
                      onClick={() => {
                          onAllLocationsClick && onAllLocationsClick(); 
                      }}
                      style={{ margin: '0px 5px 0px 0px', padding: '0 7px', color: isChoosingFromAllLocations ? '#0080c1' : '#424242' }}></TelButton>
        )}
        <ButtonGroup orientation="horizontal" color="primary">
          <Button
            variant={'contained'}
            className="toggle-button"
            title="Clear selections"
            onClick={() => {
              setIsSelectAllMode('none');
              onclearColumns();
            }}
            style={{
              minWidth: '80px',
              padding: '2px 0',
              textTransform: 'none',
              boxShadow: 'none',
              color: 'white',
              background: isSelectAllMode === 'none' ? '#0080c1' : '#8a8a8a',
              borderRight: '1px solid white',
            }}>
            Select none
          </Button>
          {profileType && (
            <Button
              variant={'contained'}
              className="toggle-button"
              title={`Select current ${profileType === 1 ? 'columns' : 'units'}`}
              onClick={() => {
                setIsSelectAllMode('selected');
                onSelected();
              }}
              style={{
                minWidth: '80px',
                padding: '2px 0',
                textTransform: 'none',
                boxShadow: 'none',
                color: 'white',
                background: isSelectAllMode === 'selected' ? '#0080c1' : '#8a8a8a',
                borderRight: '1px solid white',
                borderLeft: '1px solid white',
              }}>
              Selected
            </Button>
          )}
          <Button
            className="toggle-button"
            variant={'outlined'}
            title="Tick all visible results"
            onClick={() => {
              setIsSelectAllMode('all');
              onSellectAll(searchResults.length > 0 ? [...searchResults] : [...nodes]);
            }}
            style={{
              minWidth: '80px',
              padding: '2px 0',
              textTransform: 'none',
              boxShadow: 'none',
              color: 'white',
              background: isSelectAllMode === 'all' ? '#0080c1' : '#8a8a8a',
              borderLeft: '1px solid white',
            }}>
            Select all
          </Button>
        </ButtonGroup>
      </div>
      <TreeView className={classes.root} defaultCollapseIcon={<ExpandLessIcon />} defaultExpandIcon={<ExpandMoreIcon />}>
        {stateNodes && stateNodes.map((node) => renderTree([node]))}
      </TreeView>
    </>
  );
};

export default MaterialTreeView;
